import React from "react";
import "./style.css";

export const GuestMobile = () => {
  return (
    <div className="GUEST-MOBILE">
      <div className="div-9">
        <div className="group-402">
          <div className="group-403">
            <div className="text-wrapper-214">AWAKENING</div>
            <div className="FULL-MOON-3">FULL&nbsp;&nbsp;MOON</div>
          </div>
        </div>
        <div className="group-404">
          <div className="overlap-group-42">
            <img className="vector-46" alt="Vector" src="/img/vector-233-7.svg" />
            <img className="vector-47" alt="Vector" src="/img/vector-244-4.svg" />
            <img className="group-405" alt="Group" src="/img/group-48098648.png" />
          </div>
          <img className="vector-48" alt="Vector" src="/img/vector-231-2.svg" />
          <img className="vector-49" alt="Vector" src="/img/vector-232-5.svg" />
          <img className="vector-50" alt="Vector" src="/img/image.svg" />
          <img className="vector-51" alt="Vector" src="/img/vector-236-5.svg" />
        </div>
        <div className="group-406">
          <div className="text-wrapper-215">July 20th</div>
          <div className="text-wrapper-216">7pm - 7am</div>
        </div>
        <div className="group-407">
          <div className="group-408">
            <div className="FABIANO-VALLI-4">FABIANO VALLI&nbsp;&nbsp;PISETZKY</div>
            <div className="co-5">&amp;&nbsp;&nbsp;co</div>
            <div className="text-wrapper-217">CORDIALLY INVITED BY</div>
            <div className="rectangle-159" />
          </div>
        </div>
        <img className="mask-group-23" alt="Mask group" src="/img/mask-group-9.png" />
        <img className="mask-group-24" alt="Mask group" src="/img/mask-group-1-2.png" />
        <div className="group-409">
          <div className="SHAMANIC"> SHAMANIC RECALIBRATION</div>
          <p className="CRYSTAL-AND-2">
            CRYSTAL AND FREQUENCY <br />
            HEALING SESSIONS
          </p>
          <div className="FROM-LOS-ANGELES">FROM&nbsp;&nbsp;LOS&nbsp;&nbsp;ANGELES</div>
        </div>
        <div className="group-410">
          <div className="group-411">
            <div className="text-wrapper-218">TOP UNDERGROUND DJS</div>
          </div>
          <p className="SECRET-LINEUP-OF-THE">
            SECRET LINEUP OF THE ELITE
            <br />
            &nbsp;&nbsp;UNDERGROUND
          </p>
        </div>
        <div className="group-412">
          <div className="group-413">
            <div className="text-wrapper-219">SKL SS 2024 CATWALK</div>
          </div>
          <p className="FEATURING-TOP-MODELS">
            FEATURING TOP MODELS FROM
            <br />
            &nbsp;&nbsp;EUROPE &amp; THE US
          </p>
        </div>
        <div className="group-414">
          <div className="group-415">
            <div className="group-413">
              <div className="text-wrapper-219">EXCLUSIVE LOCATION</div>
            </div>
            <p className="text-wrapper-220">AN EXPERTLY ARCHITECTED TEMPLE CENTERED AROUND A LUXURY VILLA</p>
          </div>
        </div>
        <div className="group-416">
          <div className="group-413">
            <div className="text-wrapper-219">PROPER&nbsp;&nbsp;FESTIVAL STAGE</div>
          </div>
          <p className="text-wrapper-221">
            DESIGNED BY ONE OF THE BEST
            <br />
            STAGE CREATORS
          </p>
        </div>
        <div className="group-417">
          <div className="group-413">
            <div className="text-wrapper-219">LUXURY&nbsp;&nbsp;PRIVATE&nbsp;&nbsp;YURTS</div>
          </div>
          <p className="text-wrapper-221">RECREATING THE FEELING OF AN&nbsp;&nbsp;EXOTIC ATMOSPHERE</p>
        </div>
        <div className="group-418">
          <div className="TAILORED-LIGHTING-wrapper">
            <div className="TAILORED-LIGHTING">TAILORED&nbsp;&nbsp;LIGHTING&nbsp;&nbsp;SHOW</div>
          </div>
          <p className="a-LIGHT-SHOW">
            A LIGHT SHOW COMPOSITION
            <br />
            &nbsp;&nbsp;TAILORED TO THE VENUE
          </p>
        </div>
        <div className="group-419">
          <div className="group-420">
            <div className="text-wrapper-219">LIVE ART PRODUCTION</div>
          </div>
          <p className="text-wrapper-222">
            TALENTED ARTISTS PERFORMING <br />
            THROUGHOUT THE NIGHT
          </p>
        </div>
        <img className="mask-group-25" alt="Mask group" src="/img/mask-group-9.png" />
        <div className="overlap-45">
          <div className="group-421" />
          <img
            className="AAA-logo-test-11"
            alt="Aaa logo test"
            src="/img/aaa-logo-test-4-removebg-preview-1-2-2x.png"
          />
        </div>
        <div className="flexcontainer-7">
          <p className="text-6">
            <span className="text-wrapper-223">
              Let us know before <br />
            </span>
          </p>
          <p className="text-6">
            <span className="text-wrapper-223">12th of July</span>
          </p>
        </div>
        <div className="text-wrapper-224">For more Info, contact</div>
        <div className="f-sokail-ai-j-sokail-8">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
        <img className="group-422" alt="Group" src="/img/group-48098605-7.png" />
        <div className="PRESENTED-BY-9">PRESENTED&nbsp;&nbsp;BY</div>
        <div className="group-423">
          <div className="group-424">
            <div className="group-425">
              <div className="group-424">
                <div className="group-425">
                  <div className="group-426">
                    <div className="overlap-group-43">
                      <div className="text-wrapper-225">or</div>
                      <div className="rectangle-160" />
                      <div className="rectangle-161" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="milan-italy" alt="Milan italy" src="/img/milan-italy.svg" />
        <div className="group-427">
          <div className="flexcontainer-wrapper">
            <div className="flexcontainer-8">
              <p className="text-7">
                <span className="text-wrapper-226">
                  EXPERIENCE&nbsp;&nbsp;
                  <br />
                </span>
              </p>
              <p className="text-7">
                <span className="text-wrapper-226">THE&nbsp;&nbsp; UNTOUCHABLE</span>
              </p>
            </div>
          </div>
          <div className="rectangle-162" />
        </div>
        <div className="group-428">
          <div className="text-wrapper-222">A LOT MORE</div>
          <div className="text-wrapper-227">+++</div>
        </div>
      </div>
    </div>
  );
};
