/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PlatformRedditColorNegative = ({ className }) => {
  return (
    <svg
      className={`platform-reddit-color-negative ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M28.8825 11.3175C29.2988 13.0818 30.8831 14.3962 32.775 14.3962C34.9838 14.3962 36.7744 12.6056 36.7744 10.3968C36.7744 8.18809 34.9838 6.39746 32.775 6.39746C30.8438 6.39746 29.2331 7.76621 28.8581 9.58684C25.6238 9.93371 23.0981 12.6768 23.0981 16.0012C23.0981 16.0087 23.0981 16.0143 23.0981 16.0218C19.5806 16.17 16.3688 17.1712 13.8188 18.7518C12.8719 18.0187 11.6831 17.5818 10.3931 17.5818C7.29751 17.5818 4.78876 20.0906 4.78876 23.1862C4.78876 25.4325 6.10876 27.3675 8.01563 28.2618C8.20126 34.7681 15.2906 40.0012 24.0113 40.0012C32.7319 40.0012 39.8306 34.7625 40.0069 28.2506C41.8988 27.3506 43.2075 25.4212 43.2075 23.1881C43.2075 20.0925 40.6988 17.5837 37.6031 17.5837C36.3188 17.5837 35.1356 18.0168 34.1906 18.7443C31.6181 17.1525 28.3706 16.1512 24.8175 16.0181C24.8175 16.0125 24.8175 16.0087 24.8175 16.0031C24.8175 13.6218 26.5875 11.6456 28.8825 11.3212V11.3175ZM13.5938 26.7431C13.6875 24.7106 15.0375 23.1506 16.6069 23.1506C18.1763 23.1506 19.3763 24.7987 19.2825 26.8312C19.1888 28.8637 18.0169 29.6025 16.4456 29.6025C14.8744 29.6025 13.5 28.7756 13.5938 26.7431ZM31.4175 23.1506C32.9888 23.1506 34.3388 24.7106 34.4306 26.7431C34.5244 28.7756 33.1481 29.6025 31.5788 29.6025C30.0094 29.6025 28.8356 28.8656 28.7419 26.8312C28.6481 24.7987 29.8463 23.1506 31.4175 23.1506ZM29.55 31.4456C29.8444 31.4756 30.0319 31.7812 29.9175 32.055C28.9519 34.3631 26.6719 35.985 24.0113 35.985C21.3506 35.985 19.0725 34.3631 18.105 32.055C17.9906 31.7812 18.1781 31.4756 18.4725 31.4456C20.1975 31.2712 22.0631 31.1756 24.0113 31.1756C25.9594 31.1756 27.8231 31.2712 29.55 31.4456Z"
        fill="white"
      />
    </svg>
  );
};
