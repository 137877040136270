import React from "react";
import "./style.css";

export const MainScreen = () => {
  return (
    <div className="MAIN-screen">
      <div className="MAIN-2">
        <div className="overlap-58">
          <div className="property-VIP-INV-wrapper">
            <div className="property-VIP-INV">
              <div className="group-532" />
              <div className="overlap-59">
                <div className="group-533">
                  <div className="group-534">
                    <div className="overlap-group-55">
                      <div className="group-533">
                        <img className="group-535" alt="Group" src="/img/group-48098457.png" />
                      </div>
                      <div className="text-wrapper-275">SECRET EVENT</div>
                    </div>
                  </div>
                </div>
                <div className="group-533">
                  <div className="group-534">
                    <div className="overlap-group-55">
                      <div className="group-533">
                        <img className="group-535" alt="Group" src="/img/group-48098457.png" />
                      </div>
                      <div className="text-wrapper-275">SECRET EVENT</div>
                    </div>
                  </div>
                </div>
                <div className="group-536">
                  <div className="text-wrapper-276">CORDIALLY INVITE YOU</div>
                </div>
                <div className="FABIANO-VALLI-LEVI-5">
                  FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp; co.
                </div>
                <div className="group-537">
                  <div className="overlap-60">
                    <div className="rectangle-210" />
                    <div className="rectangle-211" />
                    <div className="text-wrapper-277">July 20th</div>
                  </div>
                </div>
                <div className="group-538">
                  <div className="overlap-61">
                    <img className="vector-65" alt="Vector" src="/img/vector-233-5.svg" />
                    <img className="vector-66" alt="Vector" src="/img/vector-244-3.svg" />
                    <img className="group-539" alt="Group" src="/img/group-48098648-2.png" />
                  </div>
                  <img className="vector-67" alt="Vector" src="/img/vector-231-5.svg" />
                  <img className="vector-68" alt="Vector" src="/img/vector-232-2.svg" />
                  <img className="vector-69" alt="Vector" src="/img/vector-235.svg" />
                  <img className="vector-70" alt="Vector" src="/img/vector-236-2.svg" />
                </div>
              </div>
              <div className="overlap-62">
                <div className="group-540">
                  <div className="group-541">
                    <div className="group-542">
                      <div className="group-543">
                        <img className="group-544" alt="Group" src="/img/group-48098457-1-2.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="EXCLUSIVE-LOCATION">EXCLUSIVE&nbsp;&nbsp; LOCATION</div>
                <div className="LIVE-ART-PRODUCTIONS">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
                <div className="TOP-UNDERGROUND-DJS">TOP&nbsp;&nbsp; UNDERGROUND&nbsp;&nbsp; DJS</div>
                <div className="FROM-LOS-ANGELES-2">
                  FROM&nbsp;&nbsp;LOS&nbsp;&nbsp;ANGELES:&nbsp;&nbsp;SHAMANIC&nbsp;&nbsp;RECALIBRATION
                </div>
                <div className="GIFT-BOXES-OF-GIFTS">
                  GIFT&nbsp;&nbsp;BOXES&nbsp;&nbsp;OF&nbsp;&nbsp;GIFTS&nbsp;&nbsp;FROM&nbsp;&nbsp;SPONSORS&nbsp;&nbsp;TO&nbsp;&nbsp;VIP
                </div>
                <div className="VIP-PRIVATE-ACCESS">
                  VIP&nbsp;&nbsp;PRIVATE&nbsp;&nbsp;ACCESS&nbsp;&nbsp;TO&nbsp;&nbsp;SECRET&nbsp;&nbsp;AREAS
                </div>
                <div className="PROPER-FESTIVAL">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
                <div className="text-wrapper-278">LUXURY PRIVATE YERTS</div>
                <div className="LIVE-VIP-TATTOO">LIVE&nbsp;&nbsp;VIP&nbsp;&nbsp;TATTOO&nbsp;&nbsp;ARTISTS</div>
                <div className="LIGHTING-DESIGN">LIGHTING&nbsp;&nbsp;DESIGN&nbsp;&nbsp;EXPERIENCE</div>
                <div className="SPECIALTY-SMOKING">
                  SPECIALTY&nbsp;&nbsp;SMOKING&nbsp;&nbsp;LOUNGE&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY
                  OUR&nbsp;&nbsp;SPONSORS
                </div>
                <div className="PRESENTATION-OF-OUR">
                  PRESENTATION&nbsp;&nbsp;OF&nbsp;&nbsp;OUR&nbsp;&nbsp;PRIVATE&nbsp;&nbsp;ART&nbsp;&nbsp;GALLERY
                </div>
                <div className="group-545">
                  <div className="text-wrapper-279">EVENT SPECIALS</div>
                  <div className="EXPERIENCE-THE-5">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
                </div>
                <div className="rectangle-212" />
                <div className="rectangle-213" />
              </div>
              <div className="overlap-63">
                <div className="group-546">
                  <div className="group-541">
                    <div className="group-547">
                      <div className="group-543">
                        <img className="group-544" alt="Group" src="/img/group-48098457-2-2.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="CONNECTION-TO-THE">
                  CONNECTION&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;TOP&nbsp;&nbsp;HOTELS&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;AREA
                </div>
                <div className="PRIVATE">
                  PRIVATE&nbsp;&nbsp;TRANSPORTATION&nbsp;&nbsp;DISCOUNT&nbsp;&nbsp;AND&nbsp;&nbsp;RESERVATIONS
                </div>
                <div className="HELICOPTER-LANDING-2">
                  HELICOPTER LANDING&nbsp;&nbsp;NEXT
                  TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE&nbsp;&nbsp;UPON&nbsp;&nbsp;REQUEST
                </div>
                <div className="COMPLIMENTARY-VALET">
                  COMPLIMENTARY&nbsp;&nbsp;VALET&nbsp;&nbsp;SERVICE&nbsp;&nbsp;AT&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
                </div>
                <div className="group-548">
                  <div className="group-549">
                    <div className="text-wrapper-280">OUR SPECIAL</div>
                    <div className="text-wrapper-281">ACCOMODATIONS</div>
                  </div>
                </div>
                <img className="group-550" alt="Group" src="/img/group-48098603.png" />
                <div className="group-551">
                  <div className="text-wrapper-282">Secret</div>
                  <img className="location-7" alt="Location" src="/img/location.svg" />
                </div>
                <p className="text-wrapper-283">Let us know before 12th of July</p>
                <div className="text-wrapper-284">For more Info, contact</div>
                <div className="f-sokail-ai-j-sokail-11">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
                <div className="rectangle-214" />
                <div className="rectangle-215" />
                <div className="rectangle-216" />
                <div className="rectangle-217" />
                <img
                  className="AAA-logo-test-15"
                  alt="Aaa logo test"
                  src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
                />
                <div className="rectangle-218" />
              </div>
              <div className="rectangle-219" />
              <img className="group-552" alt="Group" src="/img/group-48098589-2x.png" />
              <div className="group-553">
                <div className="overlap-64">
                  <div className="rectangle-220" />
                  <div className="text-wrapper-285">Get Access</div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-554">
            <div className="overlap-64">
              <div className="rectangle-220" />
              <div className="text-wrapper-285">Get Access</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
