import React from "react";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { SocialIcons } from "../../components/SocialIcons";
import { PlatformWhatsappColorOriginal1 } from "../../icons/PlatformWhatsappColorOriginal1";
import { SocialIcons4 } from "../../icons/SocialIcons4";
import "./style.css";

export const MainMobile = () => {
  return (
    <div className="MAIN-MOBILE">
      <div className="div-6">
        <div className="group-352">
          <div className="text-wrapper-179">AWAKENING</div>
          <div className="FULL-MOON-2">FULL&nbsp;&nbsp;MOON</div>
        </div>
        <div className="group-353">
          <div className="overlap-group-36">
            <img className="vector-34" alt="Vector" src="/img/vector-233-7.svg" />
            <img className="vector-35" alt="Vector" src="/img/vector-244-4.svg" />
            <img className="group-354" alt="Group" src="/img/group-48098648-6.png" />
          </div>
          <img className="vector-36" alt="Vector" src="/img/vector-231-2.svg" />
          <img className="vector-37" alt="Vector" src="/img/vector-232-5.svg" />
          <img className="vector-38" alt="Vector" src="/img/image.svg" />
          <img className="vector-39" alt="Vector" src="/img/vector-236-5.svg" />
        </div>
        <div className="group-355">
          <div className="FABIANO-PISETZKY-2">FABIANO&nbsp;&nbsp;PISETZKY</div>
          <div className="co-4">&amp;&nbsp;&nbsp;co</div>
          <div className="HOSTED-BY-2">HOSTED&nbsp;&nbsp;BY</div>
          <div className="overlap-39">
            <div className="rectangle-126" />
          </div>
        </div>
        <img className="mask-group-20" alt="Mask group" src="/img/mask-group-11.png" />
        <img className="mask-group-21" alt="Mask group" src="/img/mask-group-9.png" />
        <div className="overlap-40">
          <div className="group-356" />
          <img className="AAA-logo-test-9" alt="Aaa logo test" src="/img/aaa-logo-test-4-removebg-preview-1-2-2x.png" />
        </div>
        <div className="group-357">
          <div className="flexcontainer-6">
            <p className="text-5">
              <span className="text-wrapper-180">
                EXPERIENCE&nbsp;&nbsp;
                <br />
              </span>
            </p>
            <p className="text-5">
              <span className="text-wrapper-180">THE&nbsp;&nbsp; UNTOUCHABLE</span>
            </p>
          </div>
          <div className="rectangle-127" />
        </div>
        <div className="group-358">
          <div className="text-wrapper-181">Secret</div>
          <img className="location-4" alt="Location" src="/img/location-2.svg" />
        </div>
        <p className="text-wrapper-182">Let us know before 12th of July</p>
        <div className="text-wrapper-183">For more Info, contact</div>
        <div className="f-sokail-ai-j-sokail-6">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
        <img className="group-359" alt="Group" src="/img/group-48098605-7.png" />
        <div className="PRESENTED-BY-7">PRESENTED&nbsp;&nbsp;BY</div>
        <div className="group-360">
          <SocialIcons color="negative" platform="telegram" />
          <ConcreteComponentNode color="negative" platform="x-twitter" />
          <SocialIcons color="negative" platform="instagram" />
          <PlatformWhatsappColorOriginal1 className="platform-whatsapp-color-negative" color="white" />
          <SocialIcons4 className="platform-messenger-color-negative" />
        </div>
        <div className="group-361">
          <div className="overlap-41">
            <div className="rectangle-128" />
            <div className="text-wrapper-184">SPONSOR</div>
          </div>
        </div>
        <div className="group-362">
          <div className="overlap-41">
            <div className="rectangle-129" />
            <div className="text-wrapper-185">VIP</div>
          </div>
        </div>
        <div className="group-363">
          <div className="overlap-41">
            <div className="rectangle-130" />
            <div className="text-wrapper-186">GUEST</div>
          </div>
        </div>
        <div className="group-364">
          <div className="group-365">
            <div className="group-366">
              <div className="group-365">
                <div className="group-366">
                  <div className="group-367">
                    <div className="text-wrapper-187">July 20th</div>
                    <div className="rectangle-131" />
                    <div className="rectangle-132" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-188">EXCLUSIVE LOCATION</div>
        <div className="text-wrapper-189">SKL SS 2024 CATWALK</div>
        <div className="text-wrapper-190">TOP UNDERGROUND DJS</div>
        <div className="text-wrapper-191">FESTIVAL STAGE</div>
        <div className="text-wrapper-192">LUXURY YURTS</div>
        <div className="text-wrapper-193">+++++</div>
        <div className="text-wrapper-194">LIGHTING DESIGN SHOW</div>
        <div className="text-wrapper-195">LIVE ART PRODUCTIONS</div>
        <div className="text-wrapper-196">SHAMANIC RECALIBRATION</div>
        <div className="group-368">
          <div className="group-365">
            <div className="group-369">
              <div className="group-365">
                <div className="group-369">
                  <div className="group-370">
                    <div className="overlap-group-37">
                      <div className="text-wrapper-197">or</div>
                      <div className="rectangle-133" />
                      <div className="rectangle-134" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
