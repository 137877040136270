import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { SocialIcons } from "../../components/SocialIcons";
import { PlatformWhatsappColorOriginal1 } from "../../icons/PlatformWhatsappColorOriginal1";
import { SocialIcons4 } from "../../icons/SocialIcons4";
import "./style.css";

export const SponsorInvitation = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="SPONSOR-INVITATION">
      <div
        className="SPONSOR-phone"
        style={{
          height: screenWidth < 1280 ? "9079px" : screenWidth >= 1280 ? "8059px" : undefined,
          width: screenWidth < 1280 ? "390px" : screenWidth >= 1280 ? "1280px" : undefined,
        }}
      >
        <div
          className="overlap-31"
          style={{
            height: screenWidth >= 1280 ? "7754px" : screenWidth < 1280 ? "2153px" : undefined,
            left: screenWidth >= 1280 ? "-383px" : screenWidth < 1280 ? "12px" : undefined,
            top: screenWidth >= 1280 ? "0" : screenWidth < 1280 ? "1629px" : undefined,
            width: screenWidth >= 1280 ? "2046px" : screenWidth < 1280 ? "1604px" : undefined,
          }}
        >
          <div
            className="CRYSTAL-AND-AND-wrapper"
            style={{
              height: screenWidth < 1280 ? "23px" : screenWidth >= 1280 ? "683px" : undefined,
              left: screenWidth < 1280 ? "0" : screenWidth >= 1280 ? "383px" : undefined,
              top: screenWidth < 1280 ? "846px" : screenWidth >= 1280 ? "0" : undefined,
              width: screenWidth < 1280 ? "1604px" : screenWidth >= 1280 ? "1280px" : undefined,
            }}
          >
            <div
              className="CRYSTAL-AND-AND-6"
              style={{
                WebkitBackgroundClip: screenWidth < 1280 ? "text !important" : undefined,
                WebkitTextFillColor: screenWidth < 1280 ? "transparent" : undefined,
                background:
                  screenWidth < 1280
                    ? "linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(153, 153, 153) 100%)"
                    : undefined,
                backgroundClip: screenWidth < 1280 ? "text" : undefined,
                color: screenWidth < 1280 ? "transparent" : undefined,
                fontFamily: screenWidth < 1280 ? "'Michroma', Helvetica" : undefined,
                fontSize: screenWidth < 1280 ? "16px" : undefined,
                fontWeight: screenWidth < 1280 ? "400" : undefined,
                height: screenWidth >= 1280 ? "683px" : undefined,
                left: screenWidth < 1280 ? "0" : undefined,
                letterSpacing: screenWidth < 1280 ? "0" : undefined,
                lineHeight: screenWidth < 1280 ? "normal" : undefined,
                position: screenWidth < 1280 ? "absolute" : undefined,
                textAlign: screenWidth < 1280 ? "center" : undefined,
                textFillColor: screenWidth < 1280 ? "transparent" : undefined,
                top: screenWidth < 1280 ? "0" : undefined,
                transform: screenWidth >= 1280 ? "rotate(180deg)" : undefined,
                width: screenWidth < 1280 ? "1602px" : undefined,
              }}
            >
              {screenWidth < 1280 && (
                <>CRYSTAL AND&nbsp;&nbsp;AND&nbsp;&nbsp; FREQUENCY&nbsp;&nbsp;HEALING&nbsp;&nbsp;SESSIONS</>
              )}

              {screenWidth >= 1280 && (
                <div className="group-245">
                  <img className="group-246" alt="Group" src="/img/group-48098457-4.png" />
                </div>
              )}
            </div>
          </div>
          <div
            className="group-247"
            style={{
              height: screenWidth >= 1280 ? "723px" : screenWidth < 1280 ? "82px" : undefined,
              left: screenWidth >= 1280 ? "438px" : screenWidth < 1280 ? "0" : undefined,
              top: screenWidth >= 1280 ? "3786px" : screenWidth < 1280 ? "51px" : undefined,
              width: screenWidth >= 1280 ? "62px" : screenWidth < 1280 ? "370px" : undefined,
            }}
          >
            {screenWidth >= 1280 && <div className="rectangle-86" />}

            {screenWidth < 1280 && (
              <>
                <div className="text-wrapper-116">TOP UNDERGROUND DJS</div>
                <div className="text-wrapper-117">
                  SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE
                  <br />
                  &nbsp;&nbsp;UNDERGROUND
                </div>
              </>
            )}
          </div>
          <div
            className="group-248"
            style={{
              height: screenWidth >= 1280 ? "1091px" : screenWidth < 1280 ? "108px" : undefined,
              top: screenWidth >= 1280 ? "3963px" : screenWidth < 1280 ? "649px" : undefined,
              width: screenWidth >= 1280 ? "2046px" : screenWidth < 1280 ? "370px" : undefined,
            }}
          >
            {screenWidth >= 1280 && (
              <div className="div-3">
                <div className="group-249">
                  <div className="group-250">
                    <div className="group-251">
                      <div className="div-3">
                        <img className="group-252" alt="Group" src="/img/group-48098457-1.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rectangle-87" />
                <div className="rectangle-88" />
              </div>
            )}

            {screenWidth < 1280 && (
              <>
                <p className="text-wrapper-116">
                  PRIVATE VIP ACCESS <br />
                  TO SECRET AREAS
                </p>
                <div className="text-wrapper-118">
                  UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES
                  <br />
                  &nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE THE&nbsp;&nbsp;SPACE
                </div>
              </>
            )}
          </div>
          <div
            className="group-253"
            style={{
              height: screenWidth >= 1280 ? "955px" : screenWidth < 1280 ? "82px" : undefined,
              left: screenWidth >= 1280 ? "446px" : screenWidth < 1280 ? "0" : undefined,
              top: screenWidth >= 1280 ? "6649px" : screenWidth < 1280 ? "857px" : undefined,
              width: screenWidth >= 1280 ? "1140px" : screenWidth < 1280 ? "370px" : undefined,
            }}
          >
            {screenWidth >= 1280 && (
              <div className="group-254">
                <div className="overlap-32">
                  <div className="group-255">
                    <div className="overlap-group-29">
                      <img className="image-5" alt="Image" src="/img/image-371-5.png" />
                      <div className="rectangle-89" />
                      <div className="rectangle-90" />
                      <div className="rectangle-91" />
                      <div className="rectangle-92" />
                      <img className="rectangle-93" alt="Rectangle" src="/img/rectangle-2827-4.svg" />
                    </div>
                  </div>
                  <div className="group-256">
                    <div className="rectangle-94" />
                    <div className="rectangle-95" />
                    <img
                      className="AAA-logo-test-8"
                      alt="Aaa logo test"
                      src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
                    />
                  </div>
                </div>
              </div>
            )}

            {screenWidth < 1280 && (
              <>
                <div className="text-wrapper-116">PROPER FESTIVAL STAGE</div>
                <div className="text-wrapper-117">
                  A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
                  <br /> THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
                </div>
              </>
            )}
          </div>
          <div
            className="group-257"
            style={{
              height: screenWidth >= 1280 ? "1px" : screenWidth < 1280 ? "82px" : undefined,
              left: screenWidth >= 1280 ? "712px" : screenWidth < 1280 ? "0" : undefined,
              top: screenWidth >= 1280 ? "482px" : screenWidth < 1280 ? "1039px" : undefined,
              width: screenWidth >= 1280 ? "622px" : screenWidth < 1280 ? "370px" : undefined,
            }}
          >
            {screenWidth >= 1280 && (
              <div className="group-258">
                <div className="group-259">
                  <div className="group-260">
                    <div className="rectangle-96" />
                    <div className="rectangle-97" />
                  </div>
                </div>
              </div>
            )}

            {screenWidth < 1280 && (
              <>
                <div className="text-wrapper-116">LUXURY PRIVATE YERTS</div>
                <div className="text-wrapper-117">
                  RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF AN&nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
                </div>
              </>
            )}
          </div>
          {screenWidth >= 1280 && (
            <div className="group-261">
              <div className="overlap-33">
                <img className="vector-26" alt="Vector" src="/img/vector-233-1-3.svg" />
                <img className="vector-27" alt="Vector" src="/img/vector-244-1.svg" />
                <img className="group-262" alt="Group" src="/img/group-48098648-1-3.png" />
              </div>
              <img className="vector-28" alt="Vector" src="/img/vector-231-4.svg" />
              <img className="vector-29" alt="Vector" src="/img/vector-232-1-2.svg" />
              <img className="vector-30" alt="Vector" src="/img/vector-235-1.svg" />
              <img className="vector-31" alt="Vector" src="/img/vector-236-1-4.svg" />
            </div>
          )}

          {screenWidth < 1280 && (
            <>
              <div className="group-263">
                <div className="text-wrapper-116">LIVE VIP TATTOO ARTISTS</div>
                <div className="text-wrapper-117">
                  WITNESS&nbsp;&nbsp;A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED
                  <br />
                  &nbsp;&nbsp;TALENT PERFORM
                </div>
              </div>
              <div className="group-264">
                <div className="text-wrapper-116">LIGHTING DESIGN EXPERIENCE</div>
                <div className="text-wrapper-117">
                  A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION
                  <br />
                  &nbsp;&nbsp;TAILORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
                </div>
              </div>
              <div className="group-265">
                <p className="text-wrapper-116">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
                <div className="text-wrapper-118">
                  IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC TO&nbsp;&nbsp;CREATE
                  <br />
                  &nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
                </div>
              </div>
              <div className="group-266">
                <p className="text-wrapper-116">
                  PRESENTATION OF OUR <br />
                  PRIVATE ART GALLERY
                </p>
                <div className="text-wrapper-118">
                  ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED
                  <br />
                  &nbsp;&nbsp;SPECIALTY NFC&nbsp;&nbsp;RINGS
                </div>
              </div>
              <div className="group-267">
                <div className="text-wrapper-116">LIVE ART PRODUCTIONS</div>
                <p className="text-wrapper-117">
                  HIGHLY TALENTED&nbsp;&nbsp;ARTISTS PERFORMING THROUGHOUT THE&nbsp;&nbsp;NIGHT
                </p>
              </div>
              <p className="text-wrapper-119">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
              <div className="CRYSTAL-AND">
                CRYSTAL AND FREQUENCY
                <br />
                HEALING SESSIONS
              </div>
              <div className="group-268">
                <p className="text-wrapper-116">
                  GIFT BOXES <br />
                  FROM SPONSORS TO VIP
                </p>
                <div className="text-wrapper-118">
                  SPECIAL GIFTS&nbsp;&nbsp;FROM
                  <br />
                  OUR&nbsp;&nbsp;TOP&nbsp;&nbsp;GLOBAL&nbsp;&nbsp;SPONSORS
                </div>
              </div>
            </>
          )}

          <img
            className="group-269"
            style={{
              height: screenWidth < 1280 ? "2153px" : screenWidth >= 1280 ? "133px" : undefined,
              left: screenWidth < 1280 ? "72px" : screenWidth >= 1280 ? "793px" : undefined,
              top: screenWidth < 1280 ? "0" : screenWidth >= 1280 ? "816px" : undefined,
              width: screenWidth < 1280 ? "223px" : screenWidth >= 1280 ? "458px" : undefined,
            }}
            alt="Group"
            src={
              screenWidth < 1280 ? "/img/group-48098899.png" : screenWidth >= 1280 ? "/img/mask-group-5.png" : undefined
            }
          />
          {screenWidth >= 1280 && (
            <>
              <div className="rectangle-98" />
              <img className="mask-group-14" alt="Mask group" src="/img/mask-group-6.png" />
              <img className="mask-group-15" alt="Mask group" src="/img/mask-group-2-2.png" />
              <img className="mask-group-16" alt="Mask group" src="/img/mask-group-2-2.png" />
              <div className="group-270">
                <div className="rectangle-99" />
                <div className="rectangle-100" />
              </div>
              <div className="group-271">
                <div className="group-272">
                  <div className="group-273">
                    <div className="text-wrapper-120">EXCLUSIVE</div>
                    <div className="text-wrapper-121">SERVICES</div>
                    <div className="text-wrapper-122">FOR OUR TOP SPONSORS</div>
                  </div>
                </div>
                <div className="group-274">
                  <div className="group-275">
                    <div className="group-276">
                      <p className="text-wrapper-123">COMPLIMENTARY BOOKINGS AT THE TOP HOTELS OF THE AREA</p>
                      <p className="EXCLUSIVE-ACCESS-AND-2">
                        EXCLUSIVE&nbsp;&nbsp;ACCESS AND VIP TREATMENT IN&nbsp;&nbsp;MILAN’S&nbsp;&nbsp;FINEST
                      </p>
                    </div>
                    <div className="group-277">
                      <div className="text-wrapper-124">PRIVATE TRANSPORTATION ARRANGEMENTS</div>
                      <p className="text-wrapper-125">
                        {" "}
                        COMPLIMENTARY&nbsp;&nbsp;LUXURY&nbsp;&nbsp;TRANSPORT SERVICES&nbsp;&nbsp;BEGINNING AT THE
                        AIRPORT
                      </p>
                    </div>
                    <div className="group-278">
                      <p className="text-wrapper-126">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
                      <p className="text-wrapper-127">HELICOPTER ARRIVALS ARE AVAILABLE UPON REQUEST</p>
                    </div>
                  </div>
                </div>
                <img className="basiglio-milano" alt="Basiglio milano" src="/img/basiglio-milano-italy.svg" />
                <p className="text-wrapper-128">Let us know before 12th of July</p>
                <div className="text-wrapper-129">For More Info, contact</div>
                <div className="f-sokail-ai-j-sokail-5">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
                <div className="text-wrapper-130">FULL MOON AWAKENING</div>
                <div className="SPONSOR-OUR-3">SPONSOR&nbsp;&nbsp;OUR</div>
                <div className="CORDIALLY-INVITED-BY-2">CORDIALLY INVITED&nbsp;&nbsp;BY</div>
                <div className="FABIANO-VALLI-LEVI-2">
                  FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp;&nbsp;&nbsp;co
                </div>
                <div className="text-wrapper-131">July 20th</div>
                <div className="text-wrapper-132">7pm - 7am</div>
                <div className="group-279">
                  <div className="group-280">
                    <div className="text-wrapper-133">EVENT SPECIALS</div>
                    <div className="EXPERIENCE-THE-2">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
                  </div>
                  <div className="group-281">
                    <div className="group-282">
                      <div className="group-283">
                        <div className="text-wrapper-123">EXCLUSIVE LOCATION</div>
                        <div className="AN-EXPERTLY-2">
                          AN EXPERTLY
                          ARCHITECTED&nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A&nbsp;&nbsp;LUXURY&nbsp;&nbsp;VILLA
                        </div>
                      </div>
                      <div className="group-284">
                        <div className="text-wrapper-134">SKL SS 2024 CATWALK</div>
                        <div className="text-wrapper-125">
                          FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM&nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp;
                          THE&nbsp;&nbsp;US
                        </div>
                      </div>
                      <div className="group-285">
                        <div className="text-wrapper-127">
                          COMPLIMENTARY&nbsp;&nbsp;VALET&nbsp;&nbsp;SERVICE&nbsp;&nbsp;AT&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
                        </div>
                        <p className="text-wrapper-126">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
                      </div>
                      <div className="group-286">
                        <div className="text-wrapper-127">
                          SPECIAL&nbsp;&nbsp;GIFTS&nbsp;&nbsp;FROM&nbsp;&nbsp;OUR&nbsp;&nbsp;TOP&nbsp;&nbsp;GLOBAL&nbsp;&nbsp;SPONSORS
                        </div>
                        <p className="text-wrapper-126">GIFT BOXES FROM SPONSORS TO VIP</p>
                      </div>
                      <div className="group-287">
                        <div className="text-wrapper-126">TOP UNDERGROUND DJS</div>
                        <div className="text-wrapper-127">
                          SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE&nbsp;&nbsp;UNDERGROUND
                        </div>
                      </div>
                      <div className="group-288">
                        <p className="text-wrapper-135">PRIVATE VIP ACCESS TO SECRET AREAS</p>
                        <div className="text-wrapper-125">
                          {" "}
                          UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES&nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE
                          THE&nbsp;&nbsp;SPACE
                        </div>
                      </div>
                      <div className="group-289">
                        <div className="group-290">
                          <div className="text-wrapper-126">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
                          <div className="text-wrapper-127">
                            A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
                            THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="overlap-group-30">
                      <div className="group-291">
                        <div className="group-292">
                          <div className="group-293">
                            <div className="group-294">
                              <div className="group-295">
                                <div className="group-290">
                                  <div className="text-wrapper-127">
                                    RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF
                                    AN&nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
                                  </div>
                                  <div className="text-wrapper-126">LUXURY PRIVATE YERTS</div>
                                </div>
                              </div>
                            </div>
                            <div className="group-296">
                              <div className="text-wrapper-127">
                                WITNESS A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED&nbsp;&nbsp;TALENT PERFORM
                              </div>
                              <div className="text-wrapper-126">
                                LIVE&nbsp;&nbsp;VIP&nbsp;&nbsp;TATTOO&nbsp;&nbsp;ARTISTS
                              </div>
                            </div>
                            <div className="group-297">
                              <div className="group-290">
                                <div className="text-wrapper-127">
                                  A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION&nbsp;&nbsp;TAI:LORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
                                </div>
                                <div className="text-wrapper-126">LIGHTING DESIGN EXPERIENCE</div>
                              </div>
                            </div>
                            <div className="group-298">
                              <div className="group-290">
                                <div className="text-wrapper-127">
                                  IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC
                                  TO&nbsp;&nbsp;CREATE&nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
                                </div>
                                <p className="text-wrapper-126">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
                              </div>
                            </div>
                            <div className="group-299">
                              <p className="text-wrapper-136">PRESENTATION OF OUR PRIVATE ART GALLERY</p>
                              <div className="text-wrapper-125">
                                ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;SPECIALTY
                                NFC&nbsp;&nbsp;RINGS
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="HIGHLY-TALENTED-2">
                          HIGHLY TALENTED&nbsp;&nbsp;ARTISTS PERFORMING THROUGHOUT THE&nbsp;&nbsp;NIGHT
                        </p>
                        <div className="THE-TOP-OF-THE-3">THE TOP OF THE&nbsp;&nbsp;FASHION&nbsp;&nbsp;INDUSTRY</div>
                        <div className="text-wrapper-137">INTERNATIONAL ROYALTY</div>
                        <div className="text-wrapper-138">CELEBRITY MUSICIANS</div>
                        <div className="text-wrapper-139">EXTRAORDINARILY SUCCESSFUL BUSINESS MAGNATES</div>
                        <p className="THE-MOST-POWERFUL">THE MOST POWERFUL MEMBERS&nbsp;&nbsp;OF KEY COUNTRIES</p>
                        <p className="text-wrapper-140">MASTERS OF MEDIA, MARKETING, SALES, AND PUBLIC RELATIONS</p>
                      </div>
                      <div className="group-300">
                        <div className="text-wrapper-126">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-301">
                <div className="text-wrapper-133">OFFERED</div>
                <div className="group-302">
                  <div className="overlap-group-31">
                    <div className="text-wrapper-141">SPONSORSHIP&nbsp;&nbsp;PACKAGES</div>
                    <div className="rectangle-101" />
                    <div className="rectangle-102" />
                  </div>
                </div>
              </div>
              <div className="group-303">
                <div className="text-wrapper-142">OUR</div>
                <div className="group-304">
                  <div className="overlap-group-32">
                    <div className="text-wrapper-141">CHOSEN&nbsp;&nbsp;ATTENDEES</div>
                    <div className="rectangle-103" />
                    <div className="rectangle-104" />
                  </div>
                </div>
              </div>
              <div className="text-wrapper-143">10,000€</div>
              <div className="text-wrapper-144">25,000€</div>
              <div className="text-wrapper-145">50,000€</div>
              <div className="text-wrapper-146">100,000€</div>
              <div className="text-wrapper-147">5,000€</div>
              <div className="text-wrapper-148">BRING A +5</div>
              <p className="text-wrapper-149">PRIVATE CONCIERGE FOR VIP ACCESS ANYWHERE IN MILAN</p>
              <div className="text-wrapper-150">BRING +10</div>
              <div className="ABOVE-LISTED">ABOVE&nbsp;&nbsp;LISTED&nbsp;&nbsp;EXCLUSIVE&nbsp;&nbsp;SERVICES</div>
              <div className="PROFESSIONAL">
                PROFESSIONAL ADVERTISEMENT&nbsp;&nbsp;PRODUCTION&nbsp;&nbsp;
                PROMOTED&nbsp;&nbsp;BY&nbsp;&nbsp;OUR&nbsp;&nbsp;PR&nbsp;&nbsp;&amp;&nbsp;&nbsp;INFLUENCERS
              </div>
              <div className="ONGOING-BEST-IN">
                ONGOING&nbsp;&nbsp;BEST&nbsp;&nbsp;IN&nbsp;&nbsp;CLASS&nbsp;&nbsp;MARKETING&nbsp;&nbsp;SERVICES&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY
                OUR&nbsp;&nbsp;COMPANY
              </div>
              <p className="text-wrapper-151">EQUITY SHARE IN OUR MULTI-CONGLOMERATE INTERNATIONAL AGENCY AGGREGATE</p>
              <p className="a-UNIQUE">
                A UNIQUE IDENTIFICATION GRANTING
                INDEFINITE&nbsp;&nbsp;VIP&nbsp;&nbsp;SPONSORSHIP&nbsp;&nbsp;STATUS&nbsp;&nbsp;WITHIN&nbsp;&nbsp;OUR
                <br />
                ORGANIZATION, INCLUDING VIP STATUS AT ALL&nbsp;&nbsp;OPEN EVENTS, &amp; ACCESS TO SOME FULLY
                PRIVATE&nbsp;&nbsp;UNADVERTISED&nbsp;&nbsp;EVENTS,,&nbsp;&nbsp;CONTINUOUS
                INTRODUCTIONS&nbsp;&nbsp;THROUGH&nbsp;&nbsp;NETWORK
              </p>
              <div className="item">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-2">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-2" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-3">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-3" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-4">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-4" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-5">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-5" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-6">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-6" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-7">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-7" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-8">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-8" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-9">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-9" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-10">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-10" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-11">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-11" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-12">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-12" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-13">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-13" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-14">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-15">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-16">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-17">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-18">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-19">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-20">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-21">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-22">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-23">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-24">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-25">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-26">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-15" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-27">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-16" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-28">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-29">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-30">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-31">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-17" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-32">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-33">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-34">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-35">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-36">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-18" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-37">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-38">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-39">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-40">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-41">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-19" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-42">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-20" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-43">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-44">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-45">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="ENGRAVINGS-ON-ALL-OF">ENGRAVINGS ON ALL OF OUR&nbsp;&nbsp;BAR&nbsp;&nbsp;GLASSES</p>
              <div className="DISTRIBUTION-OF">
                DISTRIBUTION&nbsp;&nbsp;OF&nbsp;&nbsp;PRODUCT&nbsp;&nbsp;SAMPLES&nbsp;&nbsp;VIA&nbsp;&nbsp;OUR&nbsp;&nbsp;VIP&nbsp;&nbsp;GIFT&nbsp;&nbsp;BOXES
              </div>
              <div className="item-46">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-47">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-48">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-49">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-50">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-51">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-52">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-53">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-54">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-55">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-56">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-57">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-58">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-59">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-60">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="CONTINUOUS">
                CONTINUOUS ADVERTISEMENT ON HIGHLY
                VISIBLE&nbsp;&nbsp;LIFE&nbsp;&nbsp;SIZE&nbsp;&nbsp;NFT&nbsp;&nbsp;PANELS
              </p>
              <div className="text-wrapper-152">BRING A +1</div>
              <div className="item-61">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="thumb">
                      <div className="icon">
                        <img className="color-21" alt="Color" src="/img/milan-italy.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-62">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-63">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-64">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-65">
                <div className="radio-wrapper">
                  <div className="controller">
                    <div className="icon-wrapper">
                      <div className="color-wrapper">
                        <img className="color-14" alt="Color" src="/img/color-3.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="PRIVATE-PRESENTATION">
                PRIVATE&nbsp;&nbsp;PRESENTATION&nbsp;&nbsp;SLOT&nbsp;&nbsp;IN&nbsp;&nbsp;ENCLOSED&nbsp;&nbsp;AREA&nbsp;&nbsp;ADVERTISED&nbsp;&nbsp;TO&nbsp;&nbsp;ATTENDEES
              </div>
            </>
          )}
        </div>
        <div
          className="group-305"
          style={{
            height: screenWidth < 1280 ? "102px" : screenWidth >= 1280 ? "34px" : undefined,
            left: screenWidth < 1280 ? "12px" : screenWidth >= 1280 ? "222px" : undefined,
            top: screenWidth < 1280 ? "1296px" : screenWidth >= 1280 ? "7836px" : undefined,
            width: screenWidth < 1280 ? "370px" : screenWidth >= 1280 ? "866px" : undefined,
          }}
        >
          {screenWidth < 1280 && (
            <>
              <div className="text-wrapper-116">EXCLUSIVE LOCATION</div>
              <div className="text-wrapper-117">
                AN EXPERTLY ARCHITECTED
                <br />
                &nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A
                <br />
                LUXURY&nbsp;&nbsp;VILLA
              </div>
            </>
          )}

          {screenWidth >= 1280 && (
            <div className="group-306">
              <div className="group-307">
                <div className="PRESENTED-BY-5">PRESENTED&nbsp;&nbsp;BY</div>
              </div>
            </div>
          )}
        </div>
        {screenWidth < 1280 && (
          <>
            <div className="group-308">
              <div className="text-wrapper-116">SKL SS 2024 CATWALK</div>
              <div className="text-wrapper-117">
                FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM
                <br />
                &nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp; THE&nbsp;&nbsp;US
              </div>
            </div>
            <div className="overlap-34">
              <img className="mask-group-17" alt="Mask group" src="/img/mask-group-9.png" />
              <div className="group-309">
                <div className="FABIANO-PISETZKY">FABIANO&nbsp;&nbsp;PISETZKY</div>
                <div className="co-3">&amp;&nbsp;&nbsp;co</div>
                <div className="text-wrapper-153">CORDIALLY INVITED BY</div>
                <div className="overlap-35">
                  <div className="rectangle-105" />
                </div>
              </div>
            </div>
            <div className="group-310">
              <div className="flexcontainer-5">
                <p className="text-4">
                  <span className="text-wrapper-154">
                    EXPERIENCE&nbsp;&nbsp;
                    <br />
                  </span>
                </p>
                <p className="text-4">
                  <span className="text-wrapper-154">THE&nbsp;&nbsp; UNTOUCHABLE</span>
                </p>
              </div>
              <div className="text-wrapper-155">EVENT SPECIALS</div>
              <div className="rectangle-106" />
              <div className="rectangle-107" />
            </div>
            <div className="rectangle-108" />
            <div className="ATTENDEES-2"> ATTENDEES</div>
            <div className="THE-TOP-OF-THE-4">
              THE TOP OF THE&nbsp;&nbsp;FASHION
              <br />
              &nbsp;&nbsp;INDUSTRY
            </div>
            <div className="text-wrapper-156">INTERNATIONAL ROYALTY</div>
            <div className="text-wrapper-157">CELEBRITY MUSICIANS</div>
            <div className="EXTRAORDINARILY-2">
              EXTRAORDINARILY SUCCESSFUL
              <br /> BUSINESS MAGNATES
            </div>
            <p className="SOME-OF-THE-MOST-3">
              SOME&nbsp;&nbsp;OF&nbsp;&nbsp;THE MOST INFLUENTIAL
              <br /> MEMBERS&nbsp;&nbsp;OF KEY COUNTRIES
            </p>
            <p className="MASTERS-OF-MEDIA-2">
              MASTERS OF MEDIA, MARKETING,
              <br /> SALES, AND PUBLIC RELATIONS
            </p>
            <div className="group-311">
              <div className="group-312">
                <div className="group-313">
                  <div className="text-wrapper-158">EXCLUSIVE</div>
                  <div className="text-wrapper-159">SERVICES</div>
                  <div className="text-wrapper-160">FOR OUR TOP SPONSORS</div>
                </div>
              </div>
            </div>
            <div className="group-314">
              <div className="group-315">
                <p className="text-wrapper-161">
                  COMPLIMENTARY <br />
                  BOOKINGS AT THE TOP
                  <br />
                  HOTELS OF THE AREA
                </p>
                <p className="EXCLUSIVE-ACCESS-AND-3">
                  EXCLUSIVE&nbsp;&nbsp;ACCESS AND
                  <br /> VIP TREATMENT IN&nbsp;&nbsp;MILAN’S&nbsp;&nbsp;FINEST
                </p>
              </div>
              <div className="group-316">
                <div className="text-wrapper-161">PRIVATE TRANSPORTATION ARRANGEMENTS</div>
                <p className="text-wrapper-162">
                  {" "}
                  COMPLIMENTARY&nbsp;&nbsp;LUXURY
                  <br />
                  &nbsp;&nbsp;TRANSPORT SERVICES
                  <br />
                  &nbsp;&nbsp;BEGINNING AT THE AIRPORT
                </p>
              </div>
              <div className="group-317">
                <p className="text-wrapper-161">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
                <p className="text-wrapper-162">
                  HELICOPTER ARRIVALS ARE
                  <br /> AVAILABLE UPON REQUEST
                </p>
              </div>
            </div>
            <div className="group-318">
              <div className="text-wrapper-163">OFFERED</div>
              <div className="group-319">
                <div className="overlap-group-33">
                  <div className="SPONSORSHIP-PACKAGES-2">
                    SPONSORSHIP&nbsp;&nbsp;
                    <br />
                    PACKAGES
                  </div>
                  <div className="rectangle-109" />
                  <div className="rectangle-110" />
                </div>
              </div>
            </div>
            <a
              className="group-320"
              href="https://api.whatsapp.com/send/?phone=19548823115"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-36">
                <div className="rectangle-111" />
                <div className="text-wrapper-164">BECOME A SPONSOR</div>
              </div>
            </a>
            <a className="group-321" href="https://sokail.ai" rel="noopener noreferrer" target="_blank">
              <img className="group-322" alt="Group" src="/img/group-48098719.png" />
              <div className="PRESENTED-BY-6">PRESENTED&nbsp;&nbsp;BY</div>
            </a>
          </>
        )}

        <img
          className="mask-group-18"
          style={{
            height: screenWidth < 1280 ? "173px" : screenWidth >= 1280 ? "40px" : undefined,
            left: screenWidth < 1280 ? "0" : screenWidth >= 1280 ? "571px" : undefined,
            top: screenWidth < 1280 ? "8669px" : screenWidth >= 1280 ? "7926px" : undefined,
            width: screenWidth < 1280 ? "390px" : screenWidth >= 1280 ? "168px" : undefined,
          }}
          alt="Mask group"
          src={
            screenWidth < 1280
              ? "/img/mask-group-9.png"
              : screenWidth >= 1280
              ? "/img/group-48098605-2x.png"
              : undefined
          }
        />
        {screenWidth < 1280 && (
          <>
            <img className="mask-group-19" alt="Mask group" src="/img/mask-group-9.png" />
            <div className="group-323">
              <div className="overlap-33">
                <img className="vector-26" alt="Vector" src="/img/vector-233-4.svg" />
                <img className="vector-27" alt="Vector" src="/img/vector-244-1-2.svg" />
                <img className="group-262" alt="Group" src="/img/group-48098648-3.png" />
              </div>
              <img className="vector-28" alt="Vector" src="/img/vector-231-1-4.svg" />
              <img className="vector-29" alt="Vector" src="/img/vector-232-1-4.svg" />
              <img className="vector-30" alt="Vector" src="/img/vector-235-1-2.svg" />
              <img className="vector-31" alt="Vector" src="/img/vector-236-1.svg" />
            </div>
            <div className="text-wrapper-165">FULL MOON AWAKENING</div>
            <div className="SPONSOR-OUR-4">SPONSOR&nbsp;&nbsp;OUR</div>
            <div className="text-wrapper-166">July 20th</div>
            <div className="group-324">
              <div className="rectangle-112" />
              <div className="rectangle-113" />
              <div className="text-wrapper-167">7pm - 7am</div>
            </div>
            <SocialIcons color="negative" platform="telegram" />
            <ConcreteComponentNode color="negative" platform="x-twitter" />
            <SocialIcons color="negative" platform="instagram" />
            <PlatformWhatsappColorOriginal1 className="platform-whatsapp-color-original-1" color="white" />
            <SocialIcons4 className="social-icons-4" />
            <div className="group-325">
              <div className="rectangle-114" />
              <div className="group-326">
                <p className="text-wrapper-168">
                  CONTINUOUS ADVERTISEMENT ON HIGHLY
                  VISIBLE&nbsp;&nbsp;LIFE&nbsp;&nbsp;SIZE&nbsp;&nbsp;NFT&nbsp;&nbsp;PANELS
                </p>
                <div className="group-327">
                  <div className="check-wrapper">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="div-4">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="overlap-group-34">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="overlap-group-35">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="div-5">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-328">
              <div className="rectangle-115" />
              <div className="group-329">
                <div className="text-wrapper-168">BRING A +1</div>
                <div className="group-330">
                  <div className="check-wrapper">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="div-4">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="overlap-group-34">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="overlap-group-35">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="div-5">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-331">
              <div className="rectangle-114" />
              <div className="group-326">
                <div className="text-wrapper-168">
                  DISTRIBUTION&nbsp;&nbsp;OF&nbsp;&nbsp;PRODUCT&nbsp;&nbsp;SAMPLES
                  <br /> VIA&nbsp;&nbsp;OUR&nbsp;&nbsp;VIP&nbsp;&nbsp;GIFT&nbsp;&nbsp;BOXES
                </div>
                <div className="group-327">
                  <div className="check-wrapper">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="div-4">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="overlap-group-34">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="overlap-group-35">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                  <div className="div-5">
                    <img className="check" alt="Check" src="/img/check.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-332">
              <div className="rectangle-116" />
              <div className="group-333">
                <div className="text-wrapper-168">
                  PRIVATE&nbsp;&nbsp;PRESENTATION&nbsp;&nbsp;SLOT&nbsp;&nbsp;IN
                  <br /> ENCLOSED&nbsp;&nbsp;AREA&nbsp;&nbsp;ADVERTISED&nbsp;&nbsp;TO
                  <br /> ATTENDEES
                </div>
                <div className="group-334">
                  <div className="overlap-37">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-336">
              <div className="rectangle-114" />
              <div className="group-326">
                <p className="text-wrapper-168">ENGRAVINGS ON ALL OF OUR&nbsp;&nbsp;BAR&nbsp;&nbsp;GLASSES</p>
                <div className="group-327">
                  <div className="overlap-37">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-337">
              <div className="rectangle-115" />
              <div className="group-329">
                <div className="text-wrapper-168">BRING A +5</div>
                <div className="group-330">
                  <div className="overlap-37">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-338">
              <div className="rectangle-114" />
              <div className="group-326">
                <p className="text-wrapper-168">PRIVATE CONCIERGE FOR VIP ACCESS ANYWHERE IN MILAN</p>
                <div className="group-327">
                  <div className="overlap-37">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-339">
              <div className="rectangle-115" />
              <div className="group-329">
                <div className="text-wrapper-168">BRING +10</div>
                <div className="group-330">
                  <div className="overlap-37">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-340">
              <div className="rectangle-115" />
              <div className="group-329">
                <div className="text-wrapper-168">ABOVE&nbsp;&nbsp;LISTED&nbsp;&nbsp;EXCLUSIVE&nbsp;&nbsp;SERVICES</div>
                <div className="group-330">
                  <div className="overlap-37">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-341">
              <div className="rectangle-116" />
              <div className="group-333">
                <div className="text-wrapper-168">
                  PROFESSIONAL ADVERTISEMENT&nbsp;&nbsp;PRODUCTION&nbsp;&nbsp; PROMOTED&nbsp;&nbsp;BY&nbsp;&nbsp;OUR{" "}
                  <br />
                  PR&nbsp;&nbsp;&amp;&nbsp;&nbsp;INFLUENCERS
                </div>
                <div className="group-334">
                  <div className="overlap-37">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="group-342">
              <div className="rectangle-114" />
              <div className="group-326">
                <div className="text-wrapper-168">
                  ONGOING&nbsp;&nbsp;BEST&nbsp;&nbsp;IN&nbsp;&nbsp;CLASS&nbsp;&nbsp;MARKETING&nbsp;&nbsp;SERVICES&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY
                  OUR&nbsp;&nbsp;COMPANY
                </div>
                <div className="group-327">
                  <div className="overlap-37">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="rectangle-117" />
            <div className="group-343">
              <p className="text-wrapper-168">EQUITY SHARE IN OUR MULTI-CONGLOMERATE INTERNATIONAL AGENCY AGGREGATE</p>
              <div className="group-334">
                <div className="group-344">
                  <div className="overlap-38">
                    <div className="group-335">
                      <div className="check-wrapper">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="div-4">
                        <img className="check" alt="Check" src="/img/check.png" />
                      </div>
                      <div className="overlap-group-34">
                        <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                      </div>
                      <div className="overlap-group-35">
                        <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                      </div>
                      <div className="div-5" />
                    </div>
                    <img className="vector-32" alt="Vector" src="/img/vector.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="rectangle-118" />
            <div className="group-345">
              <div className="group-344">
                <div className="overlap-38">
                  <div className="group-335">
                    <div className="check-wrapper">
                      <img className="check" alt="Check" src="/img/check.png" />
                    </div>
                    <div className="div-4">
                      <img className="check" alt="Check" src="/img/check.png" />
                    </div>
                    <div className="overlap-group-34">
                      <img className="vector-32" alt="Vector" src="/img/vector-16.svg" />
                    </div>
                    <div className="overlap-group-35">
                      <img className="vector-32" alt="Vector" src="/img/vector-16.svg" />
                    </div>
                    <div className="div-5" />
                  </div>
                  <img className="vector-32" alt="Vector" src="/img/vector-16.svg" />
                </div>
              </div>
            </div>
            <div className="group-346">
              <img className="rectangle-119" alt="Rectangle" src="/img/rectangle-2903.svg" />
              <div className="group-327">
                <div className="overlap-37">
                  <div className="group-335">
                    <div className="overlap-37">
                      <div className="group-335">
                        <div className="check-wrapper">
                          <img className="check" alt="Check" src="/img/check.png" />
                        </div>
                        <div className="div-4" />
                        <div className="overlap-group-34">
                          <img className="vector-32" alt="Vector" src="/img/vector-16.svg" />
                        </div>
                        <div className="overlap-group-35">
                          <img className="vector-32" alt="Vector" src="/img/vector-16.svg" />
                        </div>
                        <div className="div-5" />
                      </div>
                      <img className="vector-32" alt="Vector" src="/img/vector-20.svg" />
                    </div>
                  </div>
                  <img className="vector-33" alt="Vector" src="/img/vector-19.svg" />
                </div>
              </div>
              <div className="group-347">
                <div className="INDEFINITE-VIP-wrapper">
                  <p className="INDEFINITE-VIP">
                    INDEFINITE&nbsp;&nbsp;VIP&nbsp;&nbsp; STATUS&nbsp;&nbsp;AT ALL&nbsp;&nbsp;EVENTS
                    <br />
                    ATTEND&nbsp;&nbsp;SELECT FULLY PRIVATE&nbsp;&nbsp;EVENTS
                  </p>
                </div>
              </div>
            </div>
            <div className="group-348">
              <img className="rectangle-120" alt="Rectangle" src="/img/rectangle-2903.svg" />
              <div className="group-330">
                <div className="overlap-37">
                  <div className="group-335">
                    <div className="overlap-37">
                      <div className="group-335">
                        <div className="check-wrapper">
                          <img className="check" alt="Check" src="/img/check.png" />
                        </div>
                        <div className="div-4" />
                        <div className="overlap-group-34">
                          <img className="vector-32" alt="Vector" src="/img/vector-16.svg" />
                        </div>
                        <div className="overlap-group-35">
                          <img className="vector-32" alt="Vector" src="/img/vector-16.svg" />
                        </div>
                        <div className="div-5" />
                      </div>
                      <img className="vector-32" alt="Vector" src="/img/vector-20.svg" />
                    </div>
                  </div>
                  <img className="vector-33" alt="Vector" src="/img/vector-19.svg" />
                </div>
              </div>
              <div className="group-349">
                <div className="INDEFINITE-ACCESS-TO-wrapper">
                  <div className="text-wrapper-168">INDEFINITE ACCESS TO OUR&nbsp;&nbsp;NETWORK</div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-169">SOKAIL TOKEN ALLOCATION</div>
            <div className="group-350">
              <div className="group-351">
                <div className="text-wrapper-170">10,000€</div>
                <div className="text-wrapper-171">25,000€</div>
                <div className="text-wrapper-172">50,000€</div>
                <div className="text-wrapper-173">100,000€</div>
                <div className="text-wrapper-174">5,000€</div>
              </div>
              <div className="rectangle-121" />
              <div className="rectangle-122" />
              <div className="rectangle-123" />
              <div className="rectangle-124" />
              <div className="rectangle-125" />
            </div>
            <div className="text-wrapper-175">fabiano@sokail.ai</div>
            <p className="text-wrapper-176">
              two slots remaining for VIP sponsorship with additional benefits and pricing
            </p>
            <p className="text-wrapper-177">maximum 150,000€ in sponsorship for the event</p>
            <p className="element-accounted-for-3"> 30,000€ accounted for as of July 4th</p>
            <p className="text-wrapper-178">any commitment before July 6th will have a 20% discount</p>
          </>
        )}
      </div>
    </div>
  );
};
