import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { SocialIcons } from "../../components/SocialIcons";
import { PlatformInstagramColorNegative } from "../../icons/PlatformInstagramColorNegative";
import { PlatformTelegramColorNegative } from "../../icons/PlatformTelegramColorNegative";
import { PlatformXTwitterColorNegative } from "../../icons/PlatformXTwitterColorNegative";
import { SocialIcons4 } from "../../icons/SocialIcons4";
import "./style.css";

export const VipMobile = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="VIP-MOBILE">
      <div
        className="div"
        style={{
          height: screenWidth < 1280 ? "7324px" : screenWidth >= 1280 ? "6697px" : undefined,
          width: screenWidth < 1280 ? "390px" : screenWidth >= 1280 ? "1280px" : undefined,
        }}
      >
        <div
          className="overlap"
          style={{
            height: screenWidth >= 1280 ? "5762px" : screenWidth < 1280 ? "88px" : undefined,
            left: screenWidth >= 1280 ? "-383px" : screenWidth < 1280 ? "54px" : undefined,
            top: screenWidth >= 1280 ? "0" : screenWidth < 1280 ? "100px" : undefined,
            width: screenWidth >= 1280 ? "2046px" : screenWidth < 1280 ? "286px" : undefined,
          }}
        >
          {screenWidth >= 1280 && (
            <>
              <div className="group">
                <div className="group-wrapper">
                  <div className="img-wrapper">
                    <img className="img" alt="Group" src="/img/group-48098457-5.png" />
                  </div>
                </div>
              </div>
              <div className="rectangle-wrapper">
                <div className="rectangle" />
              </div>
              <div className="overlap-group-wrapper">
                <div className="div-2">
                  <div className="group-2">
                    <div className="group-3">
                      <div className="group-4">
                        <div className="div-2">
                          <img className="group-5" alt="Group" src="/img/group-48098457-1.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rectangle-2" />
                  <div className="rectangle-3" />
                </div>
              </div>
              <div className="group-6">
                <div className="group-7">
                  <div className="group-8">
                    <div className="group-9">
                      <div className="rectangle-4" />
                      <div className="rectangle-5" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-10">
                <div className="overlap-group">
                  <img className="vector" alt="Vector" src="/img/vector-233-1-3.svg" />
                  <img className="vector-2" alt="Vector" src="/img/vector-244-1.svg" />
                  <img className="group-11" alt="Group" src="/img/group-48098648-1-3.png" />
                </div>
                <img className="vector-3" alt="Vector" src="/img/vector-231-1.svg" />
                <img className="vector-4" alt="Vector" src="/img/vector-232-1-2.svg" />
                <img className="vector-5" alt="Vector" src="/img/vector-235-1.svg" />
                <img className="vector-6" alt="Vector" src="/img/vector-236-1-4.svg" />
              </div>
              <img className="mask-group" alt="Mask group" src="/img/mask-group-5.png" />
              <div className="rectangle-6" />
              <img className="mask-group-2" alt="Mask group" src="/img/mask-group-6.png" />
              <img className="mask-group-3" alt="Mask group" src="/img/mask-group-3-2x.png" />
              <div className="group-12">
                <div className="rectangle-7" />
                <div className="rectangle-8" />
              </div>
              <div className="group-13">
                <div className="group-14">
                  <div className="group-15">
                    <div className="text-wrapper">EXCLUSIVE</div>
                    <div className="text-wrapper-2">SERVICES</div>
                  </div>
                </div>
                <div className="group-16">
                  <div className="group-17">
                    <div className="group-18">
                      <p className="text-wrapper-3">CONNECTION TO THE TOP HOTELS OF THE AREA</p>
                      <div className="EXCLUSIVE">
                        EXCLUSIVE&nbsp;&nbsp;PARTNERSHIPS&nbsp;&nbsp;WITH&nbsp;&nbsp;THE
                        FINEST&nbsp;&nbsp;HOTELS&nbsp;&nbsp;IN&nbsp;&nbsp;THE&nbsp;&nbsp;REGION
                      </div>
                    </div>
                    <div className="group-19">
                      <div className="text-wrapper-4">PRIVATE TRANSPORTATION AND RESERVATIONS</div>
                      <div className="text-wrapper-5">
                        {" "}
                        SPECIAL&nbsp;&nbsp;BOOKING&nbsp;&nbsp;RATES&nbsp;&nbsp;FOR&nbsp;&nbsp;LUXURY&nbsp;&nbsp;TRANSPORT
                        SERVICES
                      </div>
                    </div>
                    <div className="group-20">
                      <div className="group-21">
                        <p className="text-wrapper-6">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
                        <div className="text-wrapper-7">PROFESSIONALLY MANAGED PARKING SERVICE</div>
                      </div>
                    </div>
                    <div className="group-22">
                      <p className="text-wrapper-7">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
                      <p className="text-wrapper-6">HELICOPTER ARRIVALS ARE AVAILABLE UPON REQUEST</p>
                    </div>
                  </div>
                </div>
                <div className="FULL-MOON-AWAKENING">FULL&nbsp;&nbsp;MOON&nbsp;&nbsp;AWAKENING</div>
                <div className="CORDIALLY-INVITED-BY">CORDIALLY INVITED&nbsp;&nbsp;BY</div>
                <div className="FABIANO-VALLI">FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;PISETZKY &amp;&nbsp;&nbsp;co</div>
                <div className="text-wrapper-8">July 20th</div>
                <div className="text-wrapper-9">7pm - 7am</div>
                <div className="group-23">
                  <div className="group-24">
                    <div className="text-wrapper-10">EVENT SPECIALS</div>
                    <div className="EXPERIENCE-THE">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
                  </div>
                  <div className="group-25">
                    <div className="group-26">
                      <div className="group-27">
                        <div className="text-wrapper-3">EXCLUSIVE LOCATION</div>
                        <div className="AN-EXPERTLY">
                          AN EXPERTLY
                          ARCHITECTED&nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A&nbsp;&nbsp;LUXURY&nbsp;&nbsp;VILLA
                        </div>
                      </div>
                      <div className="group-28">
                        <div className="text-wrapper-11">SKL SS 2024 CATWALK</div>
                        <div className="text-wrapper-5">
                          FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM&nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp;
                          THE&nbsp;&nbsp;US
                        </div>
                      </div>
                      <div className="group-29">
                        <div className="text-wrapper-6">
                          CRYSTAL AND&nbsp;&nbsp;AND&nbsp;&nbsp; FREQUENCY&nbsp;&nbsp;HEALING&nbsp;&nbsp;SESSIONS
                        </div>
                        <p className="text-wrapper-7">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
                      </div>
                      <div className="group-30">
                        <div className="text-wrapper-6">
                          SPECIAL&nbsp;&nbsp;GIFTS&nbsp;&nbsp;FROM&nbsp;&nbsp;OUR&nbsp;&nbsp;TOP&nbsp;&nbsp;GLOBAL&nbsp;&nbsp;SPONSORS
                        </div>
                        <p className="text-wrapper-7">GIFT BOXES FROM SPONSORS TO VIP</p>
                      </div>
                      <div className="group-31">
                        <div className="text-wrapper-7">TOP UNDERGROUND DJS</div>
                        <div className="text-wrapper-6">
                          SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE&nbsp;&nbsp;UNDERGROUND
                        </div>
                      </div>
                      <div className="group-32">
                        <p className="p">PRIVATE VIP ACCESS TO SECRET AREAS</p>
                        <div className="text-wrapper-5">
                          {" "}
                          UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES&nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE
                          THE&nbsp;&nbsp;SPACE
                        </div>
                      </div>
                      <div className="group-33">
                        <div className="group-21">
                          <div className="text-wrapper-7">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
                          <div className="text-wrapper-6">
                            A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
                            THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="overlap-group-2">
                      <div className="group-34">
                        <div className="group-35">
                          <div className="group-36">
                            <div className="group-37">
                              <div className="group-38">
                                <div className="group-21">
                                  <div className="text-wrapper-6">
                                    RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF
                                    AN&nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
                                  </div>
                                  <div className="text-wrapper-7">LUXURY PRIVATE YURTS</div>
                                </div>
                              </div>
                            </div>
                            <div className="group-39">
                              <div className="text-wrapper-6">
                                WITNESS A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED&nbsp;&nbsp;TALENT PERFORM
                              </div>
                              <div className="text-wrapper-7">
                                LIVE&nbsp;&nbsp;VIP&nbsp;&nbsp;TATTOO&nbsp;&nbsp;ARTISTS
                              </div>
                            </div>
                            <div className="group-40">
                              <div className="group-21">
                                <div className="text-wrapper-6">
                                  A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION&nbsp;&nbsp;TAI:LORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
                                </div>
                                <div className="text-wrapper-7">LIGHTING DESIGN EXPERIENCE</div>
                              </div>
                            </div>
                            <div className="group-20">
                              <div className="group-21">
                                <div className="text-wrapper-6">
                                  IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC
                                  TO&nbsp;&nbsp;CREATE&nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
                                </div>
                                <p className="text-wrapper-7">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
                              </div>
                            </div>
                            <div className="group-41">
                              <p className="text-wrapper-12">PRESENTATION OF OUR PRIVATE ART GALLERY</p>
                              <div className="text-wrapper-5">
                                ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;SPECIALTY
                                NFC&nbsp;&nbsp;RINGS
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="HIGHLY-TALENTED">
                          HIGHLY TALENTED&nbsp;&nbsp;ARTISTS PERFORMING THROUGHOUT THE&nbsp;&nbsp;NIGHT
                        </p>
                      </div>
                      <div className="LIVE-ART-PRODUCTIONS-wrapper">
                        <div className="text-wrapper-7">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overlap-wrapper">
                <div className="overlap-2">
                  <div className="group-42">
                    <div className="group-43">
                      <div className="group-44">
                        <div className="group-45">
                          <div className="overlap-group-3">
                            <img className="image" alt="Image" src="/img/image-371.png" />
                            <div className="rectangle-9" />
                            <div className="rectangle-10" />
                            <div className="rectangle-11" />
                            <div className="rectangle-12" />
                            <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-2827-2.svg" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rectangle-14" />
                    <img
                      className="AAA-logo-test"
                      alt="Aaa logo test"
                      src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
                    />
                    <div className="rectangle-15" />
                  </div>
                  <img className="milano-italy" alt="Milano italy" src="/img/milano-italy.svg" />
                  <p className="text-wrapper-13">Let us know before 12th of July</p>
                  <div className="text-wrapper-14">For More Info, contact</div>
                  <div className="f-sokail-ai-j-sokail">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
                </div>
              </div>
              <a
                className="group-46"
                href="https://api.whatsapp.com/send/?phone=19548823115"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="overlap-3">
                  <div className="rectangle-16" />
                  <div className="text-wrapper-15">Get Access</div>
                </div>
              </a>
              <a
                className="group-47"
                href="https://api.whatsapp.com/send/?phone=19548823115"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="overlap-3">
                  <div className="rectangle-16" />
                  <div className="text-wrapper-15">Get Access</div>
                </div>
              </a>
            </>
          )}

          {screenWidth < 1280 && (
            <>
              <div className="text-wrapper-16">AWAKENING</div>
              <div className="FULL-MOON">FULL&nbsp;&nbsp;MOON</div>
            </>
          )}
        </div>
        {screenWidth < 1280 && (
          <>
            <div className="group-48">
              <div className="overlap-group-4">
                <img className="vector-7" alt="Vector" src="/img/vector-233-2.svg" />
                <img className="vector-8" alt="Vector" src="/img/vector-244-4.svg" />
                <img className="group-49" alt="Group" src="/img/group-48098648-7.png" />
              </div>
              <img className="vector-9" alt="Vector" src="/img/vector-231-2.svg" />
              <img className="vector-10" alt="Vector" src="/img/vector-232-5.svg" />
              <img className="vector-11" alt="Vector" src="/img/image.svg" />
              <img className="vector-12" alt="Vector" src="/img/vector-236-5.svg" />
            </div>
            <div className="text-wrapper-17">July 20th</div>
            <div className="text-wrapper-18">7pm - 7am</div>
          </>
        )}

        {screenWidth >= 1280 && (
          <>
            <div className="group-50">
              <div className="group-51">
                <div className="PRESENTED-BY-wrapper">
                  <div className="PRESENTED-BY">PRESENTED&nbsp;&nbsp;BY</div>
                </div>
              </div>
            </div>
            <img className="group-52" alt="Group" src="/img/group-48098605-5.png" />
          </>
        )}

        <a
          className="group-53"
          style={{
            height: screenWidth >= 1280 ? "160px" : screenWidth < 1280 ? "95px" : undefined,
            left: screenWidth >= 1280 ? "366px" : screenWidth < 1280 ? "26px" : undefined,
            top: screenWidth >= 1280 ? "5762px" : screenWidth < 1280 ? "701px" : undefined,
            width: screenWidth >= 1280 ? "160px" : screenWidth < 1280 ? "340px" : undefined,
          }}
          href="https://api.whatsapp.com/send/?phone=19548823115"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div
            className="overlap-4"
            style={{
              height: screenWidth >= 1280 ? "160px" : screenWidth < 1280 ? "95px" : undefined,
              width: screenWidth < 1280 ? "338px" : undefined,
            }}
          >
            {screenWidth >= 1280 && (
              <>
                <div className="rectangle-17" />
                <img className="group-54" alt="Group" src="/img/group-48098911.png" />
              </>
            )}

            {screenWidth < 1280 && (
              <>
                <div className="rectangle-16" />
                <div className="text-wrapper-15">Get Access</div>
              </>
            )}
          </div>
        </a>
        {screenWidth >= 1280 && (
          <>
            <a
              className="group-55"
              href="https://www.instagram.com/_pisetzky"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-5">
                <div className="rectangle-17" />
                <SocialIcons color="negative" platform="instagram" />
              </div>
            </a>
            <a
              className="group-56"
              href="https://api.whatsapp.com/send/?phone=19548823115"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="group-57">
                <div className="overlap-group-5">
                  <div className="rectangle-17" />
                  <SocialIcons color="negative" platform="messenger" />
                </div>
              </div>
            </a>
            <a className="group-58" href="https://t.me/leodavincian" rel="noopener noreferrer" target="_blank">
              <div className="overlap-5">
                <div className="rectangle-17" />
                <SocialIcons color="negative" platform="telegram" />
              </div>
            </a>
            <a className="group-59" href="https://x.com/pisetzkymusic" rel="noopener noreferrer" target="_blank">
              <div className="overlap-5">
                <div className="rectangle-17" />
                <ConcreteComponentNode color="negative" platform="x-twitter" />
              </div>
            </a>
          </>
        )}

        {screenWidth < 1280 && (
          <>
            <div className="overlap-6">
              <div className="group-60">
                <div className="FABIANO-VALLI-2">FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;PISETZKY</div>
                <div className="co">&amp;&nbsp;&nbsp;co</div>
                <div className="text-wrapper-19">CORDIALLY INVITED BY</div>
                <div className="overlap-7">
                  <div className="rectangle-18" />
                </div>
              </div>
              <img className="mask-group-4" alt="Mask group" src="/img/mask-group-11.png" />
            </div>
            <a
              className="group-61"
              href="https://api.whatsapp.com/send/?phone=19548823115"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-3">
                <div className="rectangle-16" />
                <div className="text-wrapper-15">Get Access</div>
              </div>
            </a>
            <div className="overlap-8">
              <img
                className="AAA-logo-test-2"
                alt="Aaa logo test"
                src="/img/aaa-logo-test-4-removebg-preview-1-2-2x.png"
              />
              <div className="group-62">
                <div className="group-63">
                  <div className="rectangle-19" />
                  <div className="rectangle-20" />
                  <img
                    className="AAA-logo-test-3"
                    alt="Aaa logo test"
                    src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
                  />
                </div>
              </div>
            </div>
            <div className="group-64">
              <div className="text-wrapper-20">SERVICES</div>
              <div className="text-wrapper-21">EXCLUSIVE</div>
              <div className="group-65">
                <p className="text-wrapper-22">
                  CONNECTION TO THE <br />
                  TOP HOTELS OF THE AREA
                </p>
                <p className="text-wrapper-23">
                  EXCLUSIVE&nbsp;&nbsp;PARTNERSHIPS&nbsp;&nbsp;WITH
                  <br />
                  THE FINEST HOTELS IN THE&nbsp;&nbsp;REGION
                </p>
              </div>
              <div className="group-66">
                <div className="text-wrapper-22">
                  PRIVATE TRANSPORTATION <br />
                  AND RESERVATIONS
                </div>
                <div className="text-wrapper-23">
                  SPECIAL&nbsp;&nbsp;BOOKING&nbsp;&nbsp;RATES&nbsp;&nbsp;FOR
                  <br />
                  &nbsp;&nbsp;LUXURY TRANSPORT SERVICES
                </div>
              </div>
              <div className="group-67">
                <p className="text-wrapper-24">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
                <p className="text-wrapper-23">
                  HELICOPTER ARRIVALS ARE
                  <br /> AVAILABLE UPON REQUEST
                </p>
              </div>
              <div className="group-68">
                <div className="text-wrapper-22">PROFESSIONALLY MANAGED PARKING SERVICE</div>
                <p className="text-wrapper-23">
                  COMPLIMENTARY VALET SERVICE
                  <br /> AT THE VENUE
                </p>
              </div>
              <div className="rectangle-21" />
              <div className="group-69">
                <div className="flexcontainer">
                  <p className="text">
                    <span className="span">
                      EXPERIENCE&nbsp;&nbsp;
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="span">THE&nbsp;&nbsp; UNTOUCHABLE</span>
                  </p>
                </div>
                <div className="group-70">
                  <div className="text-wrapper-22">EXCLUSIVE LOCATION</div>
                  <div className="text-wrapper-25">
                    AN EXPERTLY ARCHITECTED
                    <br />
                    &nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A
                    <br />
                    LUXURY&nbsp;&nbsp;VILLA
                  </div>
                </div>
                <div className="group-71">
                  <div className="text-wrapper-22">SKL SS 2024 CATWALK</div>
                  <div className="text-wrapper-25">
                    FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM
                    <br />
                    &nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp; THE&nbsp;&nbsp;US
                  </div>
                </div>
                <div className="group-72">
                  <div className="text-wrapper-22">TOP UNDERGROUND DJS</div>
                  <div className="text-wrapper-25">
                    SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE
                    <br />
                    &nbsp;&nbsp;UNDERGROUND
                  </div>
                </div>
                <div className="group-73">
                  <p className="text-wrapper-22">
                    PRIVATE VIP ACCESS <br />
                    TO SECRET AREAS
                  </p>
                  <div className="text-wrapper-23">
                    UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES
                    <br />
                    &nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE THE&nbsp;&nbsp;SPACE
                  </div>
                </div>
                <div className="group-74">
                  <div className="text-wrapper-22">PROPER FESTIVAL STAGE</div>
                  <div className="text-wrapper-25">
                    A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
                    <br /> THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
                  </div>
                </div>
                <div className="group-75">
                  <div className="text-wrapper-22">LUXURY PRIVATE YURTS</div>
                  <div className="text-wrapper-25">
                    RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF AN&nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
                  </div>
                </div>
                <div className="group-76">
                  <div className="text-wrapper-22">LIVE VIP TATTOO ARTISTS</div>
                  <div className="text-wrapper-25">
                    WITNESS&nbsp;&nbsp;A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED
                    <br />
                    &nbsp;&nbsp;TALENT PERFORM
                  </div>
                </div>
                <div className="group-77">
                  <div className="text-wrapper-22">LIGHTING DESIGN EXPERIENCE</div>
                  <div className="text-wrapper-25">
                    A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION
                    <br />
                    &nbsp;&nbsp;TAILORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
                  </div>
                </div>
                <div className="group-78">
                  <div className="text-wrapper-22">EXOTIC SMOKING LOUNGE</div>
                  <div className="IMPORTING-THE-FINEST">
                    IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;FINEST TO&nbsp;&nbsp;CREATE
                    <br />
                    &nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
                  </div>
                </div>
                <div className="group-79">
                  <p className="text-wrapper-22">
                    PRESENTATION OF OUR <br />
                    PRIVATE ART GALLERY
                  </p>
                  <div className="text-wrapper-23">
                    ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED
                    <br />
                    &nbsp;&nbsp;SPECIALTY NFC&nbsp;&nbsp;RINGS
                  </div>
                </div>
                <div className="group-80">
                  <div className="text-wrapper-22">LIVE ART PRODUCTIONS</div>
                  <p className="text-wrapper-25">
                    HIGHLY TALENTED&nbsp;&nbsp;ARTISTS PERFORMING THROUGHOUT THE&nbsp;&nbsp;NIGHT
                  </p>
                </div>
                <div className="group-81">
                  <p className="text-wrapper-22">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
                  <div className="text-wrapper-23">
                    CRYSTAL AND FREQUENCY
                    <br />
                    HEALING SESSIONS
                  </div>
                </div>
                <div className="group-82">
                  <p className="text-wrapper-22">
                    GIFT BOXES <br />
                    FROM SPONSORS TO VIP
                  </p>
                  <div className="text-wrapper-23">
                    SPECIAL GIFTS&nbsp;&nbsp;FROM
                    <br />
                    OUR&nbsp;&nbsp;TOP&nbsp;&nbsp;GLOBAL&nbsp;&nbsp;SPONSORS
                  </div>
                </div>
                <div className="text-wrapper-26">EVENT SPECIALS</div>
                <div className="rectangle-22" />
                <div className="rectangle-23" />
              </div>
            </div>
            <div className="rectangle-24" />
            <div className="text-wrapper-27">Secret</div>
            <img className="location" alt="Location" src="/img/location-2.svg" />
            <div className="text-wrapper-28">CONTACT US FOR LOCATION</div>
            <div className="text-wrapper-29">For more Info, contact</div>
            <div className="f-sokail-ai-j-sokail-2">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
            <img className="group-83" alt="Group" src="/img/group-48098605-8.png" />
            <div className="PRESENTED-BY-2">PRESENTED&nbsp;&nbsp;BY</div>
            <a
              className="group-84"
              href="https://api.whatsapp.com/send/?phone=19548823115"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-9">
                <div className="rectangle-25" />
                <img className="instance-node" alt="Group" src="/img/group-48098911-1.png" />
              </div>
            </a>
            <a
              className="group-85"
              href="https://www.instagram.com/_pisetzky"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-9">
                <div className="rectangle-25" />
                <PlatformInstagramColorNegative className="instance-node" />
              </div>
            </a>
            <a
              className="group-86"
              href="https://www.messenger.com/t/480455975379128/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="group-87">
                <div className="overlap-group-6">
                  <div className="rectangle-25" />
                  <SocialIcons4 className="instance-node" />
                </div>
              </div>
            </a>
            <a className="group-88" href="https://t.me/leodavincian" rel="noopener noreferrer" target="_blank">
              <div className="overlap-9">
                <div className="rectangle-25" />
                <PlatformTelegramColorNegative className="instance-node" />
              </div>
            </a>
            <a className="group-89" href="https://x.com/pisetzkymusic" rel="noopener noreferrer" target="_blank">
              <div className="overlap-9">
                <div className="rectangle-25" />
                <PlatformXTwitterColorNegative className="instance-node" color="white" />
              </div>
            </a>
            <div className="text-wrapper-30">SECRET GUEST LINEUP</div>
            <div className="text-wrapper-31">SECRET GUEST (AFTERLIFE)</div>
            <div className="text-wrapper-32">SECRET GUEST (AFTERLIFE)</div>
            <div className="text-wrapper-33">SECRET GUEST (44 LABEL)</div>
            <div className="text-wrapper-34">PISETZKY (JUST THIS)</div>
            <div className="ALTMAN-JUST-THIS">ALTMAN&nbsp;&nbsp;(JUST THIS/VOLT )</div>
            <div className="STILL-i-JUST-THIS">STILL.I&nbsp;&nbsp;(JUST THIS/VOLT)</div>
            <div className="CANIKLEIN-LOS">CANIKLEIN&nbsp;&nbsp;(LOS ARQUEROS)</div>
            <div className="ZIONER-WITHLOVE">ZIONER&nbsp;&nbsp;(WITHLOVE)</div>
            <div className="SINAI-ALIS">SINAI &amp; ALIS&nbsp;&nbsp;(RIITUALISMO)</div>
            <p className="text-wrapper-35">KAREN MENAD ( U. T. D.)</p>
            <div className="hyper-ring">HYPERRING</div>
            <img className="logo-flowergood" alt="Logo flowergood" src="/img/logo-flowergood-1.png" />
            <img className="LOGO-FLOWER-SPECIAL" alt="Logo FLOWER SPECIAL" src="/img/logo-flower-special-1.png" />
            <img className="uber-logo" alt="Uber logo" src="/img/uber-logo-2018-2-1.png" />
            <div className="overlap-10">
              <img className="screenshot" alt="Screenshot" src="/img/screenshot-2024-07-15-at-13-28-05-1.png" />
              <img className="empyriasoft-logo-fab" alt="Empyriasoft logo fab" src="/img/empyriasoft-logo-fab-1.png" />
            </div>
            <img className="screenshot-2" alt="Screenshot" src="/img/screenshot-2024-07-15-at-1-ddd34-04-pm-1.png" />
            <img className="silo-logo-bg" alt="Silo logo bg" src="/img/silo-logo-bg-1.png" />
          </>
        )}
      </div>
    </div>
  );
};
