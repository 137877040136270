/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const SocialIcons4 = ({ className }) => {
  return (
    <svg
      className={`social-icons-4 ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M0 23.28C0 9.906 10.482 0 24 0C37.518 0 48 9.912 48 23.286C48 36.66 37.518 46.566 24 46.566C21.57 46.566 19.242 46.242 17.052 45.642C16.626 45.528 16.17 45.558 15.768 45.738L11.004 47.838C10.7163 47.9649 10.4018 48.0192 10.0882 47.9961C9.77453 47.9731 9.47137 47.8733 9.20533 47.7056C8.93929 47.5379 8.71852 47.3075 8.5624 47.0345C8.40628 46.7615 8.31961 46.4543 8.31 46.14L8.178 41.868C8.166 41.34 7.926 40.848 7.536 40.5C2.868 36.324 0 30.276 0 23.28ZM16.638 18.906L9.58802 30.09C8.91002 31.164 10.23 32.37 11.238 31.602L18.81 25.854C19.0592 25.6648 19.3632 25.5618 19.676 25.5607C19.9889 25.5597 20.2936 25.6605 20.544 25.848L26.154 30.054C26.5519 30.3528 27.0075 30.5659 27.4919 30.6798C27.9763 30.7937 28.4791 30.806 28.9685 30.7158C29.4579 30.6255 29.9232 30.4349 30.3352 30.1557C30.7472 29.8766 31.0968 29.5151 31.362 29.094L38.418 17.916C39.09 16.842 37.77 15.63 36.762 16.398L29.19 22.146C28.9409 22.3352 28.6369 22.4382 28.324 22.4393C28.0112 22.4403 27.7065 22.3395 27.456 22.152L21.846 17.946C21.4481 17.6472 20.9926 17.4341 20.5081 17.3202C20.0237 17.2063 19.5209 17.194 19.0316 17.2843C18.5422 17.3745 18.0768 17.5652 17.6648 17.8443C17.2529 18.1234 16.9032 18.4849 16.638 18.906Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
