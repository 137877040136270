/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { PlatformAppleColorNegative1 } from "../../icons/PlatformAppleColorNegative1";
import { PlatformClubhouseColorNegative } from "../../icons/PlatformClubhouseColorNegative";
import { PlatformClubhouseColorOriginal } from "../../icons/PlatformClubhouseColorOriginal";
import { PlatformDiscordColorNegative } from "../../icons/PlatformDiscordColorNegative";
import { PlatformDiscordColorOriginal } from "../../icons/PlatformDiscordColorOriginal";
import { PlatformDribbbleColorNegative } from "../../icons/PlatformDribbbleColorNegative";
import { PlatformDribbbleColorOriginal } from "../../icons/PlatformDribbbleColorOriginal";
import { PlatformFacebookColorNegative1 } from "../../icons/PlatformFacebookColorNegative1";
import { PlatformFacebookColorOriginal1 } from "../../icons/PlatformFacebookColorOriginal1";
import { PlatformFigmaColorNegative1 } from "../../icons/PlatformFigmaColorNegative1";
import { PlatformFigmaColorOriginal } from "../../icons/PlatformFigmaColorOriginal";
import { PlatformGithubColorNegative1 } from "../../icons/PlatformGithubColorNegative1";
import { PlatformGoogleColorNegative1 } from "../../icons/PlatformGoogleColorNegative1";
import { PlatformInstagramColorNegative } from "../../icons/PlatformInstagramColorNegative";
import { PlatformLinkedinColorNegative } from "../../icons/PlatformLinkedinColorNegative";
import { PlatformLinkedinColorOriginal } from "../../icons/PlatformLinkedinColorOriginal";
import { PlatformMediumColorNegative1 } from "../../icons/PlatformMediumColorNegative1";
import { PlatformMessengerColorOriginal } from "../../icons/PlatformMessengerColorOriginal";
import { PlatformPinterestColorNegative } from "../../icons/PlatformPinterestColorNegative";
import { PlatformPinterestColorOriginal1 } from "../../icons/PlatformPinterestColorOriginal1";
import { PlatformRedditColorNegative } from "../../icons/PlatformRedditColorNegative";
import { PlatformRedditColorOriginal1 } from "../../icons/PlatformRedditColorOriginal1";
import { PlatformSignalColorNegative } from "../../icons/PlatformSignalColorNegative";
import { PlatformSnapchatColorNegative } from "../../icons/PlatformSnapchatColorNegative";
import { PlatformSnapchatColorOriginal1 } from "../../icons/PlatformSnapchatColorOriginal1";
import { PlatformSpotifyColorOriginal1 } from "../../icons/PlatformSpotifyColorOriginal1";
import { PlatformTelegramColorNegative } from "../../icons/PlatformTelegramColorNegative";
import { PlatformTelegramColorOriginal } from "../../icons/PlatformTelegramColorOriginal";
import { PlatformThreadsColorNegative } from "../../icons/PlatformThreadsColorNegative";
import { PlatformTiktokColorNegative } from "../../icons/PlatformTiktokColorNegative";
import { PlatformTiktokColorOriginal } from "../../icons/PlatformTiktokColorOriginal";
import { PlatformTumblrColorOriginal } from "../../icons/PlatformTumblrColorOriginal";
import { PlatformTwitchColorNegative1 } from "../../icons/PlatformTwitchColorNegative1";
import { PlatformTwitchColorOriginal } from "../../icons/PlatformTwitchColorOriginal";
import { PlatformVkColorNegative } from "../../icons/PlatformVkColorNegative";
import { PlatformVkColorOriginal1 } from "../../icons/PlatformVkColorOriginal1";
import { PlatformWhatsappColorOriginal1 } from "../../icons/PlatformWhatsappColorOriginal1";
import { PlatformXTwitterColorNegative } from "../../icons/PlatformXTwitterColorNegative";
import { PlatformYoutubeColorNegative1 } from "../../icons/PlatformYoutubeColorNegative1";
import { PlatformYoutubeColorOriginal } from "../../icons/PlatformYoutubeColorOriginal";
import { SocialIcons4 } from "../../icons/SocialIcons4";
import "./style.css";

export const SocialIcons = ({ platform, color }) => {
  return (
    <>
      {color === "negative" && platform === "messenger" && <SocialIcons4 className="social-icons" />}

      {color === "negative" && platform === "twitch" && <PlatformTwitchColorNegative1 className="social-icons" />}

      {platform === "spotify" && (
        <PlatformSpotifyColorOriginal1 className="social-icons" color={color === "original" ? "#1ED760" : "white"} />
      )}

      {platform === "VK" && color === "negative" && <PlatformVkColorNegative className="social-icons" />}

      {platform === "signal" && (
        <PlatformSignalColorNegative className="social-icons" color={color === "original" ? "#3A76F0" : "white"} />
      )}

      {platform === "clubhouse" && color === "negative" && <PlatformClubhouseColorNegative className="social-icons" />}

      {color === "negative" && platform === "telegram" && <PlatformTelegramColorNegative className="social-icons" />}

      {platform === "tumblr" && (
        <PlatformTumblrColorOriginal className="social-icons" color={color === "original" ? "#001935" : "white"} />
      )}

      {platform === "tik-tok" && color === "negative" && <PlatformTiktokColorNegative className="social-icons" />}

      {color === "negative" && platform === "discord" && <PlatformDiscordColorNegative className="social-icons" />}

      {color === "negative" && platform === "reddit" && <PlatformRedditColorNegative className="social-icons" />}

      {color === "negative" && platform === "dribbble" && <PlatformDribbbleColorNegative className="social-icons" />}

      {color === "negative" && platform === "figma" && <PlatformFigmaColorNegative1 className="social-icons" />}

      {platform === "whats-app" && (
        <PlatformWhatsappColorOriginal1 className="social-icons" color={color === "original" ? "#25D366" : "white"} />
      )}

      {platform === "threads" && (
        <PlatformThreadsColorNegative className="social-icons" color={color === "original" ? "black" : "white"} />
      )}

      {platform === "github" && (
        <PlatformGithubColorNegative1 className="social-icons" color={color === "original" ? "#24292F" : "white"} />
      )}

      {platform === "medium" && (
        <PlatformMediumColorNegative1 className="social-icons" color={color === "original" ? "black" : "white"} />
      )}

      {color === "negative" && platform === "pinterest" && <PlatformPinterestColorNegative className="social-icons" />}

      {platform === "snapchat" && color === "negative" && <PlatformSnapchatColorNegative className="social-icons" />}

      {platform === "apple" && (
        <PlatformAppleColorNegative1 className="social-icons" color={color === "original" ? "black" : "white"} />
      )}

      {color === "negative" && platform === "you-tube" && <PlatformYoutubeColorNegative1 className="social-icons" />}

      {platform === "google" && color === "negative" && <PlatformGoogleColorNegative1 className="social-icons" />}

      {color === "negative" && platform === "linked-in" && <PlatformLinkedinColorNegative className="social-icons" />}

      {platform === "instagram" && color === "negative" && <PlatformInstagramColorNegative className="social-icons" />}

      {platform === "x-twitter" && (
        <PlatformXTwitterColorNegative className="social-icons" color={color === "original" ? "black" : "white"} />
      )}

      {color === "negative" && platform === "facebook" && <PlatformFacebookColorNegative1 className="social-icons" />}

      {color === "original" && platform === "messenger" && (
        <PlatformMessengerColorOriginal className="social-icons" color="url(#paint0_radial_260_3110)" />
      )}

      {color === "original" && platform === "twitch" && <PlatformTwitchColorOriginal className="social-icons" />}

      {color === "original" && platform === "VK" && <PlatformVkColorOriginal1 className="social-icons" />}

      {platform === "clubhouse" && color === "original" && <PlatformClubhouseColorOriginal className="social-icons" />}

      {color === "original" && platform === "telegram" && (
        <PlatformTelegramColorOriginal className="social-icons" color="url(#paint0_linear_260_3133)" />
      )}

      {platform === "tik-tok" && color === "original" && <PlatformTiktokColorOriginal className="social-icons" />}

      {color === "original" && platform === "discord" && <PlatformDiscordColorOriginal className="social-icons" />}

      {color === "original" && platform === "reddit" && <PlatformRedditColorOriginal1 className="social-icons" />}

      {color === "original" && platform === "dribbble" && <PlatformDribbbleColorOriginal className="social-icons" />}

      {color === "original" && platform === "figma" && <PlatformFigmaColorOriginal className="social-icons" />}

      {color === "original" && platform === "pinterest" && <PlatformPinterestColorOriginal1 className="social-icons" />}

      {platform === "snapchat" && color === "original" && <PlatformSnapchatColorOriginal1 className="social-icons" />}

      {color === "original" && platform === "you-tube" && <PlatformYoutubeColorOriginal className="social-icons" />}

      {color === "original" && ["google", "instagram"].includes(platform) && (
        <img
          className="social-icons"
          alt="Platform google"
          src={
            platform === "instagram"
              ? "/img/platform-instagram-color-original.png"
              : "/img/platform-google-color-original.png"
          }
        />
      )}

      {color === "original" && platform === "linked-in" && <PlatformLinkedinColorOriginal className="social-icons" />}

      {color === "original" && platform === "facebook" && <PlatformFacebookColorOriginal1 className="social-icons" />}
    </>
  );
};

SocialIcons.propTypes = {
  platform: PropTypes.oneOf([
    "twitch",
    "facebook",
    "telegram",
    "whats-app",
    "snapchat",
    "github",
    "tik-tok",
    "threads",
    "pinterest",
    "reddit",
    "dribbble",
    "google",
    "you-tube",
    "signal",
    "apple",
    "x-twitter",
    "messenger",
    "VK",
    "spotify",
    "figma",
    "tumblr",
    "linked-in",
    "instagram",
    "discord",
    "clubhouse",
    "medium",
  ]),
  color: PropTypes.oneOf(["negative", "original"]),
};
