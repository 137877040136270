import React from "react";
import "./style.css";

export const Screen14 = () => {
  return (
    <div className="screen-14">
      <div className="SPONSOR-INVITATION-5">
        <div className="overlap-81">
          <div className="group-617">
            <div className="group-618">
              <div className="group-619">
                <img className="group-620" alt="Group" src="/img/group-48098457-3.png" />
              </div>
            </div>
          </div>
          <div className="group-621">
            <div className="rectangle-241" />
          </div>
          <div className="group-622">
            <div className="div-16">
              <div className="group-623">
                <div className="group-624">
                  <div className="group-625">
                    <div className="div-16">
                      <img className="group-626" alt="Group" src="/img/group-48098457-1.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-242" />
              <div className="rectangle-243" />
            </div>
          </div>
          <div className="group-627">
            <div className="group-628">
              <div className="overlap-82">
                <div className="group-629">
                  <div className="overlap-group-62">
                    <img className="image-11" alt="Image" src="/img/image-371-5.png" />
                    <div className="rectangle-244" />
                    <div className="rectangle-245" />
                    <div className="rectangle-246" />
                    <div className="rectangle-247" />
                    <img className="rectangle-248" alt="Rectangle" src="/img/rectangle-2827-1.svg" />
                  </div>
                </div>
                <div className="group-630">
                  <div className="rectangle-249" />
                  <div className="rectangle-250" />
                  <img
                    className="AAA-logo-test-16"
                    alt="Aaa logo test"
                    src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="group-631">
            <div className="group-632">
              <div className="group-633">
                <div className="group-634">
                  <div className="rectangle-251" />
                  <div className="rectangle-252" />
                </div>
              </div>
            </div>
          </div>
          <div className="group-635">
            <div className="overlap-83">
              <img className="vector-83" alt="Vector" src="/img/vector-233-1-3.svg" />
              <img className="vector-84" alt="Vector" src="/img/vector-244-1.svg" />
              <img className="group-636" alt="Group" src="/img/group-48098648-1-3.png" />
            </div>
            <img className="vector-85" alt="Vector" src="/img/vector-231-1.svg" />
            <img className="vector-86" alt="Vector" src="/img/vector-232-1-2.svg" />
            <img className="vector-87" alt="Vector" src="/img/vector-235-1.svg" />
            <img className="vector-88" alt="Vector" src="/img/vector-236-1-4.svg" />
          </div>
          <img className="mask-group-43" alt="Mask group" src="/img/mask-group-5.png" />
          <div className="rectangle-253" />
          <img className="mask-group-44" alt="Mask group" src="/img/mask-group-6.png" />
          <img className="mask-group-45" alt="Mask group" src="/img/mask-group-3-2x.png" />
          <img className="mask-group-46" alt="Mask group" src="/img/mask-group-3-2x.png" />
          <div className="group-637">
            <div className="rectangle-254" />
            <div className="rectangle-255" />
          </div>
          <div className="group-638">
            <div className="group-639">
              <div className="group-640">
                <div className="text-wrapper-354">EXCLUSIVE</div>
                <div className="text-wrapper-355">SERVICES</div>
                <div className="text-wrapper-356">FOR OUR TOP SPONSORS</div>
              </div>
            </div>
            <div className="group-641">
              <div className="group-642">
                <div className="group-643">
                  <p className="text-wrapper-357">COMPLIMENTARY BOOKINGS AT THE TOP HOTELS OF THE AREA</p>
                  <p className="EXCLUSIVE-ACCESS-AND-7">
                    EXCLUSIVE&nbsp;&nbsp;ACCESS AND VIP TREATMENT IN&nbsp;&nbsp;MILAN’S&nbsp;&nbsp;FINEST
                  </p>
                </div>
                <div className="group-644">
                  <div className="text-wrapper-358">PRIVATE TRANSPORTATION ARRANGEMENTS</div>
                  <p className="text-wrapper-359">
                    {" "}
                    COMPLIMENTARY&nbsp;&nbsp;LUXURY&nbsp;&nbsp;TRANSPORT SERVICES&nbsp;&nbsp;BEGINNING AT THE AIRPORT
                  </p>
                </div>
                <div className="group-645">
                  <p className="text-wrapper-360">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
                  <p className="text-wrapper-361">HELICOPTER ARRIVALS ARE AVAILABLE UPON REQUEST</p>
                </div>
              </div>
            </div>
            <img className="basiglio-milano-3" alt="Basiglio milano" src="/img/basiglio-milano-italy.svg" />
            <p className="text-wrapper-362">Let us know before 12th of July</p>
            <div className="text-wrapper-363">For More Info, contact</div>
            <div className="f-sokail-ai-j-sokail-14">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
            <div className="text-wrapper-364">FULL MOON AWAKENING</div>
            <div className="SPONSOR-OUR-6">SPONSOR&nbsp;&nbsp;OUR</div>
            <div className="CORDIALLY-INVITED-BY-4">CORDIALLY INVITED&nbsp;&nbsp;BY</div>
            <div className="FABIANO-VALLI-LEVI-6">
              FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp;&nbsp;&nbsp;co
            </div>
            <div className="text-wrapper-365">July 20th</div>
            <div className="text-wrapper-366">7pm - 7am</div>
            <div className="group-646">
              <div className="group-647">
                <div className="text-wrapper-367">EVENT SPECIALS</div>
                <div className="EXPERIENCE-THE-6">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
              </div>
              <div className="group-648">
                <div className="group-649">
                  <div className="group-650">
                    <div className="text-wrapper-357">EXCLUSIVE LOCATION</div>
                    <div className="AN-EXPERTLY-4">
                      AN EXPERTLY
                      ARCHITECTED&nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A&nbsp;&nbsp;LUXURY&nbsp;&nbsp;VILLA
                    </div>
                  </div>
                  <div className="group-651">
                    <div className="text-wrapper-368">SKL SS 2024 CATWALK</div>
                    <div className="text-wrapper-359">
                      FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM&nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp;
                      THE&nbsp;&nbsp;US
                    </div>
                  </div>
                  <div className="group-652">
                    <div className="text-wrapper-361">
                      CRYSTAL AND&nbsp;&nbsp;AND&nbsp;&nbsp; FREQUENCY&nbsp;&nbsp;HEALING&nbsp;&nbsp;SESSIONS
                    </div>
                    <p className="text-wrapper-360">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
                  </div>
                  <div className="group-653">
                    <div className="text-wrapper-361">
                      SPECIAL&nbsp;&nbsp;GIFTS&nbsp;&nbsp;FROM&nbsp;&nbsp;OUR&nbsp;&nbsp;TOP&nbsp;&nbsp;GLOBAL&nbsp;&nbsp;SPONSORS
                    </div>
                    <p className="text-wrapper-360">GIFT BOXES FROM SPONSORS TO VIP</p>
                  </div>
                  <div className="group-654">
                    <div className="text-wrapper-360">TOP UNDERGROUND DJS</div>
                    <div className="text-wrapper-361">
                      SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE&nbsp;&nbsp;UNDERGROUND
                    </div>
                  </div>
                  <div className="group-655">
                    <p className="text-wrapper-369">PRIVATE VIP ACCESS TO SECRET AREAS</p>
                    <div className="text-wrapper-359">
                      {" "}
                      UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES&nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE
                      THE&nbsp;&nbsp;SPACE
                    </div>
                  </div>
                  <div className="group-656">
                    <div className="group-657">
                      <div className="text-wrapper-360">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
                      <div className="text-wrapper-361">
                        A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
                        THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-group-63">
                  <div className="group-658">
                    <div className="group-659">
                      <div className="group-660">
                        <div className="group-661">
                          <div className="group-662">
                            <div className="group-657">
                              <div className="text-wrapper-361">
                                RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF
                                AN&nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
                              </div>
                              <div className="text-wrapper-360">LUXURY PRIVATE YERTS</div>
                            </div>
                          </div>
                        </div>
                        <div className="group-663">
                          <div className="text-wrapper-361">
                            WITNESS A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED&nbsp;&nbsp;TALENT PERFORM
                          </div>
                          <div className="text-wrapper-360">
                            LIVE&nbsp;&nbsp;VIP&nbsp;&nbsp;TATTOO&nbsp;&nbsp;ARTISTS
                          </div>
                        </div>
                        <div className="group-664">
                          <div className="group-657">
                            <div className="text-wrapper-361">
                              A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION&nbsp;&nbsp;TAI:LORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
                            </div>
                            <div className="text-wrapper-360">LIGHTING DESIGN EXPERIENCE</div>
                          </div>
                        </div>
                        <div className="group-665">
                          <div className="group-657">
                            <div className="text-wrapper-361">
                              IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC
                              TO&nbsp;&nbsp;CREATE&nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
                            </div>
                            <p className="text-wrapper-360">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
                          </div>
                        </div>
                        <div className="group-666">
                          <p className="text-wrapper-370">PRESENTATION OF OUR PRIVATE ART GALLERY</p>
                          <div className="text-wrapper-359">
                            ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;SPECIALTY
                            NFC&nbsp;&nbsp;RINGS
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="HIGHLY-TALENTED-3">
                      HIGHLY TALENTED&nbsp;&nbsp;ARTISTS PERFORMING THROUGHOUT THE&nbsp;&nbsp;NIGHT
                    </p>
                    <div className="THE-TOP-OF-THE-8">THE TOP OF THE&nbsp;&nbsp;FASHION&nbsp;&nbsp;INDUSTRY</div>
                    <div className="text-wrapper-371">INTERNATIONAL ROYALTY</div>
                    <div className="text-wrapper-372">CELEBRITY MUSICIANS</div>
                    <div className="text-wrapper-373">EXTRAORDINARILY SUCCESSFUL BUSINESS MAGNATES</div>
                    <p className="SOME-OF-THE-MOST-7">
                      SOME&nbsp;&nbsp;OF .THE MOST INFLUENTIAL MEMBERS&nbsp;&nbsp;OF KEY COUNTRIES
                    </p>
                    <p className="text-wrapper-374">MASTERS OF MEDIA, MARKETING, SALES, AND PUBLIC RELATIONS</p>
                  </div>
                  <div className="group-667">
                    <div className="text-wrapper-360">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-635">
            <div className="overlap-83">
              <img className="vector-83" alt="Vector" src="/img/vector-233-1-3.svg" />
              <img className="vector-84" alt="Vector" src="/img/vector-244-1.svg" />
              <img className="group-636" alt="Group" src="/img/group-48098648-1-3.png" />
            </div>
            <img className="vector-85" alt="Vector" src="/img/vector-231-1.svg" />
            <img className="vector-86" alt="Vector" src="/img/vector-232-1-2.svg" />
            <img className="vector-87" alt="Vector" src="/img/vector-235-1.svg" />
            <img className="vector-88" alt="Vector" src="/img/vector-236-1-4.svg" />
          </div>
          <img className="mask-group-43" alt="Mask group" src="/img/mask-group-5.png" />
          <img className="mask-group-47" alt="Mask group" src="/img/mask-group-10.png" />
          <div className="text-wrapper-375">FULL MOON AWAKENING</div>
          <div className="SPONSOR-OUR-7">SPONSOR&nbsp;&nbsp;OUR</div>
          <div className="FABIANO-VALLI-LEVI-7">
            FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp;&nbsp;&nbsp;co
          </div>
          <div className="text-wrapper-376">July 20th</div>
          <div className="text-wrapper-377">7pm - 7am</div>
          <div className="group-668">
            <div className="text-wrapper-367">OFFERED</div>
            <div className="group-669">
              <div className="overlap-group-64">
                <div className="text-wrapper-378">SPONSORSHIP&nbsp;&nbsp;PACKAGES</div>
                <div className="rectangle-256" />
                <div className="rectangle-257" />
              </div>
            </div>
          </div>
          <div className="group-670">
            <div className="text-wrapper-379">OUR</div>
            <div className="group-671">
              <div className="overlap-group-65">
                <div className="text-wrapper-378">CHOSEN&nbsp;&nbsp;ATTENDEES</div>
                <div className="rectangle-258" />
                <div className="rectangle-259" />
              </div>
            </div>
          </div>
          <div className="text-wrapper-380">10,000€</div>
          <div className="text-wrapper-381">25,000€</div>
          <div className="text-wrapper-382">50,000€</div>
          <div className="text-wrapper-383">100,000€</div>
          <div className="text-wrapper-384">5,000€</div>
          <div className="text-wrapper-385">BRING A +5</div>
          <p className="text-wrapper-386">PRIVATE CONCIERGE FOR VIP ACCESS ANYWHERE IN MILAN</p>
          <div className="text-wrapper-387">BRING +10</div>
          <div className="ABOVE-LISTED-4">ABOVE&nbsp;&nbsp;LISTED&nbsp;&nbsp;EXCLUSIVE&nbsp;&nbsp;SERVICES</div>
          <div className="PROFESSIONAL-4">
            PROFESSIONAL ADVERTISEMENT&nbsp;&nbsp;PRODUCTION&nbsp;&nbsp;
            PROMOTED&nbsp;&nbsp;BY&nbsp;&nbsp;OUR&nbsp;&nbsp;PR&nbsp;&nbsp;&amp;&nbsp;&nbsp;INFLUENCERS
          </div>
          <div className="ONGOING-BEST-IN-4">
            ONGOING&nbsp;&nbsp;BEST&nbsp;&nbsp;IN&nbsp;&nbsp;CLASS&nbsp;&nbsp;MARKETING&nbsp;&nbsp;SERVICES&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY
            OUR&nbsp;&nbsp;COMPANY
          </div>
          <p className="text-wrapper-388">EQUITY SHARE IN OUR MULTI-CONGLOMERATE INTERNATIONAL AGENCY AGGREGATE</p>
          <p className="a-UNIQUE-5">
            A UNIQUE IDENTIFICATION GRANTING
            INDEFINITE&nbsp;&nbsp;VIP&nbsp;&nbsp;SPONSORSHIP&nbsp;&nbsp;STATUS&nbsp;&nbsp;WITHIN&nbsp;&nbsp;OUR
            <br />
            ORGANIZATION, INCLUDING VIP STATUS AT ALL&nbsp;&nbsp;OPEN EVENTS, &amp; ACCESS TO SOME FULLY
            PRIVATE&nbsp;&nbsp;UNADVERTISED&nbsp;&nbsp;EVENTS,,&nbsp;&nbsp;CONTINUOUS
            INTRODUCTIONS&nbsp;&nbsp;THROUGH&nbsp;&nbsp;NETWORK
          </p>
          <div className="item-274">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-142" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-275">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-143" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-276">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-144" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-277">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-145" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-278">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-146" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-279">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-147" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-280">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-148" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-281">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-149" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-282">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-150" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-283">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-151" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-284">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-152" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-285">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-153" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-286">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-154" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-287">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-288">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-289">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-290">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-291">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-292">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-293">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-294">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-295">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-296">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-297">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-298">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-299">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-156" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-300">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-157" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-301">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-302">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-303">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-304">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-158" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-305">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-306">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-307">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-308">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-309">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-159" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-310">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-311">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-312">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-313">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-314">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-160" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-315">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-161" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-316">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-317">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-318">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="ENGRAVINGS-ON-ALL-OF-4">ENGRAVINGS ON ALL OF OUR&nbsp;&nbsp;BAR&nbsp;&nbsp;GLASSES</p>
          <div className="DISTRIBUTION-OF-4">
            DISTRIBUTION&nbsp;&nbsp;OF&nbsp;&nbsp;PRODUCT&nbsp;&nbsp;SAMPLES&nbsp;&nbsp;VIA&nbsp;&nbsp;OUR&nbsp;&nbsp;VIP&nbsp;&nbsp;GIFT&nbsp;&nbsp;BOXES
          </div>
          <div className="item-319">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-320">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-321">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-322">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-323">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-324">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-325">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-326">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-327">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-328">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-329">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-330">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-331">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-332">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-333">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="CONTINUOUS-4">
            CONTINUOUS ADVERTISEMENT ON HIGHLY VISIBLE&nbsp;&nbsp;LIFE&nbsp;&nbsp;SIZE&nbsp;&nbsp;NFT&nbsp;&nbsp;PANELS
          </p>
          <div className="text-wrapper-389">BRING A +1</div>
          <div className="item-334">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-6">
                  <div className="icon-6">
                    <img className="color-162" alt="Color" src="/img/milan-italy.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-335">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-336">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-337">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-338">
            <div className="radio-wrapper-4">
              <div className="controller-7">
                <div className="thumb-7">
                  <div className="icon-7">
                    <img className="color-155" alt="Color" src="/img/color-136.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="PRIVATE-PRESENTATION-5">
            PRIVATE&nbsp;&nbsp;PRESENTATION&nbsp;&nbsp;SLOT&nbsp;&nbsp;IN&nbsp;&nbsp;ENCLOSED&nbsp;&nbsp;AREA&nbsp;&nbsp;ADVERTISED&nbsp;&nbsp;TO&nbsp;&nbsp;ATTENDEES
          </div>
        </div>
        <div className="group-672">
          <div className="group-673">
            <div className="group-674">
              <div className="PRESENTED-BY-16">PRESENTED&nbsp;&nbsp;BY</div>
            </div>
          </div>
        </div>
        <img className="group-675" alt="Group" src="/img/group-48098605.png" />
      </div>
    </div>
  );
};
