import React from "react";
import { SocialIcons } from "../../components/SocialIcons";
import { PlatformWhatsappColorOriginal1 } from "../../icons/PlatformWhatsappColorOriginal1";
import { PlatformXTwitterColorNegative } from "../../icons/PlatformXTwitterColorNegative";
import { SocialIcons4 } from "../../icons/SocialIcons4";
import "./style.css";

export const VipMobileScreen = () => {
  return (
    <div className="VIP-MOBILE-screen">
      <div className="VIP-MOBILE-2">
        <div className="group-676">
          <div className="text-wrapper-390">AWAKENING</div>
          <div className="FULL-MOON-5">FULL&nbsp;&nbsp;MOON</div>
        </div>
        <div className="group-677">
          <div className="overlap-group-66">
            <img className="vector-89" alt="Vector" src="/img/vector-233-2.svg" />
            <img className="vector-90" alt="Vector" src="/img/vector-244-4.svg" />
            <img className="group-678" alt="Group" src="/img/group-48098648-4.png" />
          </div>
          <img className="vector-91" alt="Vector" src="/img/vector-231-2.svg" />
          <img className="vector-92" alt="Vector" src="/img/vector-232-5.svg" />
          <img className="vector-93" alt="Vector" src="/img/image.svg" />
          <img className="vector-94" alt="Vector" src="/img/vector-236-5.svg" />
        </div>
        <div className="text-wrapper-391">July 20th</div>
        <div className="text-wrapper-392">7pm - 7am</div>
        <a
          className="group-679"
          href="https://api.whatsapp.com/send/?phone=19548823115"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="overlap-84">
            <div className="rectangle-260" />
            <div className="text-wrapper-393">Get Access</div>
          </div>
        </a>
        <div className="group-680">
          <div className="FABIANO-PISETZKY-4">FABIANO&nbsp;&nbsp;PISETZKY</div>
          <div className="co-7">&amp;&nbsp;&nbsp;co</div>
          <div className="text-wrapper-394">CORDIALLY INVITED BY</div>
          <div className="overlap-85">
            <div className="rectangle-261" />
          </div>
        </div>
        <div className="text-wrapper-395">SERVICES</div>
        <div className="text-wrapper-396">EXCLUSIVE</div>
        <div className="group-681">
          <p className="text-wrapper-397">
            CONNECTION TO THE <br />
            TOP HOTELS OF THE AREA
          </p>
          <p className="text-wrapper-398">
            EXCLUSIVE&nbsp;&nbsp;PARTNERSHIPS&nbsp;&nbsp;WITH
            <br />
            THE FINEST HOTELS IN THE&nbsp;&nbsp;REGION
          </p>
        </div>
        <div className="group-682">
          <div className="text-wrapper-397">
            PRIVATE TRANSPORTATION <br />
            AND RESERVATIONS
          </div>
          <div className="text-wrapper-398">
            SPECIAL&nbsp;&nbsp;BOOKING&nbsp;&nbsp;RATES&nbsp;&nbsp;FOR
            <br />
            &nbsp;&nbsp;LUXURY TRANSPORT SERVICES
          </div>
        </div>
        <div className="group-683">
          <p className="text-wrapper-399">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
          <p className="text-wrapper-398">
            HELICOPTER ARRIVALS ARE
            <br /> AVAILABLE UPON REQUEST
          </p>
        </div>
        <div className="group-684">
          <div className="text-wrapper-397">PROFESSIONALLY MANAGED PARKING SERVICE</div>
          <p className="text-wrapper-398">
            COMPLIMENTARY VALET SERVICE
            <br /> AT THE VENUE
          </p>
        </div>
        <div className="rectangle-262" />
        <img className="mask-group-48" alt="Mask group" src="/img/mask-group-11.png" />
        <a
          className="group-685"
          href="https://api.whatsapp.com/send/?phone=19548823115"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="overlap-84">
            <div className="rectangle-260" />
            <div className="text-wrapper-393">Get Access</div>
          </div>
        </a>
        <img className="mask-group-49" alt="Mask group" src="/img/mask-group-11.png" />
        <div className="overlap-86">
          <div className="group-686">
            <div className="group-687">
              <div className="rectangle-263" />
              <div className="rectangle-264" />
              <img
                className="AAA-logo-test-17"
                alt="Aaa logo test"
                src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
              />
            </div>
          </div>
          <img
            className="AAA-logo-test-18"
            alt="Aaa logo test"
            src="/img/aaa-logo-test-4-removebg-preview-1-2-2x.png"
          />
        </div>
        <div className="group-688">
          <div className="flexcontainer-12">
            <p className="text-11">
              <span className="text-wrapper-400">
                EXPERIENCE&nbsp;&nbsp;
                <br />
              </span>
            </p>
            <p className="text-11">
              <span className="text-wrapper-400">THE&nbsp;&nbsp; UNTOUCHABLE</span>
            </p>
          </div>
          <div className="group-689">
            <div className="text-wrapper-397">EXCLUSIVE LOCATION</div>
            <div className="text-wrapper-401">
              AN EXPERTLY ARCHITECTED
              <br />
              &nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A
              <br />
              LUXURY&nbsp;&nbsp;VILLA
            </div>
          </div>
          <div className="group-690">
            <div className="text-wrapper-397">SKL SS 2024 CATWALK</div>
            <div className="text-wrapper-401">
              FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM
              <br />
              &nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp; THE&nbsp;&nbsp;US
            </div>
          </div>
          <div className="group-691">
            <div className="text-wrapper-397">TOP UNDERGROUND DJS</div>
            <div className="text-wrapper-401">
              SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE
              <br />
              &nbsp;&nbsp;UNDERGROUND
            </div>
          </div>
          <div className="group-692">
            <p className="text-wrapper-397">
              PRIVATE VIP ACCESS <br />
              TO SECRET AREAS
            </p>
            <div className="text-wrapper-398">
              UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES
              <br />
              &nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE THE&nbsp;&nbsp;SPACE
            </div>
          </div>
          <div className="group-693">
            <div className="text-wrapper-397">PROPER FESTIVAL STAGE</div>
            <div className="text-wrapper-401">
              A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
              <br /> THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
            </div>
          </div>
          <div className="group-694">
            <div className="text-wrapper-397">LUXURY PRIVATE YERTS</div>
            <div className="text-wrapper-401">
              RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF AN&nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
            </div>
          </div>
          <div className="group-695">
            <div className="text-wrapper-397">LIVE VIP TATTOO ARTISTS</div>
            <div className="text-wrapper-401">
              WITNESS&nbsp;&nbsp;A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED
              <br />
              &nbsp;&nbsp;TALENT PERFORM
            </div>
          </div>
          <div className="group-696">
            <div className="text-wrapper-397">LIGHTING DESIGN EXPERIENCE</div>
            <div className="text-wrapper-401">
              A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION
              <br />
              &nbsp;&nbsp;TAILORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
            </div>
          </div>
          <div className="group-697">
            <p className="text-wrapper-397">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
            <div className="text-wrapper-398">
              IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC TO&nbsp;&nbsp;CREATE
              <br />
              &nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
            </div>
          </div>
          <div className="group-698">
            <p className="text-wrapper-397">
              PRESENTATION OF OUR <br />
              PRIVATE ART GALLERY
            </p>
            <div className="text-wrapper-398">
              ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED
              <br />
              &nbsp;&nbsp;SPECIALTY NFC&nbsp;&nbsp;RINGS
            </div>
          </div>
          <div className="group-699">
            <div className="text-wrapper-397">LIVE ART PRODUCTIONS</div>
            <p className="text-wrapper-401">
              HIGHLY TALENTED&nbsp;&nbsp;ARTISTS PERFORMING THROUGHOUT THE&nbsp;&nbsp;NIGHT
            </p>
          </div>
          <div className="group-700">
            <div className="overlap-group-67">
              <p className="text-wrapper-397">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
              <div className="CRYSTAL-AND-AND-5">
                CRYSTAL AND&nbsp;&nbsp;AND&nbsp;&nbsp; FREQUENCY&nbsp;&nbsp;HEALING&nbsp;&nbsp;SESSIONS
              </div>
            </div>
            <div className="text-wrapper-398">
              CRYSTAL AND FREQUENCY
              <br />
              HEALING SESSIONS
            </div>
          </div>
          <div className="group-701">
            <p className="text-wrapper-397">
              GIFT BOXES <br />
              FROM SPONSORS TO VIP
            </p>
            <div className="text-wrapper-398">
              SPECIAL GIFTS&nbsp;&nbsp;FROM
              <br />
              OUR&nbsp;&nbsp;TOP&nbsp;&nbsp;GLOBAL&nbsp;&nbsp;SPONSORS
            </div>
          </div>
          <div className="text-wrapper-402">EVENT SPECIALS</div>
          <div className="rectangle-265" />
          <div className="rectangle-266" />
          <div className="rectangle-267" />
        </div>
        <div className="rectangle-268" />
        <div className="text-wrapper-403">Secret</div>
        <img className="location-8" alt="Location" src="/img/location-2.svg" />
        <p className="text-wrapper-404">Let us know before 12th of July</p>
        <div className="text-wrapper-405">For more Info, contact</div>
        <div className="f-sokail-ai-j-sokail-15">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
        <img className="group-702" alt="Group" src="/img/group-48098605-2.png" />
        <div className="PRESENTED-BY-17">PRESENTED&nbsp;&nbsp;BY</div>
        <SocialIcons color="negative" platform="telegram" />
        <PlatformXTwitterColorNegative className="platform-x-twitter-color-negative" color="white" />
        <SocialIcons color="negative" platform="instagram" />
        <PlatformWhatsappColorOriginal1 className="platform-whatsapp-color-negative-instance" color="white" />
        <SocialIcons4 className="icon-instance-node" />
      </div>
    </div>
  );
};
