import React from "react";
import "./style.css";

export const Screen13 = () => {
  return (
    <div className="screen-13">
      <div className="SPONSOR-INVITATION-4">
        <div className="overlap-73">
          <div className="overlap-74">
            <div className="group-586">
              <div className="rectangle-231" />
            </div>
            <div className="text-wrapper-320">BRING A +1</div>
            <p className="MASTERS-OF-MEDIA-4">
              MASTERS OF MEDIA, MARKETING
              <br />, SALES, AND PUBLIC RELATIONS
            </p>
            <div className="group-587">
              <div className="group-588">
                <div className="text-wrapper-321">EXCLUSIVE</div>
                <div className="text-wrapper-322">SERVICES</div>
                <div className="text-wrapper-323">FOR OUR TOP SPONSORS</div>
              </div>
            </div>
            <div className="group-589">
              <div className="group-590">
                <p className="text-wrapper-324">COMPLIMENTARY BOOKINGS AT THE TOP HOTELS OF THE AREA</p>
                <p className="EXCLUSIVE-ACCESS-AND-6">
                  EXCLUSIVE&nbsp;&nbsp;ACCESS AND
                  <br /> VIP TREATMENT IN&nbsp;&nbsp;MILAN’S
                  <br />
                  &nbsp;&nbsp;FINEST
                </p>
              </div>
              <div className="group-591">
                <div className="text-wrapper-325">PRIVATE TRANSPORTATION ARRANGEMENTS</div>
                <p className="COMPLIMENTARY-LUXURY-5">
                  {" "}
                  COMPLIMENTARY&nbsp;&nbsp;LUXURY
                  <br />
                  &nbsp;&nbsp;TRANSPORT SERVICES
                  <br />
                  &nbsp;&nbsp;BEGINNING AT THE AIRPORT
                </p>
              </div>
              <div className="group-592">
                <p className="text-wrapper-326">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
                <p className="HELICOPTER-ARRIVALS-3">
                  HELICOPTER ARRIVALS ARE
                  <br /> AVAILABLE UPON REQUEST
                </p>
              </div>
            </div>
          </div>
          <div className="text-wrapper-327">5,000€</div>
        </div>
        <div className="group-593">
          <div className="overlap-group-59">
            <div className="overlap-75">
              <img className="vector-77" alt="Vector" src="/img/milan-italy.png" />
              <img className="vector-78" alt="Vector" src="/img/milan-italy.png" />
              <img className="group-594" alt="Group" src="/img/milan-italy.png" />
            </div>
            <img className="vector-79" alt="Vector" src="/img/milan-italy.png" />
            <img className="vector-80" alt="Vector" src="/img/milan-italy.png" />
            <img className="vector-81" alt="Vector" src="/img/milan-italy.png" />
            <img className="vector-82" alt="Vector" src="/img/milan-italy.png" />
          </div>
        </div>
        <img className="mask-group-38" alt="Mask group" src="/img/mask-group-2x.png" />
        <img className="mask-group-39" alt="Mask group" src="/img/mask-group-2x.png" />
        <img className="mask-group-40" alt="Mask group" src="/img/mask-group-2x.png" />
        <img className="mask-group-41" alt="Mask group" src="/img/mask-group-2x.png" />
        <div className="overlap-76">
          <div className="group-595">
            <div className="group-596">
              <div className="group-597">
                <div className="PRESENTED-BY-14">PRESENTED&nbsp;&nbsp;BY</div>
              </div>
            </div>
          </div>
          <div className="group-598">
            <div className="overlap-77">
              <div className="rectangle-232" />
              <div className="text-wrapper-328">BECOME A SPONSOR</div>
            </div>
            <div className="text-wrapper-329">Enter your email here</div>
          </div>
          <img className="mask-group-42" alt="Mask group" src="/img/mask-group-4-2.png" />
          <div className="rectangle-233" />
        </div>
        <div className="group-599">
          <div className="group-600">
            <div className="overlap-group-60">
              <div className="CHOSEN-ATTENDEES-4">CHOSEN&nbsp;&nbsp;ATTENDEES</div>
              <div className="rectangle-234" />
            </div>
            <img className="rectangle-235" alt="Rectangle" src="/img/milan-italy.png" />
          </div>
        </div>
        <div className="text-wrapper-330">100,000€</div>
        <div className="overlap-78">
          <div className="text-wrapper-331">BRING A +5</div>
          <div className="text-wrapper-332">10,000€</div>
          <div className="text-wrapper-333">25,000€</div>
          <div className="text-wrapper-334">50,000€</div>
          <div className="text-wrapper-335">100,000€</div>
          <div className="text-wrapper-336">5,000€</div>
          <p className="CONTINUOUS-3">
            CONTINUOUS ADVERTISEMENT ON HIGHLY VISIBLE&nbsp;&nbsp;LIFE&nbsp;&nbsp;SIZE&nbsp;&nbsp;NFT&nbsp;&nbsp;PANELS
          </p>
        </div>
        <div className="overlap-79">
          <div className="text-wrapper-337">BRING +10</div>
          <div className="item-170">
            <div className="radio-wrapper-3">
              <div className="controller-4">
                <div className="thumb-4">
                  <div className="icon-4">
                    <img className="color-82" alt="Color" src="/img/color-72.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-171">
            <div className="radio-wrapper-3">
              <div className="controller-4">
                <div className="thumb-4">
                  <div className="icon-4">
                    <img className="color-82" alt="Color" src="/img/color-72.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-172">
            <div className="radio-wrapper-3">
              <div className="controller-5">
                <div className="thumb-4">
                  <div className="icon-4">
                    <img className="color-82" alt="Color" src="/img/color-71.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-173">
            <div className="radio-wrapper-3">
              <div className="controller-5">
                <div className="thumb-4">
                  <div className="icon-4">
                    <img className="color-82" alt="Color" src="/img/color-71.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-174">
            <div className="radio-wrapper-3">
              <div className="controller-4">
                <div className="thumb-4">
                  <div className="icon-4">
                    <img className="color-82" alt="Color" src="/img/color-77.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-175">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-83" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-176">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-84" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-177">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-85" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-178">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-86" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-179">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-87" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-180">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-88" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-181">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-89" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-182">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-90" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-183">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-91" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-184">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-92" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-185">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-93" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-186">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-94" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-187">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-95" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-188">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-96" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-189">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-97" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-190">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-98" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-191">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-99" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-192">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-100" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-193">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-101" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-194">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-102" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-195">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-103" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-196">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-104" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-197">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-105" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-198">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-106" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-199">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-107" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-200">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-108" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-201">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-109" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-202">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-110" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-203">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-111" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-204">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-112" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-205">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-113" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-206">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-114" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-207">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-115" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-208">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-116" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-209">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-117" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-210">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-118" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-211">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-119" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-212">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-120" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-213">
          <div className="radio-wrapper-3">
            <div className="controller-6">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-121" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-338">EVENT SPECIALS</div>
        <div className="rectangle-236" />
        <div className="text-wrapper-339">Basiglio,</div>
        <img className="milan-italy-3" alt="Milan italy" src="/img/milan-italy.png" />
        <div className="text-wrapper-340">For more Info, contact</div>
        <div className="f-sokail-ai-j-sokail-13">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
        <img className="group-601" alt="Group" src="/img/milan-italy.png" />
        <div className="PRESENTED-BY-15">PRESENTED&nbsp;&nbsp;BY</div>
        <div className="THE-TOP-OF-THE-7">
          THE TOP OF THE&nbsp;&nbsp;FASHION
          <br />
          &nbsp;&nbsp;INDUSTRY
        </div>
        <div className="text-wrapper-341">INTERNATIONAL ROYALTY</div>
        <div className="text-wrapper-342">CELEBRITY MUSICIANS</div>
        <div className="EXTRAORDINARILY-4">
          EXTRAORDINARILY SUCCESSFUL
          <br /> BUSINESS MAGNATES
        </div>
        <p className="SOME-OF-THE-MOST-6">
          SOME&nbsp;&nbsp;OF .THE MOST INFLUENTIAL
          <br /> MEMBERS&nbsp;&nbsp;OF KEY COUNTRIES
        </p>
        <div className="text-wrapper-343">BRING A +5</div>
        <p className="text-wrapper-344">PRIVATE CONCIERGE FOR VIP ACCESS ANYWHERE IN MILAN</p>
        <div className="text-wrapper-345">BRING +10</div>
        <div className="ABOVE-LISTED-3">ABOVE&nbsp;&nbsp;LISTED&nbsp;&nbsp;EXCLUSIVE&nbsp;&nbsp;SERVICES</div>
        <div className="PROFESSIONAL-3">
          PROFESSIONAL ADVERTISEMENT&nbsp;&nbsp;PRODUCTION&nbsp;&nbsp;
          PROMOTED&nbsp;&nbsp;BY&nbsp;&nbsp;OUR&nbsp;&nbsp;PR&nbsp;&nbsp;&amp;&nbsp;&nbsp;INFLUENCERS
        </div>
        <div className="ONGOING-BEST-IN-3">
          ONGOING&nbsp;&nbsp;BEST&nbsp;&nbsp;IN&nbsp;&nbsp;CLASS&nbsp;&nbsp;MARKETING&nbsp;&nbsp;SERVICES&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY
          OUR&nbsp;&nbsp;COMPANY
        </div>
        <p className="text-wrapper-346">EQUITY SHARE IN OUR MULTI-CONGLOMERATE INTERNATIONAL AGENCY AGGREGATE</p>
        <p className="a-UNIQUE-4">
          A UNIQUE IDENTIFICATION GRANTING
          INDEFINITE&nbsp;&nbsp;VIP&nbsp;&nbsp;SPONSORSHIP&nbsp;&nbsp;STATUS&nbsp;&nbsp;WITHIN&nbsp;&nbsp;OUR
          ORGANIZATION,
          <br />
          INCLUDING VIP STATUS AT ALL&nbsp;&nbsp;OPEN EVENTS, &amp; ACCESS TO SOME FULLY
          PRIVATE&nbsp;&nbsp;UNADVERTISED&nbsp;&nbsp;EVENTS,&nbsp;&nbsp;CONTINUOUS
          INTRODUCTIONS&nbsp;&nbsp;THROUGH&nbsp;&nbsp;NETWORK
        </p>
        <div className="item-214">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-122" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-215">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-123" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-216">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-124" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-217">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-125" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-218">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-126" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-219">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-127" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-220">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-128" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-221">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-129" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-222">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-130" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-223">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-131" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-224">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-132" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-225">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-133" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-226">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-134" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-227">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-228">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-229">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-230">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-231">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-232">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-233">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-234">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-235">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-236">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-237">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-238">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-82.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-239">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-135" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-240">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-136" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-241">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-242">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-243">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-244">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-137" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-245">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-246">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-247">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-248">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-249">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-138" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-250">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-251">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-252">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-253">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-254">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-139" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-255">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-140" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-256">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-257">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-258">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="ENGRAVINGS-ON-ALL-OF-3">ENGRAVINGS ON ALL OF OUR&nbsp;&nbsp;BAR&nbsp;&nbsp;GLASSES</p>
        <div className="DISTRIBUTION-OF-3">
          DISTRIBUTION&nbsp;&nbsp;OF&nbsp;&nbsp;PRODUCT&nbsp;&nbsp;SAMPLES&nbsp;&nbsp;VIA&nbsp;&nbsp;OUR&nbsp;&nbsp;VIP&nbsp;&nbsp;GIFT&nbsp;&nbsp;BOXES
        </div>
        <div className="item-259">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-260">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-261">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-262">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-263">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-264">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-265">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-266">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-267">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-268">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-347">BRING A +1</div>
        <div className="item-269">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-5">
                <div className="icon-5">
                  <img className="color-141" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-270">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-271">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-272">
          <div className="radio-wrapper-3">
            <div className="controller-4">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-273">
          <div className="radio-wrapper-3">
            <div className="controller-5">
              <div className="thumb-4">
                <div className="icon-4">
                  <img className="color-82" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="PRIVATE-PRESENTATION-4">
          PRIVATE&nbsp;&nbsp;PRESENTATION&nbsp;&nbsp;SLOT&nbsp;&nbsp;IN&nbsp;&nbsp;ENCLOSED&nbsp;&nbsp;AREA&nbsp;&nbsp;ADVERTISED&nbsp;&nbsp;TO&nbsp;&nbsp;ATTENDEES
        </div>
        <div className="group-602">
          <div className="text-wrapper-348">OFFERED</div>
          <div className="group-603">
            <div className="overlap-group-61">
              <div className="SPONSORSHIP-PACKAGES-5">
                SPONSORSHIP&nbsp;&nbsp;
                <br />
                PACKAGES
              </div>
              <div className="rectangle-237" />
              <div className="rectangle-238" />
            </div>
          </div>
        </div>
        <div className="flexcontainer-11">
          <p className="text-10">
            <span className="text-wrapper-349">
              EXPERIENCE&nbsp;&nbsp;
              <br />
            </span>
          </p>
          <p className="text-10">
            <span className="text-wrapper-349">THE&nbsp;&nbsp; UNTOUCHABLE</span>
          </p>
        </div>
        <div className="group-604">
          <div className="text-wrapper-350">EXCLUSIVE LOCATION</div>
          <div className="text-wrapper-351">
            AN EXPERTLY ARCHITECTED
            <br />
            &nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A
            <br />
            &nbsp;&nbsp;LUXURY&nbsp;&nbsp;VILLA
          </div>
        </div>
        <div className="group-605">
          <div className="text-wrapper-350">SKL SS 2024 CATWALK</div>
          <div className="text-wrapper-351">
            FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM
            <br />
            &nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp; THE&nbsp;&nbsp;US
          </div>
        </div>
        <div className="group-606">
          <div className="text-wrapper-350">TOP UNDERGROUND DJS</div>
          <div className="text-wrapper-351">
            SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE
            <br />
            &nbsp;&nbsp;UNDERGROUND
          </div>
        </div>
        <div className="group-607">
          <p className="text-wrapper-350">
            PRIVATE VIP ACCESS <br />
            TO SECRET AREAS
          </p>
          <div className="text-wrapper-352">
            UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES
            <br />
            &nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE THE&nbsp;&nbsp;SPACE
          </div>
        </div>
        <div className="group-608">
          <div className="text-wrapper-350">PROPER FESTIVAL STAGE</div>
          <div className="text-wrapper-351">
            A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
            <br /> THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
          </div>
        </div>
        <div className="group-609">
          <div className="text-wrapper-350">LUXURY PRIVATE YERTS</div>
          <div className="text-wrapper-351">
            RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF AN
            <br />
            &nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
          </div>
        </div>
        <div className="group-610">
          <div className="text-wrapper-350">LIVE VIP TATTOO ARTISTS</div>
          <div className="text-wrapper-351">
            WITNESS&nbsp;&nbsp;A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED
            <br />
            &nbsp;&nbsp;TALENT PERFORM
          </div>
        </div>
        <div className="group-611">
          <div className="text-wrapper-350">LIGHTING DESIGN EXPERIENCE</div>
          <div className="text-wrapper-351">
            A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION
            <br />
            &nbsp;&nbsp;TAILORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
          </div>
        </div>
        <div className="group-612">
          <p className="text-wrapper-350">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
          <div className="text-wrapper-352">
            IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC TO&nbsp;&nbsp;CREATE
            <br />
            &nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
          </div>
        </div>
        <div className="group-613">
          <p className="text-wrapper-350">
            PRESENTATION OF OUR <br />
            PRIVATE ART GALLERY
          </p>
          <div className="text-wrapper-352">
            ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED
            <br />
            &nbsp;&nbsp;SPECIALTY NFC&nbsp;&nbsp;RINGS
          </div>
        </div>
        <div className="group-614">
          <div className="text-wrapper-350">LIVE ART PRODUCTIONS</div>
          <p className="text-wrapper-351">
            HIGHLY TALENTED&nbsp;&nbsp;ARTISTS
            <br /> PERFORMING THROUGHOUT THE
            <br />
            &nbsp;&nbsp;NIGHT
          </p>
        </div>
        <div className="overlap-80">
          <div className="group-615">
            <div className="CRYSTAL-AND-AND-4">
              CRYSTAL AND&nbsp;&nbsp;AND&nbsp;&nbsp; FREQUENCY&nbsp;&nbsp;HEALING&nbsp;&nbsp;SESSIONS
            </div>
          </div>
          <p className="text-wrapper-353">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
          <div className="CRYSTAL-AND-4">
            CRYSTAL AND FREQUENCY
            <br />
            HEALING SESSIONS
          </div>
        </div>
        <div className="group-616">
          <p className="text-wrapper-350">
            GIFT BOXES <br />
            FROM SPONSORS TO VIP
          </p>
          <div className="text-wrapper-352">
            SPECIAL GIFTS&nbsp;&nbsp;FROM
            <br />
            OUR .TOP&nbsp;&nbsp;GLOBAL .SPONSORS
          </div>
        </div>
        <div className="rectangle-239" />
        <div className="rectangle-240" />
      </div>
    </div>
  );
};
