import React from "react";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { SocialIcons } from "../../components/SocialIcons";
import "./style.css";

export const SponsorInvitationScreen = () => {
  return (
    <div className="SPONSOR-INVITATION-screen">
      <div className="SPONSOR-INVITATION-2">
        <div className="overlap-46">
          <div className="group-429">
            <div className="group-430">
              <div className="group-431">
                <div className="group-432">
                  <div className="overlap-47">
                    <img className="image-9" alt="Image" src="/img/image-360.png" />
                    <div className="rectangle-163" />
                    <div className="rectangle-164" />
                    <div className="group-433">
                      <div className="overlap-group-44">
                        <img className="image-9" alt="Image" src="/img/image-361-2.png" />
                        <div className="rectangle-165" />
                        <div className="rectangle-166" />
                        <div className="rectangle-167" />
                      </div>
                    </div>
                    <div className="rectangle-168" />
                    <div className="rectangle-169" />
                    <div className="rectangle-170" />
                    <div className="rectangle-171" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="mask-group-26" alt="Mask group" src="/img/mask-group-3.png" />
          <img className="mask-group-27" alt="Mask group" src="/img/mask-group-7.png" />
          <div className="text-wrapper-228">FULL MOON AWAKENING</div>
          <div className="SPONSOR-OUR-5">SPONSOR&nbsp;&nbsp;OUR</div>
          <div className="text-wrapper-229">July 20th</div>
          <div className="group-434">
            <div className="overlap-48">
              <div className="group-435">
                <div className="group-436">
                  <div className="group-437">
                    <div className="group-438">
                      <div className="rectangle-172" />
                      <div className="rectangle-173" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-230">7pm - 7am</div>
            </div>
          </div>
          <div className="group-439">
            <div className="overlap-49">
              <img className="vector-52" alt="Vector" src="/img/vector-233-1-4.svg" />
              <img className="vector-53" alt="Vector" src="/img/vector-244-1-4.svg" />
              <img className="group-440" alt="Group" src="/img/group-48098648-1-2x.png" />
            </div>
            <img className="vector-54" alt="Vector" src="/img/vector-231-1-3.svg" />
            <img className="vector-55" alt="Vector" src="/img/vector-232-1-3.svg" />
            <img className="vector-56" alt="Vector" src="/img/vector-235-1-3.svg" />
            <img className="vector-57" alt="Vector" src="/img/vector-236-1-3.svg" />
          </div>
        </div>
        <div className="overlap-50">
          <img className="mask-group-28" alt="Mask group" src="/img/mask-group-6.png" />
          <div className="group-441">
            <div className="text-wrapper-231">EXCLUSIVE LOCATION</div>
            <div className="group-442">
              <div className="overlap-group-45">
                <div className="group-443">
                  <div className="rectangle-174" />
                  <div className="rectangle-175" />
                </div>
                <div className="AN-EXPERTLY-3">
                  AN EXPERTLY
                  ARCHITECTED&nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A&nbsp;&nbsp;LUXURY&nbsp;&nbsp;VILLA
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="mask-group-29" alt="Mask group" src="/img/mask-group-3-2x.png" />
        <img className="mask-group-30" alt="Mask group" src="/img/mask-group-3-2x.png" />
        <div className="group-444">
          <div className="group-445">
            <div className="text-wrapper-232">EXCLUSIVE</div>
            <div className="text-wrapper-233">SERVICES</div>
            <div className="text-wrapper-234">FOR OUR TOP SPONSORS</div>
          </div>
        </div>
        <div className="group-446">
          <div className="group-447">
            <div className="group-448">
              <p className="text-wrapper-235">COMPLIMENTARY BOOKINGS AT THE TOP HOTELS OF THE AREA</p>
              <p className="EXCLUSIVE-ACCESS-AND-4">
                EXCLUSIVE&nbsp;&nbsp;ACCESS AND VIP TREATMENT IN&nbsp;&nbsp;MILAN’S&nbsp;&nbsp;FINEST
              </p>
            </div>
            <div className="group-449">
              <div className="text-wrapper-236">PRIVATE TRANSPORTATION ARRANGEMENTS</div>
              <p className="COMPLIMENTARY-LUXURY-3">
                {" "}
                COMPLIMENTARY&nbsp;&nbsp;LUXURY&nbsp;&nbsp;TRANSPORT SERVICES&nbsp;&nbsp;BEGINNING AT THE AIRPORT
              </p>
            </div>
            <div className="group-450">
              <p className="text-wrapper-237">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
              <p className="text-wrapper-238">HELICOPTER ARRIVALS ARE AVAILABLE UPON REQUEST</p>
            </div>
          </div>
        </div>
        <div className="group-451">
          <div className="rectangle-176" />
          <div className="CORDIALLY-INVITED-BY-3">CORDIALLY INVITED&nbsp;&nbsp;BY</div>
          <div className="FABIANO-VALLI-LEVI-4">
            FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp;&nbsp;&nbsp;co
          </div>
        </div>
        <div className="group-452">
          <div className="text-wrapper-239">EVENT SPECIALS</div>
          <div className="EXPERIENCE-THE-4">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
        </div>
        <div className="group-453">
          <div className="group-454">
            <div className="text-wrapper-240">SKL SS 2024 CATWALK</div>
            <div className="text-wrapper-241">
              FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM&nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp;
              THE&nbsp;&nbsp;US
            </div>
          </div>
          <div className="group-455">
            <div className="text-wrapper-241">
              CRYSTAL AND&nbsp;&nbsp;AND&nbsp;&nbsp; FREQUENCY&nbsp;&nbsp;HEALING&nbsp;&nbsp;SESSIONS
            </div>
            <p className="text-wrapper-240">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
          </div>
          <div className="group-456">
            <div className="text-wrapper-241">
              SPECIAL&nbsp;&nbsp;GIFTS&nbsp;&nbsp;FROM&nbsp;&nbsp;OUR&nbsp;&nbsp;TOP&nbsp;&nbsp;GLOBAL&nbsp;&nbsp;SPONSORS
            </div>
            <p className="text-wrapper-240">GIFT BOXES FROM SPONSORS TO VIP</p>
          </div>
          <div className="group-457">
            <div className="text-wrapper-240">TOP UNDERGROUND DJS</div>
            <div className="text-wrapper-241">
              SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE&nbsp;&nbsp;UNDERGROUND
            </div>
          </div>
          <div className="group-458">
            <p className="text-wrapper-240">PRIVATE VIP ACCESS TO SECRET AREAS</p>
            <div className="text-wrapper-241">
              {" "}
              UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES&nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE
              THE&nbsp;&nbsp;SPACE
            </div>
          </div>
          <div className="group-459">
            <div className="text-wrapper-240">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
            <div className="text-wrapper-241">
              A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
              THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
            </div>
          </div>
          <div className="group-460">
            <div className="text-wrapper-241">
              RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF AN&nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
            </div>
            <div className="text-wrapper-240">LUXURY PRIVATE YERTS</div>
          </div>
          <div className="group-461">
            <div className="text-wrapper-241">
              WITNESS A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED&nbsp;&nbsp;TALENT PERFORM
            </div>
            <div className="text-wrapper-240">LIVE&nbsp;&nbsp;VIP&nbsp;&nbsp;TATTOO&nbsp;&nbsp;ARTISTS</div>
          </div>
          <div className="group-462">
            <div className="text-wrapper-241">
              A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION&nbsp;&nbsp;TAI:LORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
            </div>
            <div className="text-wrapper-240">LIGHTING DESIGN EXPERIENCE</div>
          </div>
          <div className="group-463">
            <div className="text-wrapper-241">
              IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC
              TO&nbsp;&nbsp;CREATE&nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
            </div>
            <p className="text-wrapper-240">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
          </div>
          <div className="group-464">
            <p className="text-wrapper-240">PRESENTATION OF OUR PRIVATE ART GALLERY</p>
            <div className="text-wrapper-241">
              ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;SPECIALTY NFC&nbsp;&nbsp;RINGS
            </div>
          </div>
          <div className="group-465">
            <p className="text-wrapper-241">
              HIGHLY TALENTED&nbsp;&nbsp;ARTISTS PERFORMING THROUGHOUT THE&nbsp;&nbsp;NIGHT
            </p>
            <div className="text-wrapper-240">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
          </div>
        </div>
        <div className="group-466">
          <div className="group-467">
            <div className="text-wrapper-242">OUR</div>
            <div className="group-468">
              <div className="overlap-group-46">
                <div className="CHOSEN-ATTENDEES-2">CHOSEN&nbsp;&nbsp;ATTENDEES</div>
                <div className="rectangle-177" />
                <div className="rectangle-178" />
              </div>
            </div>
          </div>
        </div>
        <div className="group-469">
          <div className="text-wrapper-239">OFFERED</div>
          <div className="group-470">
            <div className="overlap-group-47">
              <div className="SPONSORSHIP-PACKAGES-3">SPONSORSHIP&nbsp;&nbsp;PACKAGES</div>
              <div className="rectangle-179" />
              <div className="rectangle-180" />
            </div>
          </div>
        </div>
        <div className="group-471">
          <div className="PROFESSIONAL-wrapper">
            <div className="text-wrapper-243">
              PROFESSIONAL ADVERTISEMENT&nbsp;&nbsp;PRODUCTION&nbsp;&nbsp;
              PROMOTED&nbsp;&nbsp;BY&nbsp;&nbsp;OUR&nbsp;&nbsp;PR&nbsp;&nbsp;&amp;&nbsp;&nbsp;INFLUENCERS
            </div>
          </div>
          <div className="ABOVE-LISTED-wrapper">
            <div className="text-wrapper-244">ABOVE&nbsp;&nbsp;LISTED&nbsp;&nbsp;EXCLUSIVE&nbsp;&nbsp;SERVICES</div>
          </div>
          <div className="group-472">
            <p className="text-wrapper-245">
              CONTINUOUS ADVERTISEMENT ON HIGHLY
              VISIBLE&nbsp;&nbsp;LIFE&nbsp;&nbsp;SIZE&nbsp;&nbsp;NFT&nbsp;&nbsp;PANELS
            </p>
            <div className="div-10">
              <div className="group-473">
                <img className="check-2" alt="Check" src="/img/check-48.png" />
                <img className="check-3" alt="Check" src="/img/check-48.png" />
                <img className="check-4" alt="Check" src="/img/check-48.png" />
                <img className="check-5" alt="Check" src="/img/check-48.png" />
                <img className="check-6" alt="Check" src="/img/check-48.png" />
              </div>
              <div className="rectangle-181" />
              <div className="rectangle-182" />
              <div className="rectangle-183" />
              <div className="rectangle-184" />
              <div className="rectangle-185" />
            </div>
          </div>
          <div className="group-474">
            <div className="group-475">
              <div className="text-wrapper-245">BRING A +1</div>
            </div>
            <div className="div-10">
              <div className="overlap-group-48">
                <div className="group-473">
                  <img className="check-2" alt="Check" src="/img/check-48.png" />
                  <img className="check-3" alt="Check" src="/img/check-48.png" />
                  <img className="check-4" alt="Check" src="/img/check-48.png" />
                  <img className="check-5" alt="Check" src="/img/check-48.png" />
                  <img className="check-6" alt="Check" src="/img/check-48.png" />
                </div>
                <div className="rectangle-181" />
                <div className="rectangle-182" />
                <div className="rectangle-183" />
                <div className="rectangle-184" />
                <div className="rectangle-185" />
              </div>
            </div>
          </div>
          <div className="group-476">
            <div className="text-wrapper-244">
              DISTRIBUTION&nbsp;&nbsp;OF&nbsp;&nbsp;PRODUCT&nbsp;&nbsp;SAMPLES&nbsp;&nbsp;VIA&nbsp;&nbsp;OUR&nbsp;&nbsp;VIP&nbsp;&nbsp;GIFT&nbsp;&nbsp;BOXES
            </div>
            <div className="group-477">
              <div className="overlap-group-49">
                <div className="rectangle-186" />
                <div className="rectangle-187" />
                <div className="div-11" />
                <div className="div-12" />
                <div className="div-13" />
                <div className="group-478">
                  <img className="check-7" alt="Check" src="/img/check-58.png" />
                  <img className="check-8" alt="Check" src="/img/check-58.png" />
                  <img className="check-9" alt="Check" src="/img/check-58.png" />
                  <img className="check-10" alt="Check" src="/img/check-58.png" />
                  <img className="check-11" alt="Check" src="/img/check-58.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="group-479">
            <div className="PRIVATE-PRESENTATION-2">
              PRIVATE&nbsp;&nbsp;PRESENTATION&nbsp;&nbsp;SLOT&nbsp;&nbsp;IN&nbsp;&nbsp;ENCLOSED&nbsp;&nbsp;AREA&nbsp;&nbsp;ADVERTISED&nbsp;&nbsp;TO&nbsp;&nbsp;ATTENDEES
            </div>
            <div className="group-480">
              <div className="overlap-51">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-52">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-group-50">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-group-51">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="div-13">
                <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
              </div>
            </div>
          </div>
          <div className="group-481">
            <p className="text-wrapper-245">ENGRAVINGS ON ALL OF OUR&nbsp;&nbsp;BAR&nbsp;&nbsp;GLASSES</p>
            <div className="group-480">
              <div className="overlap-51">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-52">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-group-50">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-group-51">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="div-13">
                <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
              </div>
            </div>
          </div>
          <div className="group-482">
            <div className="text-wrapper-245">BRING A +5</div>
            <div className="group-480">
              <div className="overlap-51">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-52">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-group-50">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-group-51">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="div-13">
                <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
              </div>
            </div>
          </div>
          <div className="group-483">
            <p className="text-wrapper-246">PRIVATE CONCIERGE FOR VIP ACCESS ANYWHERE IN MILAN</p>
            <div className="group-484">
              <div className="overlap-51">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-52">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-group-50">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="overlap-group-51">
                <img className="check-12" alt="Check" src="/img/check-63.png" />
                <div className="div-13" />
              </div>
              <div className="div-13">
                <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
              </div>
            </div>
          </div>
          <div className="group-485">
            <div className="text-wrapper-245">BRING +10</div>
            <div className="group-480">
              <div className="overlap-group-49">
                <div className="boxes">
                  <div className="rectangle-186" />
                  <div className="rectangle-187" />
                  <div className="div-11" />
                  <div className="div-12">
                    <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
                  </div>
                  <div className="div-13" />
                </div>
                <img className="check-13" alt="Check" src="/img/check-63.png" />
                <img className="check-14" alt="Check" src="/img/check-63.png" />
                <img className="check-15" alt="Check" src="/img/check-63.png" />
                <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
              </div>
            </div>
          </div>
          <div className="group-486">
            <div className="overlap-group-49">
              <div className="boxes">
                <div className="rectangle-186" />
                <div className="rectangle-187" />
                <div className="div-11" />
                <div className="div-12">
                  <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
                </div>
                <div className="div-13" />
              </div>
              <img className="check-13" alt="Check" src="/img/check-63.png" />
              <img className="check-14" alt="Check" src="/img/check-63.png" />
              <img className="check-15" alt="Check" src="/img/check-63.png" />
              <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
            </div>
          </div>
          <div className="group-487">
            <div className="overlap-group-49">
              <div className="boxes">
                <div className="rectangle-186" />
                <div className="rectangle-187" />
                <div className="div-11" />
                <div className="div-12">
                  <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
                </div>
                <div className="div-13" />
              </div>
              <img className="check-13" alt="Check" src="/img/check-63.png" />
              <img className="check-14" alt="Check" src="/img/check-63.png" />
              <img className="check-15" alt="Check" src="/img/check-63.png" />
              <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
            </div>
          </div>
          <div className="group-488">
            <div className="ONGOING-BEST-IN-wrapper">
              <div className="text-wrapper-243">
                ONGOING&nbsp;&nbsp;BEST&nbsp;&nbsp;IN&nbsp;&nbsp;CLASS&nbsp;&nbsp;MARKETING&nbsp;&nbsp;SERVICES&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY
                OUR&nbsp;&nbsp;COMPANY
              </div>
            </div>
            <div className="group-480">
              <div className="overlap-group-49">
                <div className="boxes">
                  <div className="rectangle-186" />
                  <div className="rectangle-187" />
                  <div className="div-11" />
                  <div className="div-12">
                    <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
                  </div>
                  <div className="div-13" />
                </div>
                <img className="check-13" alt="Check" src="/img/check-63.png" />
                <img className="check-14" alt="Check" src="/img/check-63.png" />
                <img className="check-15" alt="Check" src="/img/check-63.png" />
                <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
              </div>
            </div>
          </div>
          <div className="group-489">
            <p className="text-wrapper-243">EQUITY SHARE IN OUR MULTI-CONGLOMERATE INTERNATIONAL AGENCY AGGREGATE</p>
            <div className="group-480">
              <div className="overlap-group-49">
                <div className="boxes">
                  <div className="rectangle-186" />
                  <div className="rectangle-187" />
                  <div className="div-11">
                    <img className="vector-58" alt="Vector" src="/img/vector-40.svg" />
                  </div>
                  <div className="div-12">
                    <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
                  </div>
                  <div className="div-13" />
                </div>
                <img className="check-13" alt="Check" src="/img/check-63.png" />
                <img className="check-14" alt="Check" src="/img/check-63.png" />
                <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
              </div>
            </div>
          </div>
          <div className="group-490">
            <p className="a-UNIQUE-2">
              A UNIQUE IDENTIFICATION GRANTING
              INDEFINITE&nbsp;&nbsp;VIP&nbsp;&nbsp;SPONSORSHIP&nbsp;&nbsp;STATUS&nbsp;&nbsp;WITHIN&nbsp;&nbsp;OUR
              ORGANIZATION, INCLUDING VIP STATUS AT ALL OPEN EVENTS, &amp; ACCESS TO SOME FULLY
              PRIVATE&nbsp;&nbsp;UNADVERTISED&nbsp;&nbsp;EVENTS,&nbsp;&nbsp;CONTINUOUS
              INTRODUCTIONS&nbsp;&nbsp;THROUGH&nbsp;&nbsp;NETWORK, AND GROWTH THROUGH OUR ORGANIZATION
            </p>
            <div className="group-491">
              <img className="check-14" alt="Check" src="/img/check-63.png" />
              <img className="vector-58" alt="Vector" src="/img/vector-27.svg" />
            </div>
          </div>
        </div>
        <div className="group-492">
          <div className="text-wrapper-247">10,000€</div>
          <div className="text-wrapper-248">25,000€</div>
          <div className="text-wrapper-249">50,000€</div>
          <div className="text-wrapper-250">100,000€</div>
          <div className="text-wrapper-251">5,000€</div>
          <div className="rectangle-188" />
          <div className="rectangle-189" />
          <div className="rectangle-190" />
          <div className="rectangle-191" />
          <div className="rectangle-192" />
        </div>
        <div className="group-493">
          <div className="group-494">
            <div className="group-495">
              <div className="rectangle-193" />
              <div className="rectangle-194" />
              <img
                className="AAA-logo-test-12"
                alt="Aaa logo test"
                src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
              />
            </div>
          </div>
        </div>
        <div className="overlap-53">
          <div className="group-496">
            <div className="div-14">
              <div className="div-14">
                <div className="overlap-group-52">
                  <img className="image-10" alt="Image" src="/img/image-371-4.png" />
                  <div className="rectangle-195" />
                  <div className="rectangle-196" />
                  <div className="rectangle-197" />
                  <div className="rectangle-198" />
                  <div className="rectangle-199" />
                </div>
              </div>
              <div className="text-wrapper-252">For More Info, contact</div>
              <div className="f-sokail-ai-j-sokail-9">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
              <img className="basiglio-milano-2" alt="Basiglio milano" src="/img/basiglio-milano-italy-2.svg" />
            </div>
            <div className="group-497">
              <div className="group-498">
                <div className="group-499">
                  <div className="PRESENTED-BY-10">PRESENTED&nbsp;&nbsp;BY</div>
                </div>
              </div>
            </div>
            <img className="group-500" alt="Group" src="/img/group-48098605-4.png" />
          </div>
          <a
            className="group-501"
            href="https://api.whatsapp.com/send/?phone=19548823115"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="overlap-54">
              <div className="rectangle-200" />
              <div className="text-wrapper-253">BECOME A SPONSOR</div>
            </div>
          </a>
          <p className="text-wrapper-254">
            two slots remaining for VIP sponsorship with additional benefits and pricing
          </p>
          <div className="group-502">
            <SocialIcons color="negative" platform="telegram" />
            <ConcreteComponentNode color="negative" platform="x-twitter" />
            <SocialIcons color="negative" platform="instagram" />
            <SocialIcons color="negative" platform="whats-app" />
            <SocialIcons color="negative" platform="messenger" />
          </div>
        </div>
        <div className="group-503">
          <div className="THE-TOP-OF-THE-5">THE TOP OF THE&nbsp;&nbsp;FASHION&nbsp;&nbsp;INDUSTRY</div>
          <div className="text-wrapper-255">INTERNATIONAL ROYALTY</div>
          <div className="text-wrapper-256">CELEBRITY MUSICIANS</div>
          <div className="text-wrapper-257">EXTRAORDINARILY SUCCESSFUL BUSINESS MAGNATES</div>
          <p className="SOME-OF-THE-MOST-4">
            SOME&nbsp;&nbsp;OF .THE MOST INFLUENTIAL MEMBERS&nbsp;&nbsp;OF KEY COUNTRIES
          </p>
          <p className="text-wrapper-258">MASTERS OF MEDIA, MARKETING, SALES, AND PUBLIC RELATIONS</p>
        </div>
      </div>
    </div>
  );
};
