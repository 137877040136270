/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PlatformDribbbleColorNegative = ({ className }) => {
  return (
    <svg
      className={`platform-dribbble-color-negative ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M24 0C10.7505 0 0 10.7505 0 24C0 37.2495 10.7505 48 24 48C37.2234 48 48 37.2495 48 24C48 10.7505 37.2234 0 24 0ZM39.8525 11.0629C42.7158 14.551 44.4339 19.0021 44.486 23.8177C43.8092 23.6877 37.0411 22.308 30.2212 23.167C30.0651 22.8286 29.9349 22.4641 29.7788 22.0998C29.3622 21.1107 28.8937 20.0955 28.4251 19.1324C35.9739 16.0608 39.41 11.6356 39.8525 11.0629ZM24 3.54013C29.2061 3.54013 33.9696 5.49242 37.5879 8.69415C37.2234 9.21476 34.1258 13.3536 26.8372 16.0868C23.4794 9.91756 19.7571 4.86768 19.1844 4.08676C20.7201 3.72234 22.3341 3.54013 24 3.54013ZM15.2799 5.46637C15.8265 6.19522 19.4708 11.2712 22.8807 17.3102C13.3015 19.8612 4.84165 19.8092 3.93058 19.8092C5.25813 13.4577 9.55314 8.17353 15.2799 5.46637ZM3.48807 24.0261C3.48807 23.8178 3.48807 23.6096 3.48807 23.4014C4.3731 23.4273 14.3167 23.5575 24.5466 20.486C25.1454 21.6312 25.692 22.8026 26.2127 23.9739C25.9522 24.052 25.6659 24.1302 25.4056 24.2082C14.8373 27.6181 9.21476 36.937 8.7462 37.7179C5.4924 34.0998 3.48807 29.2842 3.48807 24.0261ZM24 44.5119C19.2624 44.5119 14.8894 42.898 11.4273 40.1908C11.7918 39.4361 15.9565 31.4187 27.514 27.384C27.5661 27.3579 27.5922 27.3579 27.6443 27.332C30.5336 34.8026 31.705 41.0759 32.0173 42.872C29.5445 43.9392 26.8372 44.5119 24 44.5119ZM35.4273 40.9978C35.2191 39.7483 34.1258 33.7614 31.4447 26.3948C37.8743 25.3796 43.4967 27.0456 44.1996 27.2799C43.3146 32.9805 40.0347 37.9002 35.4273 40.9978Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  );
};
