import React from "react";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { SocialIcons } from "../../components/SocialIcons";
import { PlatformWhatsappColorOriginal1 } from "../../icons/PlatformWhatsappColorOriginal1";
import { SocialIcons4 } from "../../icons/SocialIcons4";
import "./style.css";

export const Main = () => {
  return (
    <div className="MAIN">
      <div className="div-7">
        <div className="group-371">
          <div className="group-372">
            <div className="rectangle-135" />
          </div>
          <div className="rectangle-136" />
        </div>
        <div className="overlap-42">
          <div className="group-373">
            <div className="overlap-group-38">
              <img className="image-6" alt="Image" src="/img/image-361.png" />
              <div className="rectangle-137" />
              <div className="rectangle-138" />
              <div className="rectangle-139" />
              <div className="rectangle-140" />
            </div>
          </div>
          <div className="group-374">
            <div className="overlap-group-38">
              <img className="image-7" alt="Image" src="/img/image-361.png" />
              <div className="rectangle-137" />
              <div className="rectangle-141" />
              <div className="rectangle-142" />
              <div className="rectangle-143" />
            </div>
          </div>
          <div className="group-375">
            <div className="div-8">
              <div className="div-8">
                <div className="group-376">
                  <img className="group-377" alt="Group" src="/img/group-48098454.png" />
                </div>
              </div>
              <img className="mask-group-22" alt="Mask group" src="/img/mask-group-2.png" />
              <div className="group-378">
                <div className="overlap-group-39">
                  <img className="vector-40" alt="Vector" src="/img/vector-233-1-2.svg" />
                  <img className="vector-41" alt="Vector" src="/img/vector-244-1-2.svg" />
                  <img className="group-379" alt="Group" src="/img/group-48098648-1-2.png" />
                </div>
                <img className="vector-42" alt="Vector" src="/img/vector-231-1-4.svg" />
                <img className="vector-43" alt="Vector" src="/img/vector-232-1-4.svg" />
                <img className="vector-44" alt="Vector" src="/img/vector-235-1-2.svg" />
                <img className="vector-45" alt="Vector" src="/img/vector-236-1.svg" />
              </div>
              <div className="FULL-MOON-AWAKENING-wrapper">
                <div className="FULL-MOON-AWAKENING-3">FULL&nbsp;&nbsp;MOON&nbsp;&nbsp;AWAKENING</div>
              </div>
              <div className="group-380">
                <div className="group-381">
                  <div className="group-382">
                    <div className="group-381">
                      <div className="group-382">
                        <div className="group-383">
                          <div className="overlap-group-40">
                            <div className="text-wrapper-198">July 20th</div>
                            <div className="rectangle-144" />
                            <div className="rectangle-145" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-384">
              <div className="group-385">
                <div className="overlap-group-41">
                  <div className="rectangle-146" />
                  <div className="text-wrapper-199">GUEST</div>
                </div>
              </div>
              <div className="group-386">
                <div className="overlap-group-41">
                  <div className="rectangle-146" />
                  <div className="text-wrapper-199">SPONSOR</div>
                </div>
              </div>
              <div className="group-387">
                <div className="overlap-group-41">
                  <div className="rectangle-146" />
                  <div className="text-wrapper-199">VIP</div>
                </div>
              </div>
            </div>
            <div className="overlap-43">
              <div className="group-388">
                <div className="rectangle-147" />
                <div className="rectangle-148" />
                <div className="HOSTED-BY-3">HOSTED&nbsp;&nbsp; BY</div>
                <div className="FABIANO-VALLI-LEVI-3">
                  FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp;&nbsp;&nbsp;co
                </div>
              </div>
              <div className="text-wrapper-200">SOME EVENT SPECIALS</div>
              <div className="EXPERIENCE-THE-3">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
            </div>
            <div className="overlap-44">
              <div className="group-389">
                <div className="group-390">
                  <div className="group-391">
                    <div className="rectangle-149" />
                    <div className="rectangle-150" />
                    <img
                      className="AAA-logo-test-10"
                      alt="Aaa logo test"
                      src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
                    />
                  </div>
                </div>
              </div>
              <img className="image-8" alt="Image" src="/img/image-372.png" />
              <div className="rectangle-151" />
              <div className="rectangle-151" />
              <div className="rectangle-152" />
              <div className="rectangle-153" />
              <div className="rectangle-154" />
              <div className="rectangle-155" />
              <img className="rectangle-156" alt="Rectangle" src="/img/rectangle-2836-2.svg" />
              <div className="group-392">
                <div className="group-393">
                  <div className="group-394">
                    <div className="PRESENTED-BY-8">PRESENTED&nbsp;&nbsp;BY</div>
                  </div>
                </div>
              </div>
              <img className="group-395" alt="Group" src="/img/group-48098605-1.png" />
              <p className="text-wrapper-201">Let us know before 12th of July</p>
              <div className="group-396">
                <div className="text-wrapper-202">Secret</div>
                <img className="location-5" alt="Location" src="/img/location.svg" />
              </div>
              <div className="group-397">
                <div className="text-wrapper-203">For more Info, contact</div>
                <div className="f-sokail-ai-j-sokail-7">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
              </div>
              <div className="text-wrapper-204">+++++</div>
              <div className="group-398">
                <SocialIcons color="negative" platform="telegram" />
                <ConcreteComponentNode color="negative" platform="x-twitter" />
                <SocialIcons color="negative" platform="instagram" />
                <PlatformWhatsappColorOriginal1 className="platform-whatsapp-color-original" color="white" />
                <SocialIcons4 className="social-icons-4-instance" />
              </div>
              <div className="group-399">
                <div className="group-381">
                  <div className="group-400">
                    <div className="group-381">
                      <div className="group-400">
                        <div className="group-401">
                          <div className="text-wrapper-205">or</div>
                          <div className="rectangle-157" />
                          <div className="rectangle-158" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-206">EXCLUSIVE LOCATION</div>
            <div className="text-wrapper-207">SKL SS 2024 CATWALK</div>
            <div className="text-wrapper-208">TOP UNDERGROUND DJS</div>
            <div className="text-wrapper-209">FESTIVAL STAGE</div>
            <div className="text-wrapper-210">LUXURY YURTS</div>
            <div className="text-wrapper-211">LIGHTING DESIGN SHOW</div>
            <div className="text-wrapper-212">LIVE ART PRODUCTIONS</div>
            <div className="text-wrapper-213">SHAMANIC RECALIBRATION</div>
          </div>
        </div>
      </div>
    </div>
  );
};
