import React from "react";
import "./style.css";

export const DivWrapper = () => {
  return (
    <div className="div-wrapper">
      <div className="SPONSOR-INVITATION-3">
        <div className="overlap-65">
          <div className="overlap-66">
            <div className="group-555">
              <div className="rectangle-221" />
            </div>
            <div className="text-wrapper-286">BRING A +1</div>
            <p className="MASTERS-OF-MEDIA-3">
              MASTERS OF MEDIA, MARKETING
              <br />, SALES, AND PUBLIC RELATIONS
            </p>
            <div className="group-556">
              <div className="group-557">
                <div className="text-wrapper-287">EXCLUSIVE</div>
                <div className="text-wrapper-288">SERVICES</div>
                <div className="text-wrapper-289">FOR OUR TOP SPONSORS</div>
              </div>
            </div>
            <div className="group-558">
              <div className="group-559">
                <p className="text-wrapper-290">COMPLIMENTARY BOOKINGS AT THE TOP HOTELS OF THE AREA</p>
                <p className="EXCLUSIVE-ACCESS-AND-5">
                  EXCLUSIVE&nbsp;&nbsp;ACCESS AND
                  <br /> VIP TREATMENT IN&nbsp;&nbsp;MILAN’S
                  <br />
                  &nbsp;&nbsp;FINEST
                </p>
              </div>
              <div className="group-560">
                <div className="text-wrapper-291">PRIVATE TRANSPORTATION ARRANGEMENTS</div>
                <p className="COMPLIMENTARY-LUXURY-4">
                  {" "}
                  COMPLIMENTARY&nbsp;&nbsp;LUXURY
                  <br />
                  &nbsp;&nbsp;TRANSPORT SERVICES
                  <br />
                  &nbsp;&nbsp;BEGINNING AT THE AIRPORT
                </p>
              </div>
              <div className="group-561">
                <p className="text-wrapper-292">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
                <p className="HELICOPTER-ARRIVALS-2">
                  HELICOPTER ARRIVALS ARE
                  <br /> AVAILABLE UPON REQUEST
                </p>
              </div>
            </div>
          </div>
          <div className="text-wrapper-293">5,000€</div>
        </div>
        <div className="group-562">
          <div className="overlap-group-56">
            <div className="overlap-67">
              <img className="vector-71" alt="Vector" src="/img/milan-italy.png" />
              <img className="vector-72" alt="Vector" src="/img/milan-italy.png" />
              <img className="group-563" alt="Group" src="/img/milan-italy.png" />
            </div>
            <img className="vector-73" alt="Vector" src="/img/milan-italy.png" />
            <img className="vector-74" alt="Vector" src="/img/milan-italy.png" />
            <img className="vector-75" alt="Vector" src="/img/milan-italy.png" />
            <img className="vector-76" alt="Vector" src="/img/milan-italy.png" />
          </div>
        </div>
        <img className="mask-group-33" alt="Mask group" src="/img/mask-group-2x.png" />
        <img className="mask-group-34" alt="Mask group" src="/img/mask-group-2x.png" />
        <img className="mask-group-35" alt="Mask group" src="/img/mask-group-2x.png" />
        <img className="mask-group-36" alt="Mask group" src="/img/mask-group-2x.png" />
        <div className="overlap-68">
          <div className="group-564">
            <div className="group-565">
              <div className="group-566">
                <div className="PRESENTED-BY-12">PRESENTED&nbsp;&nbsp;BY</div>
              </div>
            </div>
          </div>
          <div className="group-567">
            <div className="overlap-69">
              <div className="rectangle-222" />
              <div className="text-wrapper-294">BECOME A SPONSOR</div>
            </div>
            <div className="text-wrapper-295">Enter your email here</div>
          </div>
          <img className="mask-group-37" alt="Mask group" src="/img/mask-group-4-2.png" />
          <div className="rectangle-223" />
        </div>
        <div className="group-568">
          <div className="group-569">
            <div className="overlap-group-57">
              <div className="CHOSEN-ATTENDEES-3">CHOSEN&nbsp;&nbsp;ATTENDEES</div>
              <div className="rectangle-224" />
            </div>
            <img className="rectangle-225" alt="Rectangle" src="/img/milan-italy.png" />
          </div>
        </div>
        <div className="text-wrapper-296">100,000€</div>
        <div className="overlap-70">
          <div className="text-wrapper-297">BRING A +5</div>
          <div className="text-wrapper-298">10,000€</div>
          <div className="text-wrapper-299">25,000€</div>
          <div className="text-wrapper-300">50,000€</div>
          <div className="text-wrapper-301">100,000€</div>
          <div className="text-wrapper-302">5,000€</div>
          <p className="CONTINUOUS-2">
            CONTINUOUS ADVERTISEMENT ON HIGHLY VISIBLE&nbsp;&nbsp;LIFE&nbsp;&nbsp;SIZE&nbsp;&nbsp;NFT&nbsp;&nbsp;PANELS
          </p>
        </div>
        <div className="overlap-71">
          <div className="text-wrapper-303">BRING +10</div>
          <div className="item-66">
            <div className="radio-wrapper-2">
              <div className="thumb-wrapper">
                <div className="thumb-2">
                  <div className="icon-2">
                    <img className="color-22" alt="Color" src="/img/color-72.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-67">
            <div className="radio-wrapper-2">
              <div className="thumb-wrapper">
                <div className="thumb-2">
                  <div className="icon-2">
                    <img className="color-22" alt="Color" src="/img/color-72.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-68">
            <div className="radio-wrapper-2">
              <div className="controller-2">
                <div className="thumb-2">
                  <div className="icon-2">
                    <img className="color-22" alt="Color" src="/img/color-71.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-69">
            <div className="radio-wrapper-2">
              <div className="controller-2">
                <div className="thumb-2">
                  <div className="icon-2">
                    <img className="color-22" alt="Color" src="/img/color-71.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item-70">
            <div className="radio-wrapper-2">
              <div className="thumb-wrapper">
                <div className="thumb-2">
                  <div className="icon-2">
                    <img className="color-22" alt="Color" src="/img/color-77.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-71">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-23" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-72">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-24" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-73">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-25" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-74">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-26" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-75">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-27" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-76">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-28" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-77">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-29" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-78">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-30" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-79">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-31" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-80">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-32" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-81">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-33" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-82">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-34" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-83">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-35" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-84">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-36" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-85">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-37" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-86">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-38" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-87">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-39" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-88">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-40" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-89">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-41" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-90">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-42" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-91">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-43" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-92">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-44" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-93">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-45" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-94">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-46" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-95">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-47" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-96">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-48" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-97">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-49" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-98">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-50" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-99">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-51" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-100">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-52" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-101">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-53" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-102">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-54" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-103">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-55" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-104">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-56" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-105">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-57" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-106">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-58" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-107">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-59" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-108">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-60" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-109">
          <div className="radio-wrapper-2">
            <div className="controller-3">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-61" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-304">EVENT SPECIALS</div>
        <div className="rectangle-226" />
        <div className="text-wrapper-305">Basiglio,</div>
        <img className="milan-italy-2" alt="Milan italy" src="/img/milan-italy.png" />
        <div className="text-wrapper-306">For more Info, contact</div>
        <div className="f-sokail-ai-j-sokail-12">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
        <img className="group-570" alt="Group" src="/img/milan-italy.png" />
        <div className="PRESENTED-BY-13">PRESENTED&nbsp;&nbsp;BY</div>
        <div className="THE-TOP-OF-THE-6">
          THE TOP OF THE&nbsp;&nbsp;FASHION
          <br />
          &nbsp;&nbsp;INDUSTRY
        </div>
        <div className="text-wrapper-307">INTERNATIONAL ROYALTY</div>
        <div className="text-wrapper-308">CELEBRITY MUSICIANS</div>
        <div className="EXTRAORDINARILY-3">
          EXTRAORDINARILY SUCCESSFUL
          <br /> BUSINESS MAGNATES
        </div>
        <p className="SOME-OF-THE-MOST-5">
          SOME&nbsp;&nbsp;OF .THE MOST INFLUENTIAL
          <br /> MEMBERS&nbsp;&nbsp;OF KEY COUNTRIES
        </p>
        <div className="text-wrapper-309">BRING A +5</div>
        <p className="text-wrapper-310">PRIVATE CONCIERGE FOR VIP ACCESS ANYWHERE IN MILAN</p>
        <div className="text-wrapper-311">BRING +10</div>
        <div className="ABOVE-LISTED-2">ABOVE&nbsp;&nbsp;LISTED&nbsp;&nbsp;EXCLUSIVE&nbsp;&nbsp;SERVICES</div>
        <div className="PROFESSIONAL-2">
          PROFESSIONAL ADVERTISEMENT&nbsp;&nbsp;PRODUCTION&nbsp;&nbsp;
          PROMOTED&nbsp;&nbsp;BY&nbsp;&nbsp;OUR&nbsp;&nbsp;PR&nbsp;&nbsp;&amp;&nbsp;&nbsp;INFLUENCERS
        </div>
        <div className="ONGOING-BEST-IN-2">
          ONGOING&nbsp;&nbsp;BEST&nbsp;&nbsp;IN&nbsp;&nbsp;CLASS&nbsp;&nbsp;MARKETING&nbsp;&nbsp;SERVICES&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY
          OUR&nbsp;&nbsp;COMPANY
        </div>
        <p className="text-wrapper-312">EQUITY SHARE IN OUR MULTI-CONGLOMERATE INTERNATIONAL AGENCY AGGREGATE</p>
        <p className="a-UNIQUE-3">
          A UNIQUE IDENTIFICATION GRANTING
          INDEFINITE&nbsp;&nbsp;VIP&nbsp;&nbsp;SPONSORSHIP&nbsp;&nbsp;STATUS&nbsp;&nbsp;WITHIN&nbsp;&nbsp;OUR
          ORGANIZATION,
          <br />
          INCLUDING VIP STATUS AT ALL&nbsp;&nbsp;OPEN EVENTS, &amp; ACCESS TO SOME FULLY
          PRIVATE&nbsp;&nbsp;UNADVERTISED&nbsp;&nbsp;EVENTS,&nbsp;&nbsp;CONTINUOUS
          INTRODUCTIONS&nbsp;&nbsp;THROUGH&nbsp;&nbsp;NETWORK
        </p>
        <div className="item-110">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-62" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-111">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-63" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-112">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-64" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-113">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-65" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-114">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-66" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-115">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-67" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-116">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-68" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-117">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-69" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-118">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-70" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-119">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-71" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-120">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-72" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-121">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-73" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-122">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-74" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-123">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-124">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-125">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-126">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-127">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-128">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-129">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-130">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-131">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-132">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-133">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-134">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-82.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-135">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-75" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-136">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-76" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-137">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-138">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-139">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-140">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-77" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-141">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-142">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-143">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-144">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-145">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-78" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-146">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-147">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-148">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-149">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-150">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-79" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-151">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-80" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-152">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-153">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-154">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="ENGRAVINGS-ON-ALL-OF-2">ENGRAVINGS ON ALL OF OUR&nbsp;&nbsp;BAR&nbsp;&nbsp;GLASSES</p>
        <div className="DISTRIBUTION-OF-2">
          DISTRIBUTION&nbsp;&nbsp;OF&nbsp;&nbsp;PRODUCT&nbsp;&nbsp;SAMPLES&nbsp;&nbsp;VIA&nbsp;&nbsp;OUR&nbsp;&nbsp;VIP&nbsp;&nbsp;GIFT&nbsp;&nbsp;BOXES
        </div>
        <div className="item-155">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-156">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-157">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-158">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-159">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-160">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-161">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-162">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-163">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-164">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-313">BRING A +1</div>
        <div className="item-165">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-3">
                <div className="icon-3">
                  <img className="color-81" alt="Color" src="/img/milan-italy.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-166">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-72.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-167">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-73.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-168">
          <div className="radio-wrapper-2">
            <div className="thumb-wrapper">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-77.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item-169">
          <div className="radio-wrapper-2">
            <div className="controller-2">
              <div className="thumb-2">
                <div className="icon-2">
                  <img className="color-22" alt="Color" src="/img/color-71.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="PRIVATE-PRESENTATION-3">
          PRIVATE&nbsp;&nbsp;PRESENTATION&nbsp;&nbsp;SLOT&nbsp;&nbsp;IN&nbsp;&nbsp;ENCLOSED&nbsp;&nbsp;AREA&nbsp;&nbsp;ADVERTISED&nbsp;&nbsp;TO&nbsp;&nbsp;ATTENDEES
        </div>
        <div className="group-571">
          <div className="text-wrapper-314">OFFERED</div>
          <div className="group-572">
            <div className="overlap-group-58">
              <div className="SPONSORSHIP-PACKAGES-4">
                SPONSORSHIP&nbsp;&nbsp;
                <br />
                PACKAGES
              </div>
              <div className="rectangle-227" />
              <div className="rectangle-228" />
            </div>
          </div>
        </div>
        <div className="flexcontainer-10">
          <p className="text-9">
            <span className="text-wrapper-315">
              EXPERIENCE&nbsp;&nbsp;
              <br />
            </span>
          </p>
          <p className="text-9">
            <span className="text-wrapper-315">THE&nbsp;&nbsp; UNTOUCHABLE</span>
          </p>
        </div>
        <div className="group-573">
          <div className="text-wrapper-316">EXCLUSIVE LOCATION</div>
          <div className="text-wrapper-317">
            AN EXPERTLY ARCHITECTED
            <br />
            &nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A
            <br />
            &nbsp;&nbsp;LUXURY&nbsp;&nbsp;VILLA
          </div>
        </div>
        <div className="group-574">
          <div className="text-wrapper-316">SKL SS 2024 CATWALK</div>
          <div className="text-wrapper-317">
            FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM
            <br />
            &nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp; THE&nbsp;&nbsp;US
          </div>
        </div>
        <div className="group-575">
          <div className="text-wrapper-316">TOP UNDERGROUND DJS</div>
          <div className="text-wrapper-317">
            SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE
            <br />
            &nbsp;&nbsp;UNDERGROUND
          </div>
        </div>
        <div className="group-576">
          <p className="text-wrapper-316">
            PRIVATE VIP ACCESS <br />
            TO SECRET AREAS
          </p>
          <div className="text-wrapper-318">
            UNIQUELY&nbsp;&nbsp;CRAFTED&nbsp;&nbsp;EXPERIENCES
            <br />
            &nbsp;&nbsp;HIDDEN&nbsp;&nbsp;INSIDE THE&nbsp;&nbsp;SPACE
          </div>
        </div>
        <div className="group-577">
          <div className="text-wrapper-316">PROPER FESTIVAL STAGE</div>
          <div className="text-wrapper-317">
            A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
            <br /> THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
          </div>
        </div>
        <div className="group-578">
          <div className="text-wrapper-316">LUXURY PRIVATE YERTS</div>
          <div className="text-wrapper-317">
            RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF AN
            <br />
            &nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
          </div>
        </div>
        <div className="group-579">
          <div className="text-wrapper-316">LIVE VIP TATTOO ARTISTS</div>
          <div className="text-wrapper-317">
            WITNESS&nbsp;&nbsp;A&nbsp;&nbsp;WORLD&nbsp;&nbsp;RECOGNIZED
            <br />
            &nbsp;&nbsp;TALENT PERFORM
          </div>
        </div>
        <div className="group-580">
          <div className="text-wrapper-316">LIGHTING DESIGN EXPERIENCE</div>
          <div className="text-wrapper-317">
            A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION
            <br />
            &nbsp;&nbsp;TAILORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
          </div>
        </div>
        <div className="group-581">
          <p className="text-wrapper-316">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
          <div className="text-wrapper-318">
            IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC TO&nbsp;&nbsp;CREATE
            <br />
            &nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
          </div>
        </div>
        <div className="group-582">
          <p className="text-wrapper-316">
            PRESENTATION OF OUR <br />
            PRIVATE ART GALLERY
          </p>
          <div className="text-wrapper-318">
            ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED
            <br />
            &nbsp;&nbsp;SPECIALTY NFC&nbsp;&nbsp;RINGS
          </div>
        </div>
        <div className="group-583">
          <div className="text-wrapper-316">LIVE ART PRODUCTIONS</div>
          <p className="text-wrapper-317">
            HIGHLY TALENTED&nbsp;&nbsp;ARTISTS
            <br /> PERFORMING THROUGHOUT THE
            <br />
            &nbsp;&nbsp;NIGHT
          </p>
        </div>
        <div className="overlap-72">
          <div className="group-584">
            <div className="CRYSTAL-AND-AND-3">
              CRYSTAL AND&nbsp;&nbsp;AND&nbsp;&nbsp; FREQUENCY&nbsp;&nbsp;HEALING&nbsp;&nbsp;SESSIONS
            </div>
          </div>
          <p className="text-wrapper-319">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
          <div className="CRYSTAL-AND-3">
            CRYSTAL AND FREQUENCY
            <br />
            HEALING SESSIONS
          </div>
        </div>
        <div className="group-585">
          <p className="text-wrapper-316">
            GIFT BOXES <br />
            FROM SPONSORS TO VIP
          </p>
          <div className="text-wrapper-318">
            SPECIAL GIFTS&nbsp;&nbsp;FROM
            <br />
            OUR .TOP&nbsp;&nbsp;GLOBAL .SPONSORS
          </div>
        </div>
        <div className="rectangle-229" />
        <div className="rectangle-230" />
      </div>
    </div>
  );
};
