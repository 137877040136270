/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PlatformPinterestColorOriginal1 = ({ className }) => {
  return (
    <svg
      className={`platform-pinterest-color-original-1 ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_260_3369)">
        <path
          className="path"
          d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
          fill="white"
        />
        <path
          className="path"
          d="M24 0C10.7457 0 0 10.7457 0 24C0 34.1728 6.32099 42.8642 15.2494 46.3605C15.0321 44.4642 14.8543 41.5407 15.3284 39.4667C15.763 37.5901 18.1333 27.5358 18.1333 27.5358C18.1333 27.5358 17.4222 26.0938 17.4222 23.9802C17.4222 20.642 19.358 18.1531 21.7679 18.1531C23.8222 18.1531 24.8099 19.6938 24.8099 21.5309C24.8099 23.5852 23.5062 26.6667 22.8148 29.5309C22.242 31.921 24.0198 33.8765 26.3704 33.8765C30.637 33.8765 33.916 29.3728 33.916 22.8938C33.916 17.1457 29.7877 13.1358 23.8815 13.1358C17.0469 13.1358 13.037 18.2519 13.037 23.5457C13.037 25.6 13.8272 27.8123 14.8148 29.0173C15.0123 29.2543 15.0321 29.4716 14.9728 29.7086C14.7951 30.4593 14.3802 32.0988 14.3012 32.4346C14.2025 32.8691 13.9457 32.9679 13.4914 32.7506C10.4889 31.3481 8.61235 26.9827 8.61235 23.4469C8.61235 15.8815 14.1037 8.9284 24.4741 8.9284C32.7901 8.9284 39.2691 14.8543 39.2691 22.7951C39.2691 31.0716 34.0543 37.7284 26.8247 37.7284C24.3951 37.7284 22.1037 36.4642 21.3333 34.963C21.3333 34.963 20.1284 39.5457 19.8321 40.6716C19.2988 42.7654 17.837 45.3728 16.8494 46.9728C19.1012 47.6642 21.4716 48.0395 23.9605 48.0395C37.2148 48.0395 47.9605 37.2938 47.9605 24.0395C48 10.7457 37.2543 0 24 0Z"
          fill="#E60019"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_260_3369">
          <rect className="rect" fill="white" height="48" width="48" />
        </clipPath>
      </defs>
    </svg>
  );
};
