import React from "react";
import "./style.css";

export const GuestVipInv = () => {
  return (
    <div className="GUEST-VIP-INV">
      <div className="div-18">
        <div className="group-769" />
        <div className="overlap-98">
          <div className="group-770">
            <div className="group-771">
              <div className="group-772">
                <div className="group-773">
                  <img className="group-774" alt="Group" src="/img/group-48098457-2-2.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="group-775">
            <div className="group-776">
              <div className="text-wrapper-430">EXCLUSIVE</div>
              <div className="text-wrapper-431">SERVICES</div>
            </div>
          </div>
          <div className="rectangle-297" />
          <div className="rectangle-298" />
          <div className="group-777">
            <div className="group-778">
              <p className="text-wrapper-432">CONNECTION TO THE TOP HOTELS OF THE AREA</p>
              <div className="EXCLUSIVE-2">
                EXCLUSIVE&nbsp;&nbsp;PARTNERSHIPS&nbsp;&nbsp;WITH&nbsp;&nbsp;THE
                FINEST&nbsp;&nbsp;HOTELS&nbsp;&nbsp;IN&nbsp;&nbsp;THE&nbsp;&nbsp;REGION
              </div>
            </div>
            <div className="group-779">
              <div className="text-wrapper-433">PRIVATE TRANSPORTATION AND RESERVATIONS</div>
              <div className="text-wrapper-434">
                {" "}
                SPECIAL&nbsp;&nbsp;BOOKING&nbsp;&nbsp;RATES&nbsp;&nbsp;FOR&nbsp;&nbsp;LUXURY&nbsp;&nbsp;TRANSPORT
                SERVICES
              </div>
            </div>
            <div className="group-780">
              <p className="text-wrapper-435">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
              <div className="text-wrapper-436">AVAILABLE ON REQUEST</div>
            </div>
            <div className="group-781">
              <p className="text-wrapper-435">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
              <div className="text-wrapper-436">EXPERIENCE HASSLE-FREE PARKING</div>
            </div>
            <div className="group-782">
              <p className="text-wrapper-436">HELICOPTER LANDING NEXT TO THE VENUE UPON REQUEST</p>
              <div className="text-wrapper-435">
                ARRIVE&nbsp;&nbsp;IN&nbsp;&nbsp;STYLE&nbsp;&nbsp;WITH
                OUR&nbsp;&nbsp;HELICOPTER&nbsp;&nbsp;LANDING&nbsp;&nbsp;SERVICE
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-99">
          <div className="group-783">
            <div className="text-wrapper-437">CORDIALLY INVITE YOU TO</div>
          </div>
          <div className="FABIANO-VALLI-LEVI-10">
            FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp; co.
          </div>
          <div className="group-784">
            <div className="overlap-group-72">
              <div className="rectangle-299" />
              <div className="rectangle-300" />
              <p className="text-wrapper-438">7 am - 7 pm</p>
            </div>
          </div>
          <div className="group-785">
            <div className="group-786">
              <div className="group-787">
                <img className="group-788" alt="Group" src="/img/group-48098457.png" />
              </div>
            </div>
          </div>
          <div className="group-789">
            <div className="text-wrapper-439">SECRET EVENT</div>
            <div className="overlap-100">
              <div className="group-790">
                <div className="text-wrapper-440">CORDIALLY INVITE YOU</div>
              </div>
              <div className="FABIANO-VALLI-LEVI-11">
                FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp; co.
              </div>
              <div className="group-791">
                <div className="overlap-group-73">
                  <div className="rectangle-301" />
                  <div className="rectangle-302" />
                  <div className="text-wrapper-441">July 20th</div>
                </div>
                <div className="text-wrapper-442">7pm - 7am</div>
              </div>
            </div>
          </div>
          <div className="group-792">
            <div className="overlap-101">
              <img className="vector-107" alt="Vector" src="/img/vector-233.svg" />
              <img className="vector-108" alt="Vector" src="/img/vector-244.svg" />
              <img className="group-793" alt="Group" src="/img/group-48098648-2x.png" />
            </div>
            <img className="vector-109" alt="Vector" src="/img/vector-231.svg" />
            <img className="vector-110" alt="Vector" src="/img/vector-232-4.svg" />
            <img className="vector-111" alt="Vector" src="/img/vector-235-3.svg" />
            <img className="vector-112" alt="Vector" src="/img/vector-236-4.svg" />
          </div>
        </div>
        <div className="overlap-102">
          <div className="group-794">
            <div className="rectangle-303" />
          </div>
          <div className="group-795">
            <div className="overlap-103">
              <div className="group-796">
                <div className="overlap-group-74">
                  <img className="image-13" alt="Image" src="/img/image-371-2.png" />
                  <div className="rectangle-304" />
                  <div className="rectangle-305" />
                  <div className="rectangle-306" />
                  <div className="rectangle-307" />
                  <img className="rectangle-308" alt="Rectangle" src="/img/rectangle-2827-5.svg" />
                </div>
              </div>
              <img className="basiglio-milano-4" alt="Basiglio milano" src="/img/basiglio-milano-italy-3.svg" />
              <p className="text-wrapper-443">Let us know before 12th of July</p>
              <div className="text-wrapper-444">For More Info, contact</div>
              <div className="f-sokail-ai-j-sokail-18">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
              <div className="group-797">
                <div className="rectangle-309" />
                <div className="rectangle-310" />
                <img
                  className="AAA-logo-test-21"
                  alt="Aaa logo test"
                  src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
                />
              </div>
            </div>
          </div>
          <div className="group-798">
            <div className="group-799">
              <div className="text-wrapper-445">EXCLUSIVE LOCATION</div>
              <div className="a-FLOOR-VILLA-THAT">A&nbsp;&nbsp;3&nbsp;&nbsp;FLOOR&nbsp;&nbsp;VILLA THAT CAN ROOM</div>
            </div>
            <div className="group-779">
              <div className="text-wrapper-446">SKL SS 2024 CATWALK</div>
              <div className="text-wrapper-434">
                FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM&nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp;
                THE&nbsp;&nbsp;US
              </div>
            </div>
            <div className="group-780">
              <p className="text-wrapper-435">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
              <p className="text-wrapper-436">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
            </div>
            <div className="group-781">
              <p className="text-wrapper-435">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
              <p className="text-wrapper-436">GIFT BOXES FROM SPONSORS TO VIP</p>
            </div>
            <div className="group-782">
              <div className="text-wrapper-436">TOP UNDERGROUND DJS</div>
              <p className="text-wrapper-435">No&nbsp;&nbsp;1 - 2 - 3</p>
            </div>
            <div className="group-800">
              <p className="text-wrapper-447">PRIVATE VIP ACCESS TO SECRET AREAS</p>
              <p className="text-wrapper-434">
                {" "}
                THERE&nbsp;&nbsp;WILL BE&nbsp;&nbsp;PRIVATE&nbsp;&nbsp;AREAS&nbsp;&nbsp;WITH&nbsp;&nbsp;ACCESS TO ???
              </p>
            </div>
            <div className="group-801">
              <p className="text-wrapper-435">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
              <div className="text-wrapper-436">LUXURY PRIVATE YERTS</div>
            </div>
            <div className="group-802">
              <p className="text-wrapper-435">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
              <div className="text-wrapper-436">LIVE&nbsp;&nbsp;VIP&nbsp;&nbsp;TATTOO&nbsp;&nbsp;ARTISTS</div>
            </div>
            <div className="group-803">
              <div className="text-wrapper-436">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
              <div className="text-wrapper-435">
                ARRIVE&nbsp;&nbsp;IN&nbsp;&nbsp;STYLE&nbsp;&nbsp;WITH
                OUR&nbsp;&nbsp;HELICOPTER&nbsp;&nbsp;LANDING&nbsp;&nbsp;SERVICE
              </div>
            </div>
            <div className="group-804">
              <p className="text-wrapper-435">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
              <div className="text-wrapper-436">LIGHTING DESIGN EXPERIENCE</div>
            </div>
            <div className="group-805">
              <p className="text-wrapper-435">COMPLIMENTARY VALET SERVICE AT THE VENUE</p>
              <p className="text-wrapper-436">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
            </div>
            <div className="overlap-104">
              <div className="group-806">
                <p className="text-wrapper-448">PRESENTATION OF OUR PRIVATE ART GALLERY</p>
                <div className="text-wrapper-434">
                  {" "}
                  SPECIAL&nbsp;&nbsp;BOOKING&nbsp;&nbsp;RATES&nbsp;&nbsp;FOR&nbsp;&nbsp;LUXURY&nbsp;&nbsp;TRANSPORT
                  SERVICES
                </div>
                <div className="SPECIAL-BOOKING">
                  {" "}
                  SPECIAL&nbsp;&nbsp;BOOKING&nbsp;&nbsp;RATES&nbsp;&nbsp;FOR&nbsp;&nbsp;LUXURY&nbsp;&nbsp;TRANSPORT
                  SERVICES
                </div>
              </div>
              <div className="group-807">
                <div className="text-wrapper-436">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
              </div>
            </div>
          </div>
        </div>
        <img className="group-808" alt="Group" src="/img/group-48098605-6.png" />
        <div className="group-809">
          <div className="overlap-105">
            <div className="rectangle-311" />
            <div className="rectangle-312" />
            <div className="group-810">
              <div className="group-811">
                <div className="THE-EVENT-IS"> THE EVENT IS</div>
                <div className="PRESENTED-BY-19">PRESENTED&nbsp;&nbsp;BY</div>
              </div>
            </div>
          </div>
        </div>
        <div className="SECRET-LINEUP">SECRET&nbsp;&nbsp;LINEUP</div>
        <div className="group-812">
          <div className="group-813">
            <div className="text-wrapper-449">EVENT SPECIALS</div>
            <div className="EXPERIENCE-THE-9">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
          </div>
          <div className="rectangle-313" />
          <div className="rectangle-314" />
        </div>
      </div>
    </div>
  );
};
