import React from "react";
import { useWindowWidth } from "../../breakpoints";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { SocialIcons } from "../../components/SocialIcons";
import { PlatformXTwitterColorNegative } from "../../icons/PlatformXTwitterColorNegative";
import { SocialIcons4 } from "../../icons/SocialIcons4";
import "./style.css";

export const SponsorWeb = () => {
  const screenWidth = useWindowWidth();

  return (
    <div className="SPONSOR-WEB">
      <div
        className="SPONSOR-MOBILE"
        style={{
          height: screenWidth < 1280 ? "9434px" : screenWidth >= 1280 ? "10512px" : undefined,
          width: screenWidth < 1280 ? "390px" : screenWidth >= 1280 ? "1280px" : undefined,
        }}
      >
        <div
          className="overlap-11"
          style={{
            height: screenWidth >= 1280 ? "864px" : screenWidth < 1280 ? "23px" : undefined,
            left: screenWidth >= 1280 ? "0" : screenWidth < 1280 ? "12px" : undefined,
            top: screenWidth >= 1280 ? "0" : screenWidth < 1280 ? "-2997px" : undefined,
            width: screenWidth >= 1280 ? "1280px" : screenWidth < 1280 ? "1604px" : undefined,
          }}
        >
          {screenWidth >= 1280 && (
            <>
              <div className="group-90">
                <div className="group-91">
                  <div className="group-92">
                    <div className="group-93">
                      <div className="overlap-12">
                        <img className="image-2" alt="Image" src="/img/image-360.png" />
                        <div className="rectangle-26" />
                        <div className="rectangle-27" />
                        <div className="group-94">
                          <div className="overlap-group-7">
                            <img className="image-2" alt="Image" src="/img/image-361-2.png" />
                            <div className="rectangle-28" />
                            <div className="rectangle-29" />
                            <div className="rectangle-30" />
                          </div>
                        </div>
                        <div className="rectangle-31" />
                        <div className="rectangle-32" />
                        <div className="rectangle-33" />
                        <div className="rectangle-34" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="mask-group-5" alt="Mask group" src="/img/mask-group-2-2x.png" />
            </>
          )}

          <div
            className="CRYSTAL-AND-AND"
            style={{
              background:
                screenWidth < 1280
                  ? "linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(153, 153, 153) 100%)"
                  : screenWidth >= 1280
                  ? "linear-gradient(180deg, rgb(234.72, 193.95, 130.53) 0%, rgb(121.98, 93.33, 48.78) 100%)"
                  : undefined,
              fontSize: screenWidth < 1280 ? "16px" : screenWidth >= 1280 ? "48px" : undefined,
              left: screenWidth < 1280 ? "0" : screenWidth >= 1280 ? "127px" : undefined,
              top: screenWidth < 1280 ? "0" : screenWidth >= 1280 ? "286px" : undefined,
              width: screenWidth < 1280 ? "1602px" : screenWidth >= 1280 ? "1026px" : undefined,
            }}
          >
            {screenWidth < 1280 && (
              <>CRYSTAL AND&nbsp;&nbsp;AND&nbsp;&nbsp; FREQUENCY&nbsp;&nbsp;HEALING&nbsp;&nbsp;SESSIONS</>
            )}

            {screenWidth >= 1280 && <>FULL MOON AWAKENING</>}
          </div>
          {screenWidth >= 1280 && (
            <>
              <div className="SPONSOR-OUR">SPONSOR&nbsp;&nbsp;OUR</div>
              <div className="text-wrapper-36">July 20th</div>
              <div className="group-95">
                <div className="group-96">
                  <div className="group-97">
                    <div className="group-96">
                      <div className="group-98">
                        <div className="rectangle-35" />
                        <div className="rectangle-36" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-99">
                <div className="overlap-group-8">
                  <img className="vector-13" alt="Vector" src="/img/vector-233-6.svg" />
                  <img className="vector-14" alt="Vector" src="/img/vector-244-1-4.svg" />
                  <img className="group-100" alt="Group" src="/img/group-48098648-8.png" />
                </div>
                <img className="vector-15" alt="Vector" src="/img/vector-231-3.svg" />
                <img className="vector-16" alt="Vector" src="/img/vector-232.svg" />
                <img className="vector-17" alt="Vector" src="/img/vector-235-4.svg" />
                <img className="vector-18" alt="Vector" src="/img/vector-236.svg" />
              </div>
            </>
          )}
        </div>
        {screenWidth >= 1280 && (
          <>
            <img className="mask-group-6" alt="Mask group" src="/img/mask-group.png" />
            <img className="mask-group-7" alt="Mask group" src="/img/mask-group-1.png" />
          </>
        )}

        <div
          className="group-101"
          style={{
            height: screenWidth < 1280 ? "109px" : screenWidth >= 1280 ? "113px" : undefined,
            left: screenWidth < 1280 ? "18px" : screenWidth >= 1280 ? "123px" : undefined,
            top: screenWidth < 1280 ? "3743px" : screenWidth >= 1280 ? "1169px" : undefined,
            width: screenWidth < 1280 ? "356px" : screenWidth >= 1280 ? "1038px" : undefined,
          }}
        >
          {screenWidth < 1280 && (
            <div className="overlap-13">
              <div className="group-102">
                <div className="overlap-group-9">
                  <div className="rectangle-37" />
                  <div className="text-wrapper-37">Silver</div>
                </div>
              </div>
              <img className="img-2" alt="Silver sigil" src="/img/silver-sigil.png" />
              <div className="text-wrapper-38">1,000€</div>
            </div>
          )}

          {screenWidth >= 1280 && (
            <>
              <div className="rectangle-38" />
              <div className="HOSTED-BY">HOSTED&nbsp;&nbsp;BY</div>
              <div className="FABIANO-VALLI-LEVI">
                FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp;&nbsp;&nbsp;co
              </div>
            </>
          )}
        </div>
        <div
          className="overlap-14"
          style={{
            height: screenWidth < 1280 ? "112px" : screenWidth >= 1280 ? "57px" : undefined,
            left: screenWidth < 1280 ? "18px" : screenWidth >= 1280 ? "111px" : undefined,
            top: screenWidth < 1280 ? "3868px" : screenWidth >= 1280 ? "924px" : undefined,
            width: screenWidth < 1280 ? "358px" : screenWidth >= 1280 ? "1060px" : undefined,
          }}
        >
          {screenWidth < 1280 && (
            <>
              <div className="group-103">
                <div className="overlap-15">
                  <div className="group-102">
                    <div className="overlap-group-10">
                      <div className="rectangle-39" />
                      <div className="text-wrapper-39">Gold</div>
                    </div>
                  </div>
                  <div className="GOLD-sigil">
                    <div className="overlap-15">
                      <img className="group-104" alt="Group" src="/img/group-48099424.png" />
                      <img className="vector-19" alt="Vector" src="/img/vector-205.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-40">2,500€</div>
            </>
          )}

          {screenWidth >= 1280 && <div className="REACH-THE">REACH&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>}
        </div>
        <div
          className="overlap-16"
          style={{
            height: screenWidth < 1280 ? "109px" : screenWidth >= 1280 ? "91px" : undefined,
            left: screenWidth < 1280 ? "18px" : screenWidth >= 1280 ? "99px" : undefined,
            top: screenWidth < 1280 ? "3993px" : screenWidth >= 1280 ? "1639px" : undefined,
            width: screenWidth < 1280 ? "354px" : screenWidth >= 1280 ? "1082px" : undefined,
          }}
        >
          {screenWidth < 1280 && (
            <>
              <div className="group-105">
                <div className="overlap-17">
                  <div className="group-102">
                    <div className="overlap-group-11">
                      <div className="rectangle-40" />
                      <div className="text-wrapper-41">Platinum</div>
                    </div>
                  </div>
                  <img className="img-2" alt="Plat sigil" src="/img/plat-sigil.png" />
                </div>
              </div>
              <div className="text-wrapper-42">5,000€</div>
            </>
          )}

          {screenWidth >= 1280 && (
            <div className="group-106">
              <div className="text-wrapper-43">SOME OF THE</div>
              <div className="group-107">
                <div className="overlap-group-12">
                  <div className="CHOSEN-ATTENDEES">CHOSEN&nbsp;&nbsp;ATTENDEES</div>
                  <div className="rectangle-41" />
                  <div className="rectangle-42" />
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="group-108"
          style={{
            height: screenWidth >= 1280 ? "91px" : screenWidth < 1280 ? "112px" : undefined,
            left: screenWidth >= 1280 ? "111px" : screenWidth < 1280 ? "18px" : undefined,
            top: screenWidth >= 1280 ? "3442px" : screenWidth < 1280 ? "4118px" : undefined,
            width: screenWidth >= 1280 ? "1060px" : screenWidth < 1280 ? "358px" : undefined,
          }}
        >
          {screenWidth >= 1280 && (
            <>
              <div className="text-wrapper-44">OFFERED</div>
              <div className="group-109">
                <div className="SPONSORSHIP-PACKAGES">SPONSORSHIP&nbsp;&nbsp;PACKAGES</div>
              </div>
            </>
          )}

          {screenWidth < 1280 && (
            <div className="overlap-15">
              <div className="group-105">
                <div className="group-110">
                  <div className="overlap-group-13">
                    <div className="rectangle-43" />
                    <div className="rectangle-43" />
                    <div className="rectangle-44" />
                    <div className="text-wrapper-45">Diamond</div>
                    <div className="text-wrapper-46">10,000€</div>
                  </div>
                </div>
              </div>
              <img className="DIAMOND-sigil" alt="Diamond sigil" src="/img/diamond-sigil.png" />
            </div>
          )}
        </div>
        <div
          className="group-111"
          style={{
            height: screenWidth >= 1280 ? "34px" : screenWidth < 1280 ? "109px" : undefined,
            left: screenWidth >= 1280 ? "207px" : screenWidth < 1280 ? "18px" : undefined,
            top: screenWidth >= 1280 ? "10290px" : screenWidth < 1280 ? "3618px" : undefined,
            width: screenWidth >= 1280 ? "866px" : screenWidth < 1280 ? "358px" : undefined,
          }}
        >
          <div
            className="group-836"
            style={{
              backgroundImage: screenWidth < 1280 ? "url(/img/image-427.png)" : undefined,
              backgroundSize: screenWidth < 1280 ? "100% 100%" : undefined,
              height: screenWidth >= 1280 ? "34px" : screenWidth < 1280 ? "109px" : undefined,
              position: screenWidth < 1280 ? "relative" : undefined,
              width: screenWidth < 1280 ? "354px" : undefined,
            }}
          >
            {screenWidth >= 1280 && (
              <div className="group-112">
                <div className="PRESENTED-BY-3">PRESENTED&nbsp;&nbsp;BY</div>
              </div>
            )}

            {screenWidth < 1280 && (
              <>
                <img className="image-3" alt="Image" src="/img/image-429.png" />
                <div className="rectangle-39" />
                <img className="img-2" alt="Bronze sigil" src="/img/bronze-sigil.png" />
                <div className="text-wrapper-47">500€</div>
                <div className="text-wrapper-48">Bronze</div>
              </>
            )}
          </div>
        </div>
        {screenWidth >= 1280 && <img className="group-113" alt="Group" src="/img/group-48098605-3.png" />}

        <div
          className="group-114"
          style={{
            height: screenWidth >= 1280 ? "200px" : screenWidth < 1280 ? "183px" : undefined,
            left: screenWidth >= 1280 ? "111px" : screenWidth < 1280 ? "0" : undefined,
            top: screenWidth >= 1280 ? "4017px" : screenWidth < 1280 ? "6698px" : undefined,
            width: screenWidth >= 1280 ? "1058px" : screenWidth < 1280 ? "386px" : undefined,
          }}
        >
          {screenWidth >= 1280 && (
            <div className="group-115">
              <div className="group-116">
                <div className="overlap-group-14">
                  <div className="rectangle-45" />
                  <div className="group-117">
                    <div className="group-118">
                      <div className="group-119">
                        <div className="group-120">
                          <img className="polygon" alt="Polygon" src="/img/polygon-7.svg" />
                          <img className="polygon-2" alt="Polygon" src="/img/polygon-8.svg" />
                          <img className="polygon-3" alt="Polygon" src="/img/polygon-9.svg" />
                          <img className="polygon-4" alt="Polygon" src="/img/polygon-10.svg" />
                          <img className="polygon-5" alt="Polygon" src="/img/polygon-11.svg" />
                        </div>
                        <div className="text-wrapper-49">Continues Advertisment</div>
                        <p className="text-wrapper-50">Displayed on highly visible life size digital Panels</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {screenWidth < 1280 && (
            <>
              <img className="mask-group-8" alt="Mask group" src="/img/mask-group-4-2x.png" />
              <div className="group-121">
                <div className="overlap-18">
                  <div className="rectangle-46" />
                  <div className="group-122">
                    <img className="polygon" alt="Polygon" src="/img/polygon-15-20.svg" />
                    <img className="polygon-2" alt="Polygon" src="/img/polygon-16-21.svg" />
                  </div>
                  <div className="group-123">
                    <div className="text-wrapper-51">Equity Shares</div>
                    <p className="text-wrapper-52">
                      Equity in Sokail, our multi-conglomerate international agency aggregate
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="group-124"
          style={{
            height: screenWidth < 1280 ? "104px" : screenWidth >= 1280 ? "222px" : undefined,
            left: screenWidth < 1280 ? "18px" : screenWidth >= 1280 ? "111px" : undefined,
            top: screenWidth < 1280 ? "4292px" : screenWidth >= 1280 ? "4497px" : undefined,
            width: screenWidth < 1280 ? "354px" : screenWidth >= 1280 ? "1058px" : undefined,
          }}
        >
          {screenWidth < 1280 && (
            <>
              <div className="rectangle-47" />
              <div className="rectangle-48" />
              <img
                className="AAA-logo-test-4"
                alt="Aaa logo test"
                src="/img/aaa-logo-test-4-removebg-preview-1-2.png"
              />
            </>
          )}

          {screenWidth >= 1280 && (
            <div className="group-125">
              <div className="group-126">
                <div className="group-125">
                  <div className="overlap-group-15">
                    <div className="rectangle-49" />
                    <div className="group-127">
                      <div className="group-128">
                        <img className="polygon" alt="Polygon" src="/img/polygon-12.svg" />
                        <img className="polygon-2" alt="Polygon" src="/img/polygon-13.svg" />
                        <img className="polygon-3" alt="Polygon" src="/img/polygon-14.svg" />
                        <img className="polygon-4" alt="Polygon" src="/img/polygon-15.svg" />
                        <img className="polygon-5" alt="Polygon" src="/img/polygon-16.svg" />
                      </div>
                      <div className="text-wrapper-49">Rarely stocked&nbsp;&nbsp;VIP&nbsp;&nbsp;Gift Boxes</div>
                      <p className="text-wrapper-53">
                        Distribution of some of the rarest internationally sourced specialty products
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="group-129"
          style={{
            height: screenWidth >= 1280 ? "222px" : screenWidth < 1280 ? "90px" : undefined,
            left: screenWidth >= 1280 ? "111px" : screenWidth < 1280 ? "12px" : undefined,
            top: screenWidth >= 1280 ? "4759px" : screenWidth < 1280 ? "1020px" : undefined,
            width: screenWidth >= 1280 ? "1058px" : screenWidth < 1280 ? "368px" : undefined,
          }}
        >
          {screenWidth >= 1280 && (
            <div className="group-125">
              <div className="overlap-group-15">
                <div className="rectangle-49" />
                <div className="group-127">
                  <div className="group-130">
                    <img className="polygon" alt="Polygon" src="/img/polygon-13-1.svg" />
                    <img className="polygon-2" alt="Polygon" src="/img/polygon-14-1.svg" />
                    <img className="polygon-3" alt="Polygon" src="/img/polygon-15-1.svg" />
                    <img className="polygon-4" alt="Polygon" src="/img/polygon-16-1.svg" />
                  </div>
                  <div className="text-wrapper-49">Private Product Presentation</div>
                  <p className="text-wrapper-54">
                    We will personally present and demonstrate the value of your product to our most influential guests
                  </p>
                </div>
              </div>
            </div>
          )}

          {screenWidth < 1280 && (
            <>
              <div className="flexcontainer-2">
                <p className="span-wrapper">
                  <span className="text-wrapper-55">
                    REACH&nbsp;&nbsp;
                    <br />
                  </span>
                </p>
                <p className="span-wrapper">
                  <span className="text-wrapper-55">THE&nbsp;&nbsp; UNTOUCHABLE</span>
                </p>
              </div>
              <div className="rectangle-50" />
            </>
          )}
        </div>
        {screenWidth >= 1280 && (
          <>
            <div className="group-131">
              <div className="group-115">
                <div className="group-116">
                  <div className="group-115">
                    <div className="overlap-group-16">
                      <div className="rectangle-45" />
                      <div className="group-132">
                        <div className="group-130">
                          <img className="polygon" alt="Polygon" src="/img/polygon-13-2.svg" />
                          <img className="polygon-2" alt="Polygon" src="/img/polygon-14-2.svg" />
                          <img className="polygon-3" alt="Polygon" src="/img/polygon-15-2.svg" />
                          <img className="polygon-4" alt="Polygon" src="/img/polygon-16-2.svg" />
                        </div>
                        <div className="text-wrapper-49">Company&nbsp;&nbsp;Engravings</div>
                        <p className="text-wrapper-56">Engravings on our glasses, tables, and gift boxes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-133">
              <div className="group-115">
                <div className="group-116">
                  <div className="group-115">
                    <div className="overlap-group-16">
                      <div className="rectangle-45" />
                      <div className="group-132">
                        <div className="group-130">
                          <img className="polygon" alt="Polygon" src="/img/polygon-13-3.svg" />
                          <img className="polygon-2" alt="Polygon" src="/img/polygon-14-3.svg" />
                          <img className="polygon-3" alt="Polygon" src="/img/polygon-15-3.svg" />
                          <img className="polygon-4" alt="Polygon" src="/img/polygon-16-3.svg" />
                        </div>
                        <div className="text-wrapper-49">BRING +5</div>
                        <p className="text-wrapper-56">Just make sure they’re cool</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-134">
              <div className="group-125">
                <div className="overlap-group-17">
                  <div className="rectangle-49" />
                  <div className="group-135">
                    <div className="group-136">
                      <div className="group-137">
                        <div className="group-136">
                          <div className="group-138">
                            <div className="group-139">
                              <img className="polygon" alt="Polygon" src="/img/polygon-14-4.svg" />
                              <img className="polygon-2" alt="Polygon" src="/img/polygon-15-4.svg" />
                              <img className="polygon-3" alt="Polygon" src="/img/polygon-16-4.svg" />
                            </div>
                            <div className="text-wrapper-49">Exclusive Services</div>
                            <p className="text-wrapper-57">
                              Above listed exclusive services, making your stay in Milan a luxurious experience
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-140">
              <div className="group-125">
                <div className="group-126">
                  <div className="overlap-group-18">
                    <div className="rectangle-49" />
                    <div className="group-135">
                      <div className="group-138">
                        <div className="group-139">
                          <div className="group-141">
                            <img className="polygon" alt="Polygon" src="/img/polygon-14-5.svg" />
                            <img className="polygon-2" alt="Polygon" src="/img/polygon-15-5.svg" />
                            <img className="polygon-3" alt="Polygon" src="/img/polygon-16-5.svg" />
                          </div>
                        </div>
                        <div className="text-wrapper-49">Advertisement &amp; Promotion</div>
                        <p className="professional">
                          Professional advertisement production promoted by our PR &amp; Influencers with a combined
                          reach of 100M+
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-142">
              <div className="group-115">
                <div className="overlap-group-19">
                  <div className="rectangle-45" />
                  <div className="group-117">
                    <div className="group-118">
                      <div className="group-119">
                        <div className="group-130">
                          <img className="polygon" alt="Polygon" src="/img/polygon-13-4.svg" />
                          <img className="polygon-2" alt="Polygon" src="/img/polygon-14-6.svg" />
                          <img className="polygon-3" alt="Polygon" src="/img/polygon-15-6.svg" />
                          <img className="polygon-4" alt="Polygon" src="/img/polygon-16-6.svg" />
                        </div>
                        <p className="text-wrapper-56">Private concierge for VIP access anywhere in Milan</p>
                        <div className="text-wrapper-49">Private VIP Concierge</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-143">
              <div className="group-115">
                <div className="group-116">
                  <div className="overlap-group-14">
                    <div className="rectangle-45" />
                    <div className="group-117">
                      <div className="group-118">
                        <div className="group-144">
                          <div className="group-118">
                            <div className="group-119">
                              <div className="group-139">
                                <img className="polygon" alt="Polygon" src="/img/polygon-14-7.svg" />
                                <img className="polygon-2" alt="Polygon" src="/img/polygon-15-7.svg" />
                                <img className="polygon-3" alt="Polygon" src="/img/polygon-16-7.svg" />
                              </div>
                              <div className="text-wrapper-49">BRING +10</div>
                              <p className="text-wrapper-56">Just make sure they’re cool</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-145">
              <div className="overlap-group-14">
                <div className="rectangle-45" />
                <div className="group-117">
                  <div className="group-119">
                    <div className="group-120">
                      <img className="polygon" alt="Polygon" src="/img/polygon-12-1.svg" />
                      <img className="polygon-2" alt="Polygon" src="/img/polygon-13-5.svg" />
                      <img className="polygon-3" alt="Polygon" src="/img/polygon-14-8.svg" />
                      <img className="polygon-4" alt="Polygon" src="/img/polygon-15-8.svg" />
                      <img className="polygon-5" alt="Polygon" src="/img/polygon-16-8.svg" />
                    </div>
                    <div className="text-wrapper-49">Bring +1</div>
                    <p className="text-wrapper-58">Check with us first, 99% approved</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-146">
              <div className="overlap-group-14">
                <div className="rectangle-45" />
                <div className="group-117">
                  <div className="group-147">
                    <div className="group-148">
                      <img className="polygon" alt="Polygon" src="/img/polygon-15-9.svg" />
                      <img className="polygon-2" alt="Polygon" src="/img/polygon-16-9.svg" />
                    </div>
                    <div className="group-149">
                      <div className="text-wrapper-59">Equity Shares</div>
                      <p className="text-wrapper-60">
                        Equity in Sokail, our multi-conglomerate international agency aggregate
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-150">
              <div className="group-125">
                <div className="overlap-group-17">
                  <div className="rectangle-49" />
                  <div className="group-135">
                    <div className="group-136">
                      <div className="group-151">
                        <div className="group-139">
                          <img className="polygon" alt="Polygon" src="/img/polygon-14-9.svg" />
                          <img className="polygon-2" alt="Polygon" src="/img/polygon-15-10.svg" />
                          <img className="polygon-3" alt="Polygon" src="/img/polygon-16-10.svg" />
                        </div>
                        <div className="group-152">
                          <div className="text-wrapper-59">Professional&nbsp;&nbsp;Marketing</div>
                          <p className="text-wrapper-61">
                            Ongoing best in class marketing services provided personally by our company
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-153">
              <div className="overlap-19">
                <div className="rectangle-51" />
                <div className="indefinite-VIP-more">Indefinite VIP &amp; More</div>
                <p className="a-unique">
                  A unique identification granting indefinite VIP status
                  <br />
                  <br />
                  Sponsorship status within our organization <br />
                  <br />
                  VIP status at all open events, &amp; access
                  <br />
                  to some fully private unadvertised events
                  <br />
                  <br />
                  Access to our private network, which can reach the majority of the world
                </p>
                <div className="group-154">
                  <div className="overlap-group-20">
                    <img className="mask-group-9" alt="Mask group" src="/img/mask-group-3-2.png" />
                    <img className="polygon-6" alt="Polygon" src="/img/polygon-16-11.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-20">
              <div className="group-155">
                <div className="group-156">
                  <div className="group-157">
                    <div className="text-wrapper-62">10,000€</div>
                    <div className="text-wrapper-63">25,000€</div>
                    <div className="text-wrapper-64">50,000€</div>
                    <div className="text-wrapper-65">100,000€</div>
                    <div className="text-wrapper-66">5,000€</div>
                    <div className="rectangle-52" />
                    <div className="rectangle-53" />
                    <div className="rectangle-54" />
                    <div className="rectangle-55" />
                    <div className="rectangle-56" />
                  </div>
                  <div className="text-wrapper-67">BRONZE</div>
                  <div className="text-wrapper-68">SILVER</div>
                  <div className="text-wrapper-69">GOLD</div>
                  <div className="text-wrapper-70">PLATINUM</div>
                  <div className="text-wrapper-71">DIAMOND</div>
                </div>
              </div>
              <div className="group-158">
                <div className="rectangle-57" />
                <div className="rectangle-58" />
                <div className="rectangle-59" />
                <img
                  className="AAA-logo-test-5"
                  alt="Aaa logo test"
                  src="/img/aaa-logo-test-4-removebg-preview-1-2.png"
                />
              </div>
            </div>
            <div className="group-159">
              <div className="text-wrapper-72">EXCLUSIVE SERVICES</div>
              <div className="group-109">
                <div className="FOR-OUR-TOP-SPONSORS">FOR&nbsp;&nbsp;OUR&nbsp;&nbsp;TOP&nbsp;&nbsp;SPONSORS</div>
              </div>
            </div>
            <div className="rectangle-60" />
            <div className="text-wrapper-73">For more Info, contact</div>
            <div className="f-sokail-ai-j-sokail-3">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
            <a
              className="group-160"
              href="https://api.whatsapp.com/send/?phone=19548823115"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-21">
                <div className="rectangle-61" />
                <img className="group-161" alt="Group" src="/img/group-48098911.png" />
              </div>
            </a>
            <a
              className="group-162"
              href="https://www.instagram.com/_pisetzky"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-21">
                <div className="rectangle-61" />
                <SocialIcons color="negative" platform="instagram" />
              </div>
            </a>
            <a
              className="group-163"
              href="https://api.whatsapp.com/send/?phone=19548823115"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="group-164">
                <div className="overlap-group-21">
                  <div className="rectangle-61" />
                  <SocialIcons color="negative" platform="messenger" />
                </div>
              </div>
            </a>
            <a className="group-165" href="https://t.me/leodavincian" rel="noopener noreferrer" target="_blank">
              <div className="overlap-21">
                <div className="rectangle-61" />
                <SocialIcons color="negative" platform="telegram" />
              </div>
            </a>
            <a className="group-166" href="https://x.com/pisetzkymusic" rel="noopener noreferrer" target="_blank">
              <div className="overlap-21">
                <div className="rectangle-61" />
                <ConcreteComponentNode color="negative" platform="x-twitter" />
              </div>
            </a>
            <div className="group-167">
              <div className="group-168">
                <div className="group-169">
                  <div className="group-170">
                    <p className="text-wrapper-74">VIP TREATMENT AND EXCLUSIVE ACCESS</p>
                    <p className="EXCLUSIVE-ACCESS-AND">
                      EXCLUSIVE&nbsp;&nbsp;ACCESS AND VIP TREATMENT IN&nbsp;&nbsp;MILAN’S&nbsp;&nbsp;FINEST
                    </p>
                  </div>
                  <div className="group-171">
                    <div className="text-wrapper-75">PRIVATE TRANSPORTATION ARRANGEMENTS</div>
                    <p className="COMPLIMENTARY-LUXURY">
                      {" "}
                      COMPLIMENTARY&nbsp;&nbsp;LUXURY&nbsp;&nbsp;TRANSPORT SERVICES&nbsp;&nbsp;BEGINNING AT THE AIRPORT
                    </p>
                  </div>
                  <div className="group-172">
                    <p className="text-wrapper-76">HELICOPTER LANDING ADJACENT TO THE VENUE</p>
                    <p className="text-wrapper-77">HELICOPTER ARRIVALS ARE AVAILABLE UPON REQUEST</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-173">
              <div className="THE-TOP-OF-THE">THE TOP OF THE&nbsp;&nbsp;FASHION&nbsp;&nbsp;INDUSTRY</div>
              <div className="text-wrapper-78">INTERNATIONAL ROYALTY</div>
              <div className="text-wrapper-79">CELEBRITY MUSICIANS</div>
              <div className="text-wrapper-80">EXTRAORDINARILY SUCCESSFUL BUSINESS MAGNATES</div>
              <p className="SOME-OF-THE-MOST">
                SOME&nbsp;&nbsp;OF THE MOST INFLUENTIAL MEMBERS&nbsp;&nbsp;OF KEY COUNTRIES
              </p>
              <p className="text-wrapper-81">MASTERS OF MEDIA, MARKETING, SALES, AND PUBLIC RELATIONS</p>
            </div>
            <div className="group-174">
              <div className="overlap-22">
                <div className="group-175">
                  <div className="overlap-group-22">
                    <img className="image-4" alt="Image" src="/img/image-372-2.png" />
                    <div className="rectangle-62" />
                    <div className="rectangle-62" />
                    <div className="rectangle-63" />
                    <div className="rectangle-64" />
                    <div className="rectangle-65" />
                    <div className="rectangle-66" />
                    <div className="rectangle-67" />
                    <img className="rectangle-68" alt="Rectangle" src="/img/rectangle-2836.svg" />
                  </div>
                </div>
                <div className="rectangle-69" />
                <div className="text-wrapper-82">Secret</div>
                <img className="location-2" alt="Location" src="/img/location.svg" />
                <p className="text-wrapper-83">maximum 150,000€ in sponsorship for the event</p>
                <p className="element-accounted-for"> 30,000€ accounted for as of July 4th</p>
                <div className="flexcontainer-3">
                  <p className="text-2">
                    <span className="text-wrapper-55">
                      any commitment before July 10th <br />
                    </span>
                  </p>
                  <p className="text-2">
                    <span className="text-wrapper-55">will have a 20% discount</span>
                  </p>
                </div>
                <p className="text-wrapper-84">
                  two slots remaining for VIP sponsorship with additional benefits and pricing
                </p>
                <div className="group-176" />
              </div>
              <div className="group-177">
                <div className="group-178">
                  <div className="group-179">
                    <div className="rectangle-70" />
                    <div className="rectangle-71" />
                    <img
                      className="AAA-logo-test-6"
                      alt="Aaa logo test"
                      src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
                    />
                  </div>
                </div>
              </div>
              <a
                className="group-180"
                href="https://api.whatsapp.com/send/?phone=19548823115"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="overlap-23">
                  <div className="rectangle-72" />
                  <div className="text-wrapper-85">BECOME A SPONSOR</div>
                </div>
              </a>
            </div>
            <a className="group-181" href="https://pisetzky.vip" rel="noopener noreferrer" target="_blank">
              <div className="overlap-23">
                <div className="rectangle-72" />
                <div className="text-wrapper-86">SEE THE EVENT DETAILS</div>
              </div>
            </a>
          </>
        )}

        {screenWidth < 1280 && (
          <>
            <div className="text-wrapper-87">EXCLUSIVE</div>
            <div className="group-182">
              <div className="ATTENDEES"> ATTENDEES</div>
              <div className="THE-TOP-OF-THE-2">
                THE TOP OF THE&nbsp;&nbsp;FASHION
                <br />
                &nbsp;&nbsp;INDUSTRY
              </div>
              <div className="text-wrapper-88">INTERNATIONAL ROYALTY</div>
              <div className="text-wrapper-89">CELEBRITY MUSICIANS</div>
              <div className="EXTRAORDINARILY">
                EXTRAORDINARILY SUCCESSFUL
                <br /> BUSINESS MAGNETES
              </div>
              <p className="SOME-OF-THE-MOST-2">
                SOME&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;MOST INFLUENTIAL
                <br /> MEMBERS&nbsp;&nbsp;OF KEY COUNTRIES
              </p>
              <p className="MASTERS-OF-MEDIA">
                MASTERS OF MEDIA, MARKETING,
                <br /> SALES, AND PUBLIC RELATIONS
              </p>
              <div className="text-wrapper-90">SOME OF THE</div>
            </div>
            <div className="text-wrapper-91">SERVICES</div>
            <div className="FOR-TOP-SPONSORS">FOR&nbsp;&nbsp;TOP&nbsp;&nbsp;SPONSORS</div>
            <div className="group-183">
              <div className="group-184">
                <div className="group-185">
                  <p className="text-wrapper-92">COMPLIMENTARY BOOKINGS AT THE TOP HOTELS OF THE AREA</p>
                  <div className="text-wrapper-93">
                    {" "}
                    VIP TREATMENT <br />
                    AND&nbsp;&nbsp;EXCLUSIVE&nbsp;&nbsp;ACCESS
                  </div>
                </div>
              </div>
            </div>
            <img className="mask-group-10" alt="Mask group" src="/img/mask-group-9.png" />
            <img className="mask-group-11" alt="Mask group" src="/img/mask-group-9.png" />
            <img className="mask-group-12" alt="Mask group" src="/img/mask-group-9.png" />
            <div className="group-186">
              <div className="text-wrapper-93">
                ARRANGEMENTS OF
                <br /> PRIVATE&nbsp;&nbsp;TRANSPORTATION
              </div>
              <p className="COMPLIMENTARY-LUXURY-2">
                &nbsp;&nbsp;COMPLIMENTARY LUXURY TRANSPORT SERVICES BEGINNING AT THE AIRPORT
              </p>
            </div>
            <div className="group-187">
              <p className="HELICOPTER-LANDING">HELICOPTER&nbsp;&nbsp;LANDING ADJACENT TO THE VENUE</p>
              <p className="HELICOPTER-ARRIVALS">&nbsp;&nbsp;HELICOPTER ARRIVALS ARE AVAILABLE UPON REQUEST</p>
            </div>
            <div className="rectangle-73" />
            <a
              className="group-188"
              href="https://api.whatsapp.com/send/?phone=19548823115"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-23">
                <div className="rectangle-72" />
                <div className="text-wrapper-86">BECOME A SPONSOR</div>
              </div>
            </a>
            <div className="group-189">
              <p className="text-wrapper-94">
                two slots remaining for VIP sponsorship with additional benefits and pricing
              </p>
              <p className="text-wrapper-95">maximum 150,000€ in sponsorship for the event</p>
              <p className="element-accounted-for-2"> 30,000€ accounted for as of July 4th</p>
              <div className="flexcontainer-4">
                <p className="text-3">
                  <span className="text-wrapper-55">
                    any commitment before July 10th <br />
                  </span>
                </p>
                <p className="text-3">
                  <span className="text-wrapper-55">will have a 20% discount</span>
                </p>
              </div>
            </div>
            <div className="group-190">
              <div className="overlap-24">
                <img className="vector-20" alt="Vector" src="/img/vector-233-1.svg" />
                <img className="vector-21" alt="Vector" src="/img/vector-244-1-3.svg" />
                <img className="group-191" alt="Group" src="/img/group-48098648-1.png" />
              </div>
              <img className="vector-22" alt="Vector" src="/img/vector-231-1-2.svg" />
              <img className="vector-23" alt="Vector" src="/img/vector-232-1.svg" />
              <img className="vector-24" alt="Vector" src="/img/vector-235-1-4.svg" />
              <img className="vector-25" alt="Vector" src="/img/vector-236-1-2.svg" />
            </div>
            <div className="rectangle-74" />
            <a className="group-192" href="https://pisetzky.vip" rel="noopener noreferrer" target="_blank">
              <div className="overlap-23">
                <div className="rectangle-72" />
                <div className="text-wrapper-86">SEE THE EVENT DETAILS</div>
              </div>
            </a>
            <div className="group-193">
              <div className="group-194">
                <div className="overlap-group-23">
                  <div className="rectangle-46" />
                  <div className="group-195">
                    <div className="group-196">
                      <img className="polygon" alt="Polygon" src="/img/polygon-7-1.svg" />
                      <img className="polygon-2" alt="Polygon" src="/img/polygon-8-1.svg" />
                      <img className="polygon-3" alt="Polygon" src="/img/polygon-9-1.svg" />
                      <img className="polygon-4" alt="Polygon" src="/img/polygon-10-1.svg" />
                      <img className="polygon-5" alt="Polygon" src="/img/polygon-11-1.svg" />
                    </div>
                    <div className="text-wrapper-96">Continues Advertisment</div>
                    <p className="text-wrapper-97">Displayed on highly visible life size digital Panels</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-197">
              <div className="group-198">
                <div className="overlap-group-24">
                  <div className="rectangle-75" />
                  <div className="group-199">
                    <img className="polygon" alt="Polygon" src="/img/polygon-12-2.svg" />
                    <img className="polygon-2" alt="Polygon" src="/img/polygon-13-6.svg" />
                    <img className="polygon-3" alt="Polygon" src="/img/polygon-14-10.svg" />
                    <img className="polygon-4" alt="Polygon" src="/img/polygon-15-11.svg" />
                    <img className="polygon-5" alt="Polygon" src="/img/polygon-16-12.svg" />
                  </div>
                  <div className="group-200">
                    <div className="rarely-stocked-VIP">Rarely stocked VIP&nbsp;&nbsp;Gift Boxes</div>
                    <p className="text-wrapper-98">
                      Distribution of some of the rarest internationally sourced specialty products
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-201">
              <div className="group-202">
                <div className="overlap-group-25">
                  <div className="rectangle-75" />
                  <div className="group-203">
                    <img className="polygon" alt="Polygon" src="/img/polygon-13-7.svg" />
                    <img className="polygon-2" alt="Polygon" src="/img/polygon-14-11.svg" />
                    <img className="polygon-3" alt="Polygon" src="/img/polygon-15-12.svg" />
                    <img className="polygon-4" alt="Polygon" src="/img/polygon-16-13.svg" />
                  </div>
                  <div className="text-wrapper-99">Private product presentation</div>
                  <p className="text-wrapper-100">
                    We will personally present and demonstrate the value of your product to our most influential guests
                  </p>
                </div>
              </div>
            </div>
            <div className="group-204">
              <div className="overlap-18">
                <div className="rectangle-46" />
                <div className="group-203">
                  <img className="polygon" alt="Polygon" src="/img/polygon-13-8.svg" />
                  <img className="polygon-2" alt="Polygon" src="/img/polygon-14-12.svg" />
                  <img className="polygon-3" alt="Polygon" src="/img/polygon-15-13.svg" />
                  <img className="polygon-4" alt="Polygon" src="/img/polygon-16-14.svg" />
                </div>
                <div className="group-205">
                  <div className="company-engravings">Company&nbsp;&nbsp;Engravings</div>
                  <p className="text-wrapper-101">Engravings on our glasses, tables, and gift boxes</p>
                </div>
              </div>
            </div>
            <div className="group-206">
              <div className="overlap-25">
                <div className="rectangle-76" />
                <div className="group-203">
                  <img className="polygon" alt="Polygon" src="/img/polygon-13-9.svg" />
                  <img className="polygon-2" alt="Polygon" src="/img/polygon-14-13.svg" />
                  <img className="polygon-3" alt="Polygon" src="/img/polygon-15-14.svg" />
                  <img className="polygon-4" alt="Polygon" src="/img/polygon-16-15.svg" />
                </div>
                <div className="group-207">
                  <div className="text-wrapper-102">BRING +5</div>
                  <p className="text-wrapper-101">Just make sure they’re cool</p>
                </div>
              </div>
            </div>
            <div className="group-208">
              <div className="overlap-18">
                <div className="rectangle-46" />
                <div className="group-209">
                  <img className="polygon" alt="Polygon" src="/img/polygon-14-14.svg" />
                  <img className="polygon-2" alt="Polygon" src="/img/polygon-15-15.svg" />
                  <img className="polygon-3" alt="Polygon" src="/img/polygon-16-16.svg" />
                </div>
                <div className="group-210">
                  <div className="group-211">
                    <div className="text-wrapper-103">Exclusive Services</div>
                    <p className="text-wrapper-104">
                      Above listed exclusive services, making your stay in Milan a luxurious experience
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-212">
              <div className="overlap-26">
                <div className="rectangle-75" />
                <div className="group-209">
                  <div className="group-141">
                    <img className="polygon" alt="Polygon" src="/img/polygon-14-15.svg" />
                    <img className="polygon-2" alt="Polygon" src="/img/polygon-15-16.svg" />
                    <img className="polygon-3" alt="Polygon" src="/img/polygon-16-17.svg" />
                  </div>
                </div>
                <div className="group-213">
                  <div className="advertisement">Advertisement &amp; Promotion</div>
                  <p className="text-wrapper-101">
                    Professional advertisement production promoted by our PR &amp; Influencers with a combined reach of
                    100M+
                  </p>
                </div>
              </div>
            </div>
            <div className="group-214">
              <div className="overlap-18">
                <div className="rectangle-46" />
                <div className="group-203">
                  <img className="polygon" alt="Polygon" src="/img/polygon-13-10.svg" />
                  <img className="polygon-2" alt="Polygon" src="/img/polygon-14-16.svg" />
                  <img className="polygon-3" alt="Polygon" src="/img/polygon-15-17.svg" />
                  <img className="polygon-4" alt="Polygon" src="/img/polygon-16-18.svg" />
                </div>
                <div className="group-205">
                  <p className="text-wrapper-101">Private concierge for VIP access anywhere in Milan</p>
                  <div className="text-wrapper-105">Private VIP concierge</div>
                </div>
              </div>
            </div>
            <div className="group-215">
              <div className="overlap-25">
                <div className="rectangle-76" />
                <div className="group-209">
                  <img className="polygon" alt="Polygon" src="/img/polygon-14-17.svg" />
                  <img className="polygon-2" alt="Polygon" src="/img/polygon-15-18.svg" />
                  <img className="polygon-3" alt="Polygon" src="/img/polygon-16-19.svg" />
                </div>
                <div className="group-216">
                  <div className="group-217">
                    <div className="text-wrapper-105">BRING +10</div>
                    <p className="text-wrapper-101">Just make sure they’re cool</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-218">
              <div className="group-219">
                <div className="overlap-group-26">
                  <div className="rectangle-77" />
                  <div className="group-199">
                    <img className="polygon" alt="Polygon" src="/img/polygon-12-3.svg" />
                    <img className="polygon-2" alt="Polygon" src="/img/polygon-13-11.svg" />
                    <img className="polygon-3" alt="Polygon" src="/img/polygon-14-18.svg" />
                    <img className="polygon-4" alt="Polygon" src="/img/polygon-15-19.svg" />
                    <img className="polygon-5" alt="Polygon" src="/img/polygon-16-20.svg" />
                  </div>
                  <div className="group-220">
                    <div className="text-wrapper-106">Bring +1</div>
                    <p className="text-wrapper-107">Check with us first, 99% approved</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-27">
              <div className="group-221">
                <div className="rectangle-78" />
                <div className="rectangle-79" />
                <div className="group-222">
                  <div className="overlap-group-8">
                    <img className="vector-13" alt="Vector" src="/img/vector-233-4.svg" />
                    <img className="vector-14" alt="Vector" src="/img/vector-244-1-2.svg" />
                    <img className="group-100" alt="Group" src="/img/group-48098648-3.png" />
                  </div>
                  <img className="vector-15" alt="Vector" src="/img/vector-231-1-4.svg" />
                  <img className="vector-16" alt="Vector" src="/img/vector-232-1-4.svg" />
                  <img className="vector-17" alt="Vector" src="/img/vector-235-1-2.svg" />
                  <img className="vector-18" alt="Vector" src="/img/vector-236-1.svg" />
                </div>
                <div className="FULL-MOON-AWAKENING-2">FULL&nbsp;&nbsp;MOON&nbsp;&nbsp;AWAKENING</div>
                <div className="SPONSOR-OUR-2">SPONSOR&nbsp;&nbsp;OUR</div>
                <div className="group-223">
                  <div className="group-224">
                    <div className="FABIANO-VALLI-3">FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;PISETZKY</div>
                    <div className="co-2">&amp;&nbsp;&nbsp;co</div>
                    <div className="AN-EVENT-HOSTED-BY">AN&nbsp;&nbsp;EVENT HOSTED&nbsp;&nbsp;BY</div>
                    <div className="rectangle-80" />
                  </div>
                </div>
              </div>
              <div className="text-wrapper-108">July 20th</div>
            </div>
            <div className="group-225">
              <div className="overlap-18">
                <div className="rectangle-46" />
                <div className="group-209">
                  <img className="polygon" alt="Polygon" src="/img/polygon-14-19.svg" />
                  <img className="polygon-2" alt="Polygon" src="/img/polygon-15-21.svg" />
                  <img className="polygon-3" alt="Polygon" src="/img/polygon-16-22.svg" />
                </div>
                <div className="group-226">
                  <div className="professional-2">Professional&nbsp;&nbsp;Marketing</div>
                  <p className="text-wrapper-109">
                    Ongoing best in class marketing services provided personally by our company
                  </p>
                </div>
              </div>
            </div>
            <div className="group-227">
              <div className="overlap-28">
                <div className="rectangle-81" />
                <div className="indefinite-VIP-more-2">Indefinite VIP &amp; More</div>
                <p className="a-unique-2">
                  A unique identification granting indefinite VIP status
                  <br />
                  <br />
                  Sponsorship status within our organization <br />
                  <br />
                  VIP status at all open events, &amp; access
                  <br />
                  to some fully private unadvertised events
                  <br />
                  <br />
                  Access to our private network, which can reach the majority of the world
                </p>
                <img className="mask-group-13" alt="Mask group" src="/img/mask-group-8.png" />
                <img className="polygon-7" alt="Polygon" src="/img/polygon-16-23.svg" />
              </div>
            </div>
            <div className="text-wrapper-110">For more Info, contact</div>
            <div className="f-sokail-ai-j-sokail-4">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
            <img className="group-228" alt="Group" src="/img/group-48098605-7.png" />
            <div className="PRESENTED-BY-4">PRESENTED&nbsp;&nbsp;BY</div>
            <div className="group-229">
              <div className="group-230">
                <div className="group-231">
                  <div className="group-230">
                    <div className="group-231">
                      <div className="group-232">
                        <div className="overlap-group-27">
                          <div className="text-wrapper-111">or</div>
                          <div className="rectangle-82" />
                          <div className="rectangle-83" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="group-233"
              href="https://api.whatsapp.com/send/?phone=19548823115"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-29">
                <div className="rectangle-84" />
                <img className="social-icons-2" alt="Group" src="/img/group-48098911-1.png" />
              </div>
            </a>
            <a
              className="group-234"
              href="https://www.instagram.com/_pisetzky"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="overlap-29">
                <div className="rectangle-84" />
                <SocialIcons color="negative" platform="instagram" />
              </div>
            </a>
            <a
              className="group-235"
              href="https://www.messenger.com/t/480455975379128/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="group-236">
                <div className="overlap-group-28">
                  <div className="rectangle-84" />
                  <SocialIcons4 className="social-icons-2" />
                </div>
              </div>
            </a>
            <a className="group-237" href="https://t.me/leodavincian" rel="noopener noreferrer" target="_blank">
              <div className="overlap-29">
                <div className="rectangle-84" />
                <SocialIcons color="negative" platform="telegram" />
              </div>
            </a>
            <a className="group-238" href="https://x.com/pisetzkymusic" rel="noopener noreferrer" target="_blank">
              <div className="overlap-29">
                <div className="rectangle-84" />
                <PlatformXTwitterColorNegative className="social-icons-2" color="white" />
              </div>
            </a>
            <div className="group-239">
              <div className="rectangle-85" />
              <div className="group-240">
                <div className="group-241">
                  <div className="text-wrapper-112">PACKAGES</div>
                  <div className="group-242">
                    <div className="text-wrapper-113">SPONSORSHIP</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-30">
              <div className="group-243" />
              <img
                className="AAA-logo-test-7"
                alt="Aaa logo test"
                src="/img/aaa-logo-test-4-removebg-preview-1-2-2x.png"
              />
            </div>
            <div className="group-244">
              <div className="text-wrapper-114">Secret</div>
              <img className="location-3" alt="Location" src="/img/location-1.svg" />
            </div>
            <p className="text-wrapper-115">Let us know before 12th of July</p>
          </>
        )}
      </div>
    </div>
  );
};
