import React from "react";
import { ConcreteComponentNode } from "../../components/ConcreteComponentNode";
import { SocialIcons } from "../../components/SocialIcons";
import "./style.css";

export const Guest = () => {
  return (
    <div className="GUEST">
      <div className="div-17">
        <div className="overlap-87">
          <div className="group-703">
            <div className="group-704">
              <div className="group-705">
                <img className="group-706" alt="Group" src="/img/group-48098457-2.png" />
              </div>
            </div>
          </div>
          <div className="group-707">
            <div className="overlap-88">
              <img className="mask-group-50" alt="Mask group" src="/img/mask-group-4.png" />
              <div className="group-708">
                <div className="overlap-89">
                  <div className="group-709">
                    <div className="group-710">
                      <div className="group-711">
                        <div className="group-712">
                          <div className="rectangle-269" />
                          <div className="rectangle-270" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-wrapper-406">7pm - 7am</div>
                </div>
                <div className="text-wrapper-407">July 20th</div>
                <div className="FULL-MOON-AWAKENING-4">FULL&nbsp;&nbsp;MOON&nbsp;&nbsp;AWAKENING</div>
                <div className="group-713">
                  <div className="overlap-group-68">
                    <img className="vector-95" alt="Vector" src="/img/vector-233-1-2.svg" />
                    <img className="vector-96" alt="Vector" src="/img/vector-244-1-2.svg" />
                    <img className="group-714" alt="Group" src="/img/group-48098648-1-2.png" />
                  </div>
                  <img className="vector-97" alt="Vector" src="/img/vector-231-1-4.svg" />
                  <img className="vector-98" alt="Vector" src="/img/vector-232-1-4.svg" />
                  <img className="vector-99" alt="Vector" src="/img/vector-235-1-2.svg" />
                  <img className="vector-100" alt="Vector" src="/img/vector-236-1.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-715">
          <div className="rectangle-271" />
          <div className="rectangle-272" />
          <img className="AAA-logo-test-19" alt="Aaa logo test" src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png" />
        </div>
        <img className="mask-group-51" alt="Mask group" src="/img/mask-group-3-2x.png" />
        <div className="overlap-90">
          <div className="group-716">
            <div className="rectangle-273" />
            <div className="rectangle-274" />
          </div>
          <div className="group-717">
            <div className="text-wrapper-408">EVENT SPECIALS</div>
            <div className="EXPERIENCE-THE-7">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
          </div>
        </div>
        <div className="group-718">
          <img className="basiglio-milan-italy" alt="Basiglio milan italy" src="/img/basiglio-milan-italy-1.svg" />
          <p className="text-wrapper-409">Let us know before 12th of July</p>
        </div>
        <div className="group-719">
          <div className="rectangle-275" />
          <div className="CORDIALLY-INVITED-BY-5">CORDIALLY INVITED&nbsp;&nbsp;BY</div>
          <div className="FABIANO-VALLI-LEVI-8">
            FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp;&nbsp;&nbsp;co
          </div>
        </div>
        <div className="group-720">
          <div className="group-721">
            <div className="text-wrapper-410">EXCLUSIVE LOCATION</div>
            <div className="AN-EXPERTLY-5">
              AN EXPERTLY
              ARCHITECTED&nbsp;&nbsp;TEMPLE&nbsp;&nbsp;CENTERED&nbsp;&nbsp;AROUND&nbsp;&nbsp;A&nbsp;&nbsp;LUXURY&nbsp;&nbsp;VILLA
            </div>
          </div>
          <div className="group-722">
            <div className="text-wrapper-411">SKL SS 2024 CATWALK</div>
            <div className="text-wrapper-412">
              FEATURING&nbsp;&nbsp;TOP&nbsp;&nbsp;MODELS&nbsp;&nbsp;FROM&nbsp;&nbsp;EUROPE&nbsp;&nbsp;&amp;
              THE&nbsp;&nbsp;US
            </div>
          </div>
          <div className="group-723">
            <div className="text-wrapper-413">
              COMPLIMENTARY&nbsp;&nbsp;VALET&nbsp;&nbsp;SERVICE&nbsp;&nbsp;AT&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
            </div>
            <p className="text-wrapper-414">FROM LOS ANGELES: SHAMANIC RECALIBRATION</p>
          </div>
          <div className="group-724">
            <div className="text-wrapper-414">TOP UNDERGROUND DJS</div>
            <div className="text-wrapper-413">
              SECRET&nbsp;&nbsp;LINEUP&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;ELITE&nbsp;&nbsp;UNDERGROUND
            </div>
          </div>
          <div className="group-725">
            <div className="group-726">
              <div className="text-wrapper-414">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
              <div className="text-wrapper-413">
                A&nbsp;&nbsp;PIECE&nbsp;&nbsp;DESIGNED&nbsp;&nbsp;FOR&nbsp;&nbsp;US&nbsp;&nbsp;ONE&nbsp;&nbsp;OF
                THE&nbsp;&nbsp;BEST&nbsp;&nbsp;STAGE&nbsp;&nbsp;CREATORS
              </div>
            </div>
          </div>
          <div className="overlap-group-69">
            <div className="group-727">
              <div className="group-728">
                <div className="group-729">
                  <div className="group-730">
                    <div className="group-731">
                      <div className="group-726">
                        <div className="text-wrapper-413">
                          RECREATING&nbsp;&nbsp;THE&nbsp;&nbsp;FEELING&nbsp;&nbsp;OF
                          AN&nbsp;&nbsp;EXOTIC&nbsp;&nbsp;JOURNEY
                        </div>
                        <div className="text-wrapper-414">LUXURY PRIVATE YERTS</div>
                      </div>
                    </div>
                  </div>
                  <div className="group-732">
                    <div className="group-726">
                      <div className="text-wrapper-413">
                        A&nbsp;&nbsp;LIGHT&nbsp;&nbsp;SHOW&nbsp;&nbsp;COMPOSITION&nbsp;&nbsp;TAI:LORED&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
                      </div>
                      <div className="text-wrapper-414">LIGHTING DESIGN EXPERIENCE</div>
                    </div>
                  </div>
                  <div className="group-733">
                    <div className="group-726">
                      <div className="text-wrapper-413">
                        IMPORTING&nbsp;&nbsp;THE&nbsp;&nbsp;EXOTIC
                        TO&nbsp;&nbsp;CREATE&nbsp;&nbsp;A&nbsp;&nbsp;UNIQUE&nbsp;&nbsp;SMOKING&nbsp;&nbsp;EXPERIENCE
                      </div>
                      <p className="text-wrapper-414">SPECIALTY SMOKING LOUNGE PROVIDED BY OUR SPONSORS</p>
                    </div>
                  </div>
                  <div className="group-734">
                    <p className="text-wrapper-415">PRESENTATION OF OUR PRIVATE ART GALLERY</p>
                    <div className="text-wrapper-412">
                      ACCESSED&nbsp;&nbsp;ONLY&nbsp;&nbsp;BY&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;SPECIALTY
                      NFC&nbsp;&nbsp;RINGS
                    </div>
                  </div>
                </div>
              </div>
              <p className="HIGHLY-TALENTED-4">
                HIGHLY TALENTED&nbsp;&nbsp;ARTISTS PERFORMING THROUGHOUT THE&nbsp;&nbsp;NIGHT
              </p>
            </div>
            <div className="group-735">
              <div className="text-wrapper-414">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
            </div>
          </div>
        </div>
        <div className="group-736">
          <div className="group-737">
            <div className="group-738">
              <div className="PRESENTED-BY-18">PRESENTED&nbsp;&nbsp;BY</div>
            </div>
          </div>
        </div>
        <img className="group-739" alt="Group" src="/img/group-48099046.png" />
        <div className="group-740">
          <div className="text-wrapper-416">For more Info, contact</div>
          <div className="f-sokail-ai-j-sokail-16">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
        </div>
        <div className="group-741">
          <SocialIcons color="negative" platform="telegram" />
          <ConcreteComponentNode color="negative" platform="x-twitter" />
          <SocialIcons color="negative" platform="instagram" />
          <SocialIcons color="negative" platform="whats-app" />
          <SocialIcons color="negative" platform="messenger" />
        </div>
        <div className="group-742">
          <div className="group-743">
            <div className="group-744">
              <div className="group-743">
                <div className="group-744">
                  <div className="group-745">
                    <div className="text-wrapper-417">or</div>
                    <div className="rectangle-276" />
                    <div className="rectangle-277" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
