/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const PlatformYoutubeColorNegative1 = ({ className }) => {
  return (
    <svg
      className={`platform-youtube-color-negative-1 ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M47.5219 14.4001C47.5219 14.4001 47.0531 11.0907 45.6094 9.63759C43.7812 7.72509 41.7375 7.71571 40.8 7.60321C34.0875 7.11571 24.0094 7.11572 24.0094 7.11572H23.9906C23.9906 7.11572 13.9125 7.11571 7.2 7.60321C6.2625 7.71571 4.21875 7.72509 2.39062 9.63759C0.946875 11.0907 0.4875 14.4001 0.4875 14.4001C0.4875 14.4001 0 18.2907 0 22.172V25.8095C0 29.6907 0.478125 33.5813 0.478125 33.5813C0.478125 33.5813 0.946875 36.8907 2.38125 38.3438C4.20937 40.2563 6.60938 40.1907 7.67813 40.397C11.5219 40.7626 24 40.8751 24 40.8751C24 40.8751 34.0875 40.8563 40.8 40.3782C41.7375 40.2657 43.7812 40.2563 45.6094 38.3438C47.0531 36.8907 47.5219 33.5813 47.5219 33.5813C47.5219 33.5813 48 29.7001 48 25.8095V22.172C48 18.2907 47.5219 14.4001 47.5219 14.4001ZM19.0406 30.2251V16.7345L32.0062 23.5032L19.0406 30.2251Z"
        fill="white"
      />
    </svg>
  );
};
