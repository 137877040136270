import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { VipMobile } from "./screens/VipMobile";
import { SponsorWeb } from "./screens/SponsorWeb";
import { SponsorInvitation } from "./screens/SponsorInvitation";
import { MainMobile } from "./screens/MainMobile";
import { Main } from "./screens/Main";
import { GuestMobile } from "./screens/GuestMobile";
import { SponsorInvitationScreen } from "./screens/SponsorInvitationScreen";
import { Vip } from "./screens/Vip";
import { MainScreen } from "./screens/MainScreen";
import { DivWrapper } from "./screens/DivWrapper";
import { Screen13 } from "./screens/Screen13";
import { Screen14 } from "./screens/Screen14";
import { VipMobileScreen } from "./screens/VipMobileScreen";
import { Guest } from "./screens/Guest";
import { VipInitation } from "./screens/VipInitation";
import { GuestVipInv } from "./screens/GuestVipInv";
import { VipInvitationPg } from "./screens/VipInvitationPg";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <VipMobile />,
  },
  {
    path: "/vip-mobile-2u460",
    element: <VipMobile />,
  },
  {
    path: "/sponsor-web",
    element: <SponsorWeb />,
  },
  {
    path: "/sponsor-invitation",
    element: <SponsorInvitation />,
  },
  {
    path: "/main-mobile",
    element: <MainMobile />,
  },
  {
    path: "/main-1u461",
    element: <Main />,
  },
  {
    path: "/guest-mobile",
    element: <GuestMobile />,
  },
  {
    path: "/sponsor-invitation-web",
    element: <SponsorInvitationScreen />,
  },
  {
    path: "/vip",
    element: <Vip />,
  },
  {
    path: "/main",
    element: <MainScreen />,
  },
  {
    path: "/sponsor-invitation-mobile",
    element: <DivWrapper />,
  },
  {
    path: "/sponsor-invitation-1",
    element: <Screen13 />,
  },
  {
    path: "/sponsor-invitation-2",
    element: <Screen14 />,
  },
  {
    path: "/vip-mobile",
    element: <VipMobileScreen />,
  },
  {
    path: "/guest",
    element: <Guest />,
  },
  {
    path: "/vip-initation",
    element: <VipInitation />,
  },
  {
    path: "/guestu47vip-inv",
    element: <GuestVipInv />,
  },
  {
    path: "/vip-invitation-pg-1",
    element: <VipInvitationPg />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
