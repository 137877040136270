import React from "react";
import "./style.css";

export const VipInvitationPg = () => {
  return (
    <div className="VIP-INVITATION-PG">
      <div className="group-wrapper-2">
        <div className="GUEST-variant-wrapper">
          <div className="GUEST-variant">
            <div className="group-814" />
            <div className="overlap-106">
              <div className="group-815">
                <div className="overlap-107">
                  <div className="group-816">
                    <div className="overlap-group-75">
                      <div className="group-817">
                        <img className="group-818" alt="Group" src="/img/group-48098457.png" />
                      </div>
                      <div className="text-wrapper-450">SECRET EVENT</div>
                    </div>
                  </div>
                  <div className="text-wrapper-451">July 20th</div>
                </div>
              </div>
              <div className="group-819">
                <div className="text-wrapper-452">CORDIALLY INVITE YOU TO</div>
              </div>
              <div className="FABIANO-VALLI-LEVI-12">
                FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp; co.
              </div>
              <div className="group-820">
                <div className="overlap-108">
                  <div className="rectangle-315" />
                  <div className="rectangle-316" />
                  <p className="text-wrapper-453">7 pm - 7 am</p>
                </div>
              </div>
              <img className="AAA-logo-test-22" alt="Aaa logo test" />
            </div>
            <div className="overlap-109">
              <div className="group-821">
                <div className="group-822">
                  <div className="group-823">
                    <div className="group-824">
                      <img className="group-825" alt="Group" src="/img/group-48098457-1-2.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="EXCLUSIVE-LOCATION-3">EXCLUSIVE&nbsp;&nbsp; LOCATION</div>
              <div className="LIVE-ART-PRODUCTIONS-3">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
              <div className="TOP-UNDERGROUND-DJS-3">TOP&nbsp;&nbsp; UNDERGROUND&nbsp;&nbsp; DJS</div>
              <div className="FROM-LOS-ANGELES-4">
                FROM&nbsp;&nbsp;LOS&nbsp;&nbsp;ANGELES:&nbsp;&nbsp;SHAMANIC&nbsp;&nbsp;RECALIBRATION
              </div>
              <div className="GIFT-BOXES-OF-GIFTS-3">
                GIFT&nbsp;&nbsp;BOXES&nbsp;&nbsp;OF&nbsp;&nbsp;GIFTS&nbsp;&nbsp;FROM&nbsp;&nbsp;SPONSORS&nbsp;&nbsp;TO&nbsp;&nbsp;VIP
              </div>
              <div className="VIP-PRIVATE-ACCESS-3">
                VIP&nbsp;&nbsp;PRIVATE&nbsp;&nbsp;ACCESS&nbsp;&nbsp;TO&nbsp;&nbsp;SECRET&nbsp;&nbsp;AREAS
              </div>
              <div className="PROPER-FESTIVAL-3">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
              <div className="text-wrapper-454">LUXURY PRIVATE YERTS</div>
              <div className="LIVE-VIP-TATTOO-3">LIVE&nbsp;&nbsp;VIP&nbsp;&nbsp;TATTOO&nbsp;&nbsp;ARTISTS</div>
              <div className="LIGHTING-DESIGN-3">LIGHTING&nbsp;&nbsp;DESIGN&nbsp;&nbsp;EXPERIENCE</div>
              <div className="SPECIALTY-SMOKING-3">
                SPECIALTY&nbsp;&nbsp;SMOKING&nbsp;&nbsp;LOUNGE&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY OUR&nbsp;&nbsp;SPONSORS
              </div>
              <div className="PRESENTATION-OF-OUR-3">
                PRESENTATION&nbsp;&nbsp;OF&nbsp;&nbsp;OUR&nbsp;&nbsp;PRIVATE&nbsp;&nbsp;ART&nbsp;&nbsp;GALLERY
              </div>
              <div className="group-826">
                <div className="text-wrapper-455">EVENT SPECIALS</div>
                <div className="EXPERIENCE-THE-10">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
              </div>
              <div className="rectangle-317" />
              <div className="rectangle-318" />
            </div>
            <div className="overlap-110">
              <div className="group-827">
                <div className="group-822">
                  <div className="group-828">
                    <div className="group-824">
                      <img className="group-825" alt="Group" src="/img/group-48098457-2-2.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="CONNECTION-TO-THE-3">
                CONNECTION&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;TOP&nbsp;&nbsp;HOTELS&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;AREA
              </div>
              <div className="PRIVATE-3">
                PRIVATE&nbsp;&nbsp;TRANSPORTATION&nbsp;&nbsp;DISCOUNT&nbsp;&nbsp;AND&nbsp;&nbsp;RESERVATIONS
              </div>
              <div className="HELICOPTER-LANDING-4">
                HELICOPTER LANDING&nbsp;&nbsp;NEXT TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE&nbsp;&nbsp;UPON&nbsp;&nbsp;REQUEST
              </div>
              <div className="COMPLIMENTARY-VALET-3">
                COMPLIMENTARY&nbsp;&nbsp;VALET&nbsp;&nbsp;SERVICE&nbsp;&nbsp;AT&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
              </div>
              <div className="SPECIAL-ACCESS-TO-2">
                SPECIAL&nbsp;&nbsp;ACCESS&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;
                MAJOR&nbsp;&nbsp;AFTERLIFE&nbsp;&nbsp;EVENT&nbsp;&nbsp;THE&nbsp;&nbsp;DAY&nbsp;&nbsp;AFTER
              </div>
              <div className="group-829">
                <div className="group-830">
                  <div className="text-wrapper-456">OUR SPECIAL</div>
                  <div className="text-wrapper-457">ACCOMODATIONS</div>
                </div>
              </div>
              <div className="group-831">
                <div className="overlap-111">
                  <img className="image-14" alt="Image" src="/img/image.png" />
                  <div className="rectangle-319" />
                  <div className="rectangle-319" />
                  <div className="rectangle-320" />
                  <div className="rectangle-321" />
                  <div className="rectangle-322" />
                  <div className="rectangle-323" />
                  <div className="rectangle-324" />
                  <div className="rectangle-325" />
                  <img className="rectangle-326" alt="Rectangle" src="/img/rectangle-2827-3.svg" />
                  <div className="group-832">
                    <div className="overlap-group-76">
                      <div className="rectangle-327" />
                      <div className="text-wrapper-458">Attend</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-833">
                <div className="text-wrapper-459">Secret</div>
                <img className="location-10" alt="Location" src="/img/location.svg" />
              </div>
              <p className="text-wrapper-460">Let us know before 12th of July</p>
              <div className="text-wrapper-461">For more Info, contact</div>
              <div className="f-sokail-ai-j-sokail-19">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
              <div className="rectangle-328" />
              <div className="rectangle-329" />
              <div className="rectangle-330" />
              <div className="rectangle-331" />
              <img className="AAA-logo-test-23" alt="Aaa logo test" />
            </div>
            <div className="rectangle-332" />
            <img className="group-834" alt="Group" src="/img/group-48098589.png" />
            <div className="group-835">
              <div className="overlap-112">
                <div className="rectangle-333" />
                <div className="text-wrapper-462">Get Access</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
