import React from "react";
import "./style.css";

export const VipInitation = () => {
  return (
    <div className="VIP-INITATION">
      <div className="GUEST-wrapper">
        <div className="GUEST-2">
          <div className="property-VIP-INV-2">
            <div className="group-746" />
            <div className="overlap-91">
              <div className="group-747">
                <div className="group-748">
                  <div className="overlap-group-70">
                    <div className="group-747">
                      <img className="group-749" alt="Group" src="/img/group-48098457.png" />
                    </div>
                    <div className="text-wrapper-418">SECRET EVENT</div>
                  </div>
                </div>
              </div>
              <div className="group-750">
                <div className="text-wrapper-419">CORDIALLY INVITE YOU</div>
              </div>
              <div className="FABIANO-VALLI-LEVI-9">
                FABIANO&nbsp;&nbsp;VALLI&nbsp;&nbsp;LEVI&nbsp;&nbsp;PISETZKY &amp; co.
              </div>
              <div className="group-751">
                <div className="overlap-92">
                  <div className="rectangle-278" />
                  <div className="rectangle-279" />
                  <div className="text-wrapper-420">July 20th</div>
                </div>
              </div>
              <div className="group-752">
                <div className="overlap-93">
                  <img className="vector-101" alt="Vector" src="/img/vector-233-3.svg" />
                  <img className="vector-102" alt="Vector" src="/img/vector-244-2.svg" />
                  <img className="group-753" alt="Group" src="/img/group-48098648-5.png" />
                </div>
                <img className="vector-103" alt="Vector" src="/img/vector-231-6.svg" />
                <img className="vector-104" alt="Vector" src="/img/vector-232-3.svg" />
                <img className="vector-105" alt="Vector" src="/img/vector-235-2.svg" />
                <img className="vector-106" alt="Vector" src="/img/vector-236-3.svg" />
              </div>
            </div>
            <div className="overlap-94">
              <div className="group-754">
                <div className="group-755">
                  <div className="group-756">
                    <div className="group-757">
                      <img className="group-758" alt="Group" src="/img/group-48098457-1-2.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="EXCLUSIVE-LOCATION-2">EXCLUSIVE&nbsp;&nbsp; LOCATION</div>
              <div className="LIVE-ART-PRODUCTIONS-2">LIVE&nbsp;&nbsp;ART&nbsp;&nbsp;PRODUCTIONS</div>
              <div className="TOP-UNDERGROUND-DJS-2">TOP&nbsp;&nbsp; UNDERGROUND&nbsp;&nbsp; DJS</div>
              <div className="FROM-LOS-ANGELES-3">
                FROM&nbsp;&nbsp;LOS&nbsp;&nbsp;ANGELES:&nbsp;&nbsp;SHAMANIC&nbsp;&nbsp;RECALIBRATION
              </div>
              <div className="GIFT-BOXES-OF-GIFTS-2">
                GIFT&nbsp;&nbsp;BOXES&nbsp;&nbsp;OF&nbsp;&nbsp;GIFTS&nbsp;&nbsp;FROM&nbsp;&nbsp;SPONSORS&nbsp;&nbsp;TO&nbsp;&nbsp;VIP
              </div>
              <div className="VIP-PRIVATE-ACCESS-2">
                VIP&nbsp;&nbsp;PRIVATE&nbsp;&nbsp;ACCESS&nbsp;&nbsp;TO&nbsp;&nbsp;SECRET&nbsp;&nbsp;AREAS
              </div>
              <div className="PROPER-FESTIVAL-2">PROPER&nbsp;&nbsp;FESTIVAL&nbsp;&nbsp;STAGE</div>
              <div className="text-wrapper-421">LUXURY PRIVATE YERTS</div>
              <div className="LIVE-VIP-TATTOO-2">LIVE&nbsp;&nbsp;VIP&nbsp;&nbsp;TATTOO&nbsp;&nbsp;ARTISTS</div>
              <div className="LIGHTING-DESIGN-2">LIGHTING&nbsp;&nbsp;DESIGN&nbsp;&nbsp;EXPERIENCE</div>
              <div className="SPECIALTY-SMOKING-2">
                SPECIALTY&nbsp;&nbsp;SMOKING&nbsp;&nbsp;LOUNGE&nbsp;&nbsp;PROVIDED&nbsp;&nbsp;BY OUR&nbsp;&nbsp;SPONSORS
              </div>
              <div className="PRESENTATION-OF-OUR-2">
                PRESENTATION&nbsp;&nbsp;OF&nbsp;&nbsp;OUR&nbsp;&nbsp;PRIVATE&nbsp;&nbsp;ART&nbsp;&nbsp;GALLERY
              </div>
              <div className="group-759">
                <div className="text-wrapper-422">EVENT SPECIALS</div>
                <div className="EXPERIENCE-THE-8">EXPERIENCE&nbsp;&nbsp;THE&nbsp;&nbsp;UNTOUCHABLE</div>
              </div>
              <div className="rectangle-280" />
              <div className="rectangle-281" />
            </div>
            <div className="overlap-95">
              <div className="group-760">
                <div className="group-755">
                  <div className="group-761">
                    <div className="group-757">
                      <img className="group-758" alt="Group" src="/img/group-48098457-2-2.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="CONNECTION-TO-THE-2">
                CONNECTION&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;TOP&nbsp;&nbsp;HOTELS&nbsp;&nbsp;OF&nbsp;&nbsp;THE&nbsp;&nbsp;AREA
              </div>
              <div className="PRIVATE-2">
                PRIVATE&nbsp;&nbsp;TRANSPORTATION&nbsp;&nbsp;DISCOUNT&nbsp;&nbsp;AND&nbsp;&nbsp;RESERVATIONS
              </div>
              <div className="HELICOPTER-LANDING-3">
                HELICOPTER LANDING&nbsp;&nbsp;NEXT TO&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE&nbsp;&nbsp;UPON&nbsp;&nbsp;REQUEST
              </div>
              <div className="COMPLIMENTARY-VALET-2">
                COMPLIMENTARY&nbsp;&nbsp;VALET&nbsp;&nbsp;SERVICE&nbsp;&nbsp;AT&nbsp;&nbsp;THE&nbsp;&nbsp;VENUE
              </div>
              <div className="SPECIAL-ACCESS-TO">
                SPECIAL&nbsp;&nbsp;ACCESS&nbsp;&nbsp;TO&nbsp;&nbsp;THE&nbsp;&nbsp;
                MAJOR&nbsp;&nbsp;AFTERLIFE&nbsp;&nbsp;EVENT&nbsp;&nbsp;THE&nbsp;&nbsp;DAY&nbsp;&nbsp;AFTER
              </div>
              <div className="group-762">
                <div className="group-763">
                  <div className="text-wrapper-423">OUR SPECIAL</div>
                  <div className="text-wrapper-424">ACCOMODATIONS</div>
                </div>
              </div>
              <div className="group-764">
                <div className="overlap-96">
                  <img className="image-12" alt="Image" src="/img/image-371-3.png" />
                  <div className="rectangle-282" />
                  <div className="rectangle-282" />
                  <div className="rectangle-283" />
                  <div className="rectangle-284" />
                  <div className="rectangle-285" />
                  <div className="rectangle-286" />
                  <div className="rectangle-287" />
                  <div className="rectangle-288" />
                  <img className="rectangle-289" alt="Rectangle" src="/img/rectangle-2827.svg" />
                  <div className="group-765">
                    <div className="overlap-group-71">
                      <div className="rectangle-290" />
                      <div className="text-wrapper-425">Attend</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-766">
                <div className="text-wrapper-426">Secret</div>
                <img className="location-9" alt="Location" src="/img/location.svg" />
              </div>
              <p className="text-wrapper-427">Let us know before 12th of July</p>
              <div className="text-wrapper-428">For more Info, contact</div>
              <div className="f-sokail-ai-j-sokail-17">f@sokail.ai&nbsp;&nbsp;//&nbsp;&nbsp;j@sokail.ai</div>
              <div className="rectangle-291" />
              <div className="rectangle-292" />
              <div className="rectangle-293" />
              <div className="rectangle-294" />
              <img
                className="AAA-logo-test-20"
                alt="Aaa logo test"
                src="/img/aaa-logo-test-4-removebg-preview-1-2-2.png"
              />
            </div>
            <div className="rectangle-295" />
            <img className="group-767" alt="Group" src="/img/group-48098589-2.png" />
            <div className="group-768">
              <div className="overlap-97">
                <div className="rectangle-296" />
                <div className="text-wrapper-429">Get Access</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
